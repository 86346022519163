import React, { useRef } from "react";
import type { RefObject } from "react";
import {
  useNavigate,
  useSearchParams,
  replace,
  type LoaderFunction,
} from "react-router-dom";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";
import { Elm } from "../../elm/Main.elm";
import type { InPort, OutPort, App as ElmApp } from "../../../elm/Main.elm";
import { FreigeistHeader } from "../../common/web-components/freigeist-header";
import { API_URL, STAGE } from "../config";
import { sortClassesAlphabetically } from "../../common/utils/assortments";
import { ElmCalendarError } from "../../common/utils/custom-errors";
import Auth from "../../common/services/Auth";
import {
  useDocumentTitle,
  useEffectOnlyDuringFirstRender,
  useEffectExceptForFirstRender,
  useResponsiveViewport,
  useRemoveLoadingSpinner,
} from "../../common/utils/hooks";
import store from "../store";
import { useAppSelector } from "../hooks";
import type { Class } from "../../features/classes/types";
import {
  selectSchoolRegion,
  selectSchoolWithHolidays,
} from "../combined-selectors/index";
import { selectCurrentSchoolyear } from "../../features/current-schoolyear/current-schoolyear-slice";
import { api, selectGetHolidaysData } from "../../services/api";
import { type HandlerCtx } from "../router/data-strategy";

type Flags = object;
type Ports = {
  navigate: OutPort<{ url: string; replace: boolean }>;
  logError: OutPort<{
    message: string;
    key: string;
    data: Record<string, unknown>;
  }>;
  shutDown: InPort<null>;
  newQueryParams: InPort<{ weekStart: string }>;
};
type ElmCalendarApp = ElmApp<Ports>;

// set initial date
// - use date from url if available
// - otherwise use a local date from now
// - clamp it between the schoolyearstart/end
// - move to next week if its a sat/sunday or sunday for weeks with saturday school
// - make sure its a monday
function getInitialDate({
  urlDate,
  schoolyearStart,
  schoolyearEnd,
  hasSaturdayClasses,
}: {
  urlDate: DateTime;
  schoolyearStart: DateTime;
  schoolyearEnd: DateTime;
  hasSaturdayClasses: boolean;
}): DateTime {
  const initialDate = urlDate.isValid ? urlDate : DateTime.local();
  const clampedDate = DateTime.min(
    DateTime.max(schoolyearStart, initialDate),
    schoolyearEnd,
  );
  const showNextWeek: boolean = hasSaturdayClasses
    ? clampedDate.weekday === 7 // is sunday
    : [6, 7].includes(clampedDate.weekday); // is sunday or saturday
  return clampedDate.plus({ weeks: Number(showNextWeek) }).startOf("week");
}

function useElmApp(): RefObject<HTMLDivElement> {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hasSaturdayClasses = useAppSelector(
    (state) => state.timetable.saturday,
  );
  const {
    holidays,
    startDate: schoolyearStart,
    endDate: schoolyearEnd,
  } = useAppSelector(selectSchoolWithHolidays);

  const elmAppRef = useRef<ElmCalendarApp>(); // notice the missing argument. Tells TS that the .current prop is mutable
  const nodeRef = useRef<HTMLDivElement>(null);

  // use this hook instead of regular useEffect to prevent
  // reinitialization during HMR, otherwise elm-hot will throw errors
  useEffectOnlyDuringFirstRender(() => {
    const flags = {
      apiBaseUrl: API_URL,
      token: Auth.getToken(),
      holidays,
      schoolyearStart: DateTime.fromISO(schoolyearStart).toISODate(), // remove time part of string
      schoolyearEnd: DateTime.fromISO(schoolyearEnd).toISODate(), // remove time part of string
      weekStart: searchParams.get("weekstart"),
      hasSaturdayClasses,
    };
    // initialize Elm
    const app = Elm.Main.init<Flags, Ports>({ node: nodeRef.current, flags });

    elmAppRef.current = app;

    // set up ports
    const handleNavigationCommand: Parameters<
      Ports["navigate"]["subscribe"]
    >[0] = ({ url, replace: replaceArg }) => {
      navigate(url, { replace: replaceArg });
    };

    const handleLogErrorCommand: Parameters<
      Ports["logError"]["subscribe"]
    >[0] = ({ message, key, data }) => {
      const error = new ElmCalendarError(message, key, JSON.stringify(data));
      if (STAGE !== "production") {
        /* eslint-disable no-console */
        console.error("Elm reported an Error:", message);
        console.log(data);
        /* eslint-enable no-console */
      }
      Sentry.captureException(error, { extra: data });
    };

    // listen to navigate event from Elm and react
    app.ports.navigate.subscribe(handleNavigationCommand);

    // listen to log event from Elm and react
    app.ports.logError.subscribe(handleLogErrorCommand);

    // clean up port subscriptions
    return () => {
      app.ports.navigate.unsubscribe(handleNavigationCommand);
      app.ports.logError.unsubscribe(handleLogErrorCommand);
      app.ports.shutDown.send(null);
      elmAppRef.current = undefined; // make sure no more messages are sent to Elm app after shutdown (breaks HMR)
    };
  });

  useEffectExceptForFirstRender(() => {
    const app = elmAppRef.current;
    if (app) {
      const timeRange = {
        weekStart: searchParams.get("weekstart") ?? "",
      };
      app.ports.newQueryParams.send(timeRange);
    }
  }, [searchParams.get("weekstart")]);

  return nodeRef;
}

export function Component() {
  const name = useAppSelector((state) => state.authentication.name);
  const classes = useAppSelector((state) => state.classes);
  const sortedClasses = sortClassesAlphabetically(classes).filter(
    (elem: Class) => elem.subjects.some((subs) => subs.schooldays.length >= 0),
  );

  useRemoveLoadingSpinner();
  useDocumentTitle("Freigeist | Kalender");
  useResponsiveViewport();
  const containerRef = useElmApp();

  return (
    <div className="pb-10 sm:pb-20">
      <FreigeistHeader
        name={name}
        classes={sortedClasses}
        activeTab="kalender"
      />
      {/* Could also be used as a web component like this
      Not necessary yet, but could become relevant in the future

      <freigeist-header
        name={authentication.name}
        classes={JSON.stringify(classes)}
        activeTab="kalender"
      />
      */}
      <div ref={containerRef} />
    </div>
  );
}

export const loader: LoaderFunction = async ({ request }, handlerCtx) => {
  const { onUnload } = handlerCtx as HandlerCtx;

  const year = selectCurrentSchoolyear(store.getState());
  // make sure to wait till school is loaded so we have the region
  const schoolPromise = store.dispatch(api.endpoints.getSchool.initiate(year));
  await schoolPromise;

  const region = selectSchoolRegion(store.getState());

  const queryPromises = [
    store.dispatch(
      api.endpoints.getHolidays.initiate({ schoolyear: year, region }),
    ),
  ];

  // wait for data to have loaded
  await Promise.all(queryPromises);

  // make sure to remove the subscriptions when the page unloads
  onUnload(() => {
    [schoolPromise, ...queryPromises].forEach((promise) =>
      promise.unsubscribe(),
    );
  });

  const state = store.getState();
  const { start: schoolyearStart, end: schoolyearEnd } = selectGetHolidaysData(
    state,
    { schoolyear: year, region },
  );
  const hasSaturdayClasses = state.timetable.saturday;

  const url = new URL(request.url);
  const urlDateParam = url.searchParams.get("weekstart");
  const initialDate = getInitialDate({
    urlDate: DateTime.fromISO(urlDateParam ?? ""),
    schoolyearStart: DateTime.fromISO(schoolyearStart),
    schoolyearEnd: DateTime.fromISO(schoolyearEnd),
    hasSaturdayClasses,
  });

  // make sure url date and start date match
  if (urlDateParam !== initialDate.toISODate()) {
    return replace(
      `?${new URLSearchParams({ weekstart: initialDate.toISODate() })}`,
    );
  }

  return null;
};
