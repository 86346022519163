import React, { useEffect } from "react";
import { Link, LoaderFunction, LoaderFunctionArgs } from "react-router-dom";
import ArrowBack from "../../assets/images/svg/pfeil-zurueck.svg";
import { FreigeistHeader } from "../../common/web-components/freigeist-header";
import * as VIEW_ACTION from "../../features/dataview/actions";
import { api } from "../../services/api";
import { useAppDispatch } from "../hooks";
import store from "../store";
import { HandlerCtx } from "../router/data-strategy";

export function Component() {
  useEffect(() => {
    document.title = "Freigeist | Archiv";
    document.getElementById("content-container")?.classList.remove("isblured");
    document.getElementById("loading-spinner")?.classList.remove("running");
  }, []);

  const dispatch = useAppDispatch();

  const { data } = api.endpoints.getSchoolyears.useQueryState();
  const schoolyears = data ?? [];

  return (
    <div className="layout-register">
      <FreigeistHeader name="" classes={[]} activeTab="" />

      <div className="Main">
        <div className="children-wrapper archiv-page">
          <div className="max-w-[1200px] mx-auto py-5 flex items-center">
            <div
              className="flex justify-center items-center box-border font-bold h-[35px]
                      after:border-0 after:border-r-2 after:border-solid after:border-[#afafaf] after:h-full after:content-[''] after:block"
            >
              <Link
                to="/zeitleiste"
                className="flex items-center h-full pr-2.5"
              >
                <ArrowBack width="0.5rem" />
              </Link>
            </div>
            <div className="pl-2.5">
              <h1 className="text-[21px] font-bold">Archiv</h1>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="inner-wrapper">
              <div className="text-xl font-bold">
                <h2>Schuljahre</h2>
              </div>
              <div className="text-xl">
                Hier kannst du vergangene Schuljahre einsehen.
              </div>

              <div className="tw-pf space-y-3 mt-4">
                {schoolyears
                  .filter((item) => !item.is_active)
                  .toSorted((a, b) => (a.schoolyear < b.schoolyear ? 1 : -1))
                  .map((item) => (
                    <div
                      className="bg-gray-100 shadow-md rounded-sm h-10 flex items-center justify-between border-2 border-transparent hover:border-green cursor-pointer"
                      key={item._id}
                      onClick={() =>
                        dispatch(
                          VIEW_ACTION.loadSchoolyearData(
                            item.schoolyear,
                            true,
                            true,
                          ),
                        )
                      }
                    >
                      <div className="px-3 font-bold">
                        Schuljahr {item.schoolyear}/
                        {Number(item.schoolyear + 1)}{" "}
                      </div>
                      <div className="px-3 text-sans text-green text-3xl">
                        &rsaquo;
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const loader: LoaderFunction = async (
  _: LoaderFunctionArgs,
  handlerCtx,
) => {
  const { onUnload } = handlerCtx as HandlerCtx;

  // subscripe to needed RTK Query data
  const queryPromises = [
    store.dispatch(api.endpoints.getSchoolyears.initiate()),
  ];

  // make sure to remove the subscriptions when the page unloads
  onUnload(() => {
    queryPromises.forEach((promise) => promise.unsubscribe());
  });
  // wait for data to have loaded
  await Promise.all(queryPromises);

  return null;
};
