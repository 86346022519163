import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";

/* action types */
export const ADD_SUBJECT_LIST = "ADD_SUBJECT_LIST";
export const REMOVE_SUBJECT_LIST = "REMOVE_SUBJECT_LIST";
export const FETCH_SUBJECTS_LIST = "FETCH_SUBJECTS_LIST";

export const ADD_SUBJECT_FAILED = "ADD_SUBJECT_FAILED";

const FETCH_SUBJECTS_FAILED = "FETCH_SUBJECT_FALED";

export const UPDATE_SUBJECTS_LIST = "UPDATE_SUBJECTS_LIST";

const REMOVE_SUBJECT_FAILED = "REMOVE_SUBJECT_FAILED";

const addSubjectFailed = (error, subject) => ({
  type: ADD_SUBJECT_FAILED,
  subject,
  error,
});

// DEL_Subject list stuff
export function addSubjectList(subject) {
  // console.log('call add DEL_Subject list');
  // console.log(subject);
  return (dispatch) =>
    fetch(URLS.API_SUBJECTS_LIST_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(subject),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data.subject);
          dispatch(addSubjectListSuccess(data.subject));
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch(addSubjectFailed(error));
        });
      }
    });
}
function addSubjectListSuccess(subject) {
  // console.log('in action add DEL_Subject local');
  return {
    type: ADD_SUBJECT_LIST,
    subject,
  };
}

/**
 * subject list update
 * @param subject
 * @return {function(*=)}
 */
export function updateSubjectList(subject) {
  return (dispatch) =>
    fetch(URLS.API_SUBJECTS_LIST_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(subject),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data.subject);
          dispatch(updateSubjectListSuccess(data.subject));
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch(addSubjectFailed(error));
        });
      }
    });
}
function updateSubjectListSuccess(subject) {
  // console.log('in action add DEL_Subject local');
  return {
    type: UPDATE_SUBJECTS_LIST,
    subject,
  };
}

/**
 * remove an subjectlist item
 * @param id
 * @return {function(*=)}
 */
export const removeSubjectList = (id, schoolyear) => {
  const user_id = Auth.getUserId();
  return (dispatch) =>
    fetch(`${URLS.API_SUBJECTS_LIST_URL}/${id}/${user_id}/${schoolyear}`, {
      method: "delete",
      headers: { Authorization: `bearer ${Auth.getToken()}` },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch(removeSubjectListSuccess(data.message, id));
        });
      } else {
        response.json().then((error) => {
          dispatch(removeSubjectFailed(error));
        });
      }
    });
};
function removeSubjectListSuccess(message, index) {
  // console.log('right delete stuff here');

  return {
    type: REMOVE_SUBJECT_LIST,
    message,
    index,
  };
}

const fetchSubjectsFailed = (error) => ({
  type: FETCH_SUBJECTS_FAILED,
  error,
});

/**
 * fetch all subjectslist to user
 */
export function fetchSubjectsList(user_id, schoolyear) {
  // console.log('call fetch subjects for user_id: ' + user_id);
  return (dispatch) =>
    fetch(`${URLS.API_SUBJECTS_LIST_ALL_URL}/${user_id}/${schoolyear}`, {
      method: "get",
      headers: { Authorization: `bearer ${Auth.getToken()}` },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data);
          dispatch(fetchSubjectsListSuccess(data.subjects, data.message));
        });
      } else {
        response.json().then((error) => {
          dispatch(fetchSubjectsFailed(error));
        });
      }
    });
}
function fetchSubjectsListSuccess(subjects, message) {
  return {
    type: FETCH_SUBJECTS_LIST,
    subjects,
    message,
  };
}

function removeSubjectFailed(index) {
  return { type: REMOVE_SUBJECT_FAILED, index };
}
