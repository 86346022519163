import * as ACTIONS from "./actions";

const default_classes = []; // list of objects
export function classes(state = default_classes, action) {
  switch (action.type) {
    case ACTIONS.FETCH_CLASSES_SUCCESS:
      return [...action.classes];

    case ACTIONS.ADD_CLASSROOM_SUCCESS:
      return [...state, action.data.classes];

    case ACTIONS.ADD_CLASS_LOCAL:
      return [...state, action.obj];

    case ACTIONS.ADD_CLASS_LIST:
      return [...state, action.data];

    case ACTIONS.REMOVE_CLASSES_LIST:
      return state.filter((p) => p._id !== action.idx);

    case ACTIONS.UPDATE_CLASSLIST_ITEM:
      return [...action.classes];

    case ACTIONS.REMOVE_CLASS_LOCAL: {
      const numIndex = parseInt(action.idx);
      return state.filter((_, index) => index !== numIndex);
    }

    default:
      return state;
  }
}
