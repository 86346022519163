import { uniqueId } from "../../common/components/sequence_calendar/Utils";
import { FETCH_CLASSES_SUCCESS } from "../classes/actions";
import { FETCH_TIMETABLE } from "../timetable/actions";
import { FETCH_SUBJECTS_LIST } from "../subjects/actions";
import { FETCH_PAYMENT_SETTINGS } from "../payment/actions";
import * as URLS from "../../common/constants/ApiRoutes";
import { setActiveView } from "../active-view/active-view-slice";
import { routerNavigate } from "../routing/actions";
import { setSchoolYear } from "../current-schoolyear/current-schoolyear-slice";

/**
 * create an new schoolyear
 * @param yeardata
 * @returns {function(*): Promise<T>}
 */
export function addSchoolyearData(yeardata) {
  // first step - cleanup subject data for import new one
  const response_data = [];
  const clean_subjects = yeardata.subjects.map((item) => ({
    import_id: item._id.toString(),
    color: item.color,
    old_ref_id: item.old_ref_id,
    schoolyear: item.schoolyear,
    schooldays: item.schooldays,
    title: item.title,
    user_id: parseInt(item.user_id),
  }));

  const tmp_storage = {};
  tmp_storage.holder = {};

  // --------------> STEP 1: Subjects speichern

  return (dispatch, getState) => {
    const auth = getState().authentication;
    return fetch(URLS.API_SUBJECTS_IMPORT_SCHOOLYEAR, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getState().authentication.token}`,
      },
      body: JSON.stringify(clean_subjects),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          response_data.subjects = data.subjects.slice();
          tmp_storage.holder.subjectslist = data.subjects.slice();

          const data_subjects = data.subjects.slice();

          // step1: we have the subjectlist from db with new real ids. replace it in the classlists and timetable
          const clean_classes = [];
          const yeardata_classes = yeardata.classes.slice();
          yeardata_classes.forEach((elem) => {
            const tmp_subjects = [];
            if (elem.subjects) {
              elem.subjects.forEach((sub) => {
                const subject = data_subjects.find(
                  (p) => String(p.import_id) === String(sub._id),
                );

                const tmp_subject = {
                  ...subject,
                  schooldays: [...sub.schooldays],
                };
                tmp_subjects.push(tmp_subject);
              });
            }

            const tmp_class_obj = {
              import_id: elem._id,
              old_ref_id: elem.old_ref_id,
              schoolyear: elem.schoolyear,
              title: elem.title,
              user_id: elem.user_id,
              subjects: tmp_subjects,
            };
            clean_classes.push(tmp_class_obj);
          });

          // STEP 2 Classes speichern falls welche vorhanden sind
          // prüfen ob classes und timetable leer sind.
          if (yeardata.classes.length > 0) {
            // step 1.1 fire up add classes
            return fetch(URLS.API_CLASSES_IMPORT_SCHOOLYEAR, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${getState().authentication.token}`,
              },
              body: JSON.stringify(clean_classes),
            }).then((classesResponse) => {
              if (classesResponse.ok) {
                classesResponse.json().then((classesData) => {
                  response_data.classes = classesData.classes;
                  tmp_storage.holder.classlists = JSON.parse(
                    JSON.stringify(classesData.classes),
                  );

                  const newTimeTableEntries = [];
                  if (yeardata.timetable.entries.length > 0) {
                    yeardata.timetable.entries.forEach((ent) => {
                      const tmp_subject = response_data.subjects.find(
                        (p) =>
                          String(p.import_id) === String(ent.class.subject._id),
                      );
                      const tmp_class = classesData.classes.find(
                        (e) => String(e.import_id) === String(ent.class._id),
                      );

                      const base = { ...ent };
                      const tmp_subjectA = ent?.classes_ab_weeks?.A
                        ? response_data.subjects.find(
                            (p) =>
                              String(p.import_id) ===
                              String(ent.classes_ab_weeks.A.subject._id),
                          )
                        : null;

                      const tmp_classA = ent?.classes_ab_weeks?.A
                        ? classesData.classes.find(
                            (e) =>
                              String(e.import_id) ===
                              String(ent.classes_ab_weeks.A._id),
                          )
                        : null;

                      const tmp_subjectB = ent?.classes_ab_weeks?.B
                        ? response_data.subjects.find(
                            (p) =>
                              String(p.import_id) ===
                              String(ent.classes_ab_weeks.B.subject._id),
                          )
                        : null;

                      const tmp_classB = ent?.classes_ab_weeks?.B
                        ? classesData.classes.find(
                            (e) =>
                              String(e.import_id) ===
                              String(ent.classes_ab_weeks.B._id),
                          )
                        : null;

                      newTimeTableEntries.push({
                        ...base,
                        class: {
                          ...tmp_class,
                          subject: tmp_subject,
                        },
                        ...((tmp_classA || tmp_classB) && {
                          classes_ab_weeks: {
                            ...(tmp_classA && {
                              A: {
                                ...tmp_classA,
                                subject: tmp_subjectA,
                              },
                            }),
                            ...(tmp_classB && {
                              B: {
                                ...tmp_classB,
                                subject: tmp_subjectB,
                              },
                            }),
                          },
                        }),
                      });
                    });
                  }

                  const clean_timetable = {
                    saturday: yeardata.timetable.saturday,
                    schoolyear: yeardata.timetable.schoolyear,
                    user_id: yeardata.timetable.user_id,
                    entries: newTimeTableEntries,
                    ttId: uniqueId(),
                  };

                  // STEP 3 Timetable speichern
                  return fetch(URLS.API_TIMETABLE_URL, {
                    method: "put",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `bearer ${
                        getState().authentication.token
                      }`,
                    },
                    body: JSON.stringify(clean_timetable),
                  }).then((timetableResponse) => {
                    if (timetableResponse.ok) {
                      timetableResponse.json().then((timetableData) => {
                        tmp_storage.holder.timetable = timetableData.timetable;

                        // STEP 4 - save settings
                        yeardata.globalsettings.user_id = auth.userId;
                        return fetch(URLS.API_FETCH_COMMON_SETTINGS, {
                          method: "put",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `bearer ${
                              getState().authentication.token
                            }`,
                          },
                          body: JSON.stringify(yeardata.globalsettings),
                        }).then((settingsResponse) => {
                          if (settingsResponse.ok) {
                            settingsResponse.json().then((settingsData) => {
                              tmp_storage.settings =
                                settingsData.settings.settings;

                              // STEP 5 save schooldata

                              return fetch(URLS.API_SCHOOL_PERSONAL_URL, {
                                method: "put",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `bearer ${
                                    getState().authentication.token
                                  }`,
                                },
                                body: JSON.stringify(yeardata.schooldata),
                              }).then((schoolpersonalResponse) => {
                                if (schoolpersonalResponse.ok) {
                                  schoolpersonalResponse
                                    .json()
                                    .then((schoolpersonalData) => {
                                      // holder for current schoolyear and calculation/logic
                                      tmp_storage.holder.active_schoolyear = {
                                        year: schoolpersonalData.schoolpersonal
                                          .schoolyear,
                                        region:
                                          schoolpersonalData.schoolpersonal
                                            .region,
                                        show_created_success: true,
                                      };
                                      tmp_storage.holder.redirect = true;
                                      tmp_storage.holder.schooldata =
                                        schoolpersonalData.schoolpersonal;

                                      dispatch(
                                        loadSchoolyearData(
                                          schoolpersonalData.schoolpersonal
                                            .schoolyear,
                                          true,
                                          false,
                                          true,
                                        ),
                                      );

                                      // finalize
                                      // dispatch(loadSchoolyearData(data.schoolpersonal.schoolyear, true, false, true));
                                    });
                                } else {
                                  schoolpersonalResponse.json().then(() => {});
                                }
                              });
                            });
                          } else {
                            settingsResponse.json().then(() => {});
                          }
                        });
                      });
                    } else {
                      timetableResponse.json().then(() => {});
                    }
                  });
                });
              } else {
                classesResponse.json().then(() => {});
              }
            });
          }
          // else case keine klassen vorhanden

          return fetch(URLS.API_SCHOOL_PERSONAL_URL, {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${getState().authentication.token}`,
            },
            body: JSON.stringify(yeardata.schooldata),
          }).then((schoolpersonalResponse) => {
            if (schoolpersonalResponse.ok) {
              schoolpersonalResponse.json().then((schoolpersonalData) => {
                // holder for current schoolyear and calculation/logic
                tmp_storage.holder.active_schoolyear = {
                  year: schoolpersonalData.schoolpersonal.schoolyear,
                  region: schoolpersonalData.schoolpersonal.region,
                  show_created_success: true,
                };
                tmp_storage.holder.redirect = true;
                tmp_storage.holder.schooldata =
                  schoolpersonalData.schoolpersonal;

                dispatch(
                  loadSchoolyearData(
                    schoolpersonalData.schoolpersonal.schoolyear,
                    true,
                    false,
                    true,
                  ),
                );
              });
            } else {
              schoolpersonalResponse.json().then(() => {});
            }
          });
        });
      } else {
        response.json().then(() => {});
      }
    });
  };
}

/**
 * call on switch schoolyear
 * @param schoolyear
 * @param redirect
 * @param is_archive
 * @returns {function(*=): Promise<T>}
 */
export function loadSchoolyearData(
  schoolyear,
  redirect = false,
  is_archive = false,
  fromSetup = false,
) {
  return (dispatch, getState) => {
    const schoolyearParam = schoolyear || `latest`;

    fetch(
      `${URLS.API_SWITCH_SCOOLYEAR}/__REDUNDANT_PATH__/${schoolyearParam}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${getState().authentication.token}`,
        },
      },
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          data.data.holder.redirect = redirect;
          data.data.holder.is_archve = is_archive;

          if (fromSetup) {
            data.data.holder.active_schoolyear.latest =
              data.data.holder.active_schoolyear.year;
            data.data.holder.active_schoolyear.show_created_success = true;
          }

          dispatch(switchSchoolyearDataView(data.data.holder));
        });
      }
    });
  };
}
/**
 * call on switchView
 * @param userData
 * @returns {{type: string, data: *}}
 */
function switchSchoolyearDataView(holder) {
  const class_list = [];
  const classes = holder.classlists;
  if (classes.length > 0) {
    classes.forEach((elem) => {
      let has_days = 0;
      if (elem.subjects.length > 0) {
        elem.subjects.forEach((subs) => {
          if (subs.schooldays.length > 0) {
            has_days += 1;
          }
        });
      }
      if (has_days > 0) {
        class_list.push(elem);
      }
    });
  }

  return (dispatch) => {
    dispatch(setSchoolYear(holder.active_schoolyear));

    dispatch(
      setActiveView(class_list.length > 0 ? class_list[0].classId : null),
    );

    dispatch({
      type: FETCH_CLASSES_SUCCESS,
      classes: holder.classlists,
    });

    dispatch({
      type: FETCH_TIMETABLE,
      timetable: holder.timetable,
    });

    dispatch({
      type: FETCH_SUBJECTS_LIST,
      subjects: holder.subjectslist,
      message: "",
    });

    if (holder.redirect) {
      dispatch(routerNavigate("/zeitleiste"));
    }
  };
}

/**
 * call on login
 * @param userData
 * @returns {{type: string, data: *}}
 */
export function switchDataView() {
  return (dispatch, getState) => {
    const auth = getState().authentication;
    // TODO REMOVE THIS DEEP CLONE
    // IT WAS TOO DIFFICULT TO REWRITE TO IMMUTABLE UPDATES
    // IN A SHORT AMOUNT OF TIME
    const newHolder = structuredClone(auth.holder);

    const class_list = [];
    const classes = newHolder.classlists;
    if (classes.length > 0) {
      classes.forEach((elem) => {
        let has_days = 0;
        if (elem.subjects.length > 0) {
          elem.subjects.forEach((subs) => {
            if (subs.schooldays.length > 0) {
              has_days += 1;
            }
          });
        }
        if (has_days > 0) {
          class_list.push(elem);
        }
      });
    }

    dispatch(setSchoolYear(newHolder.active_schoolyear));

    dispatch(
      setActiveView(class_list.length > 0 ? class_list[0].classId : null),
    );

    dispatch({
      type: FETCH_CLASSES_SUCCESS,
      classes: newHolder.classlists,
    });

    dispatch({
      type: FETCH_TIMETABLE,
      timetable: newHolder.timetable,
    });

    dispatch({
      type: FETCH_SUBJECTS_LIST,
      subjects: newHolder.subjectslist,
      message: "",
    });
    dispatch({
      type: FETCH_PAYMENT_SETTINGS,
      settings: newHolder.payment_settings,
    });
  };
}
