import React from "react";
import {
  createSelectable,
  type TSelectableItemProps,
} from "react-selectable-fast";
import { classList } from "../../../../../common/utils/classList";

type Props = { minute: number };

function TimeRow({
  selectableRef,
  isSelected,
  isSelecting,
  minute,
}: TSelectableItemProps & Props) {
  return (
    <div
      ref={selectableRef}
      className={classList({
        "time-row item": true,
        selecting: isSelecting,
        selected: isSelected,
      })}
      data-minute={minute}
    >
      <div className="label" />
    </div>
  );
}
const SelectableTimeRow = createSelectable<Props>(TimeRow);
export default SelectableTimeRow;
