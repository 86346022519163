import { replace, type RouteObject } from "react-router-dom";

const redirectsMappings: Array<{ from: string; to: string }> = [
  // index
  { from: "/", to: "/zeitleiste" },
  // legacy urls
  { from: "/sequence_planung", to: "/zeitleiste" },
  { from: "/sequenz", to: "/zeitleiste" },
  { from: "/schuljahr_einrichten", to: "/schuljahre/einrichten" },
  // catch all
  { from: "*", to: "/zeitleiste" },
];

const redirects: RouteObject[] = redirectsMappings.map(({ from, to }) => {
  const isIndex = from === "/";
  return {
    path: !isIndex ? from : undefined,
    index: isIndex,
    loader: () => replace(to),
  };
});

export default redirects;
