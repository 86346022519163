import * as API from "../../common/constants/ApiRoutes";
import Auth from "../../common/services/Auth";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const SEND_RESET_LINK = "SEND_RESET_LINK";

export const STAMP_FIRST_TIME_LOGIN_NOTE = "STAMP_FIRST_TIME_LOGIN_NOTE";

const STAMP_NOTE_MESSAGE = "STAMP_NOTE_MESSAGE";

export function doLoginSuccess(userData) {
  return {
    type: LOGIN_SUCCESS,
    data: userData,
  };
}

/**
 * change username
 * @param name
 * @returns {function(*=): Promise<T>}
 */
export function changeUsername(firstname, lastname) {
  const payload = {
    firstname,
    lastname,
  };
  return (dispatch, getState) =>
    fetch(API.API_CHANGE_USERNAME_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getState().authentication.token}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: CHANGE_USERNAME,
            firstname: data.firstname,
            lastname: data.lastname,
          });
        });
      }
    });
}

/**
 * change password for autenticated users
 * @param password
 * @returns {function(*=): Promise<T>}
 */
export function changePassword(password) {
  const data = { password };
  return (dispatch, getState) =>
    fetch(API.API_CHANGE_PASSWORD_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${getState().authentication.token}`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        response.json().then(() => {
          dispatch(changePasswordSuccess());
        });
      } else {
        response.json().then(() => {
          dispatch(changePasswordError());
        });
      }
    });
}
function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD,
    status: 1,
  };
}
function changePasswordError() {
  return {
    type: CHANGE_PASSWORD_ERROR,
    status: 0,
  };
}

export function stampNoteMessage() {
  return {
    type: STAMP_NOTE_MESSAGE,
    status: "",
  };
}

export function stampFirstTimeLoginNote() {
  return {
    type: STAMP_FIRST_TIME_LOGIN_NOTE,
    status: false,
  };
}

/**
 * reset password process
 * @param password
 * @return {function(*=)}
 */
export function sendResetLink(email) {
  const data = { email };

  return (dispatch) =>
    fetch(API.API_CHANGE_RESETPASSWORD_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        response.json().then(() => {
          dispatch(sendResetLinkResult(1, ""));
        });
      } else {
        response.json().then((error) => {
          dispatch(sendResetLinkResult(-1, error));
        });
      }
    });
}
function sendResetLinkResult(status, error) {
  return {
    type: SEND_RESET_LINK,
    status,
    error,
  };
}

export function logout() {
  Auth.deauthenticateUser();
  return {
    type: LOGOUT,
  };
}
