import type { SequenceItemData } from "../../../features/sequence_items/types";
import * as CONST from "../../../common/constants/Components";

export function getOverlappingPositions(
  start_pos: number,
  end_pos: number,
  data: SequenceItemData[],
  itemId: number,
) {
  const overlappingRows = data.filter(
    (l) =>
      (start_pos >= l.pos && start_pos < l.pos_end) ||
      (end_pos > l.pos && end_pos < l.pos_end) ||
      (start_pos <= l.pos && end_pos >= l.pos_end) ||
      (Number(start_pos) === Number(l.pos) &&
        Number(end_pos) === Number(l.pos_end)),
  );

  const foundIndex = overlappingRows.findIndex((el) => el.id === itemId);

  if (overlappingRows.length > 1) {
    const posY =
      foundIndex === 0
        ? foundIndex * CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT +
          CONST.CALENDAR_ITEM_MULTIPLE_MARGIN
        : foundIndex *
          (CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT +
            CONST.CALENDAR_ITEM_MULTIPLE_MARGIN);

    const posYwithPadding =
      foundIndex > 0 ? posY + CONST.CALENDAR_ITEM_MULTIPLE_MARGIN : posY;
    const height = CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT;

    const overlappedRow = overlappingRows[foundIndex];
    if (overlappedRow) {
      const posX = overlappedRow.pos * CONST.CALENDAR_WEEK_UNIT;
      return {
        height,
        posY: posYwithPadding,
        posX,
      };
    }
  }

  const posY = CONST.CALENDAR_ITEM_DEF_Y;
  const height = CONST.CALENDAR_ITEM_DEF_HEIGHT;
  const posX = start_pos * CONST.CALENDAR_WEEK_UNIT;

  return {
    height,
    posY,
    posX,
  };
}

function isOverlapping(
  itemA: SequenceItemData,
  itemB: SequenceItemData,
): boolean {
  return (
    (itemA.pos >= itemB.pos && itemA.pos < itemB.pos_end) ||
    (itemA.pos_end > itemB.pos && itemA.pos_end < itemB.pos_end) ||
    (itemA.pos <= itemB.pos && itemA.pos_end >= itemB.pos_end) ||
    (Number(itemA.pos) === Number(itemB.pos) &&
      Number(itemA.pos_end) === Number(itemB.pos_end))
  );
}

export function getSequenceOverlappings(
  item: SequenceItemData,
  allItems: SequenceItemData[],
) {
  return allItems.reduce((overlapCount, currentItem) => {
    const isSameItem = currentItem === item;
    return !isSameItem && isOverlapping(currentItem, item)
      ? overlapCount + 1
      : overlapCount;
  }, 0);
}

export function getClusterHeight(maxOverlaps: number) {
  return maxOverlaps >= 2
    ? CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT +
        15 +
        maxOverlaps * (CONST.CALENDAR_ITEM_MULTIPLE_HEIGHT + 15)
    : CONST.CALENDAR_ITEMS_ROW_HEIGHT;
}
