// return normalized object { r, g, b } of color channels. Values between 0 and 1
type RGB = { r: number; g: number; b: number };
function hexToRgb(hex: string): RGB | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const normalizedHex = hex.replace(shorthandRegex, (_, r, g, b) =>
    [r, r, g, g, b, b].join(""),
  );

  const result = normalizedHex.match(
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
  );

  if (!result || !result[1] || !result[2] || !result[3]) {
    return null;
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

/*
  apply a formula to see if a color appears dark or bright
  130 is the theshold (0-255). Above is bright, below is dark.
*/
function isBrightColor(hex: string): boolean {
  const rgb = hexToRgb(hex);
  if (!rgb) {
    return false;
  }
  const { r, g, b } = rgb;

  const brightness = Math.sqrt(
    0.241 * r ** 2 + 0.691 * g ** 2 + 0.068 * b ** 2,
  );
  return brightness > 130;
}

export { isBrightColor };
