/* CacheBuster component */
import { useState, useEffect } from "react";
import { COMMIT_REF } from "../../app/config";

// adapted from https://github.com/substack/semver-compare/blob/master/index.js
function semverCompare(a: string, b: string): -1 | 0 | 1 {
  const pa = a.split(".");
  const pb = b.split(".");
  for (let i = 0; i < 3; i += 1) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (Number.isNaN(na) && !Number.isNaN(nb)) return -1;
  }
  return 0;
}

const refreshCacheAndReload = (): void => {
  // eslint-disable-next-line no-console
  console.log("Hard reloading...");
  window.location.reload();
};

type ChildrenCallback = (props: {
  loading: boolean;
  isLatestVersion: boolean;
  refreshCacheAndReload: () => void;
}) => React.JSX.Element | null;

export function CacheBuster(props: { children: ChildrenCallback }) {
  const [state, setState] = useState({
    loading: true,
    isLatestVersion: false,
  });

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = COMMIT_REF;

        const shouldForceRefresh =
          semverCompare(latestVersion, currentVersion) === 1;
        if (shouldForceRefresh) {
          // eslint-disable-next-line no-console
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`,
          );
          setState({ loading: false, isLatestVersion: false });
        } else {
          // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          setState({ loading: false, isLatestVersion: true });
        }
      });
  }, []);
  const { loading, isLatestVersion } = state;
  return props.children({
    loading,
    isLatestVersion,
    refreshCacheAndReload,
  });
}
