import React from "react";
import { uniqueId } from "../../sequence_calendar/Utils";

function TimeTableLabelItem(props) {
  return (
    <div className="hour-label-item" key={uniqueId()}>
      <div className="hour">{props.idx}.</div>
      <div className="text">Stunde</div>
    </div>
  );
}
export default TimeTableLabelItem;
