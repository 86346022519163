import { type LoaderFunction, replace } from "react-router-dom";
// import { type HandlerCtx } from "../../router/data-strategy";
// import { selectSchoolRegion } from "../../combined-selectors/index";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";
import store from "../../store";
import { api } from "../../../services/api";
import { activeSequenceView } from "../../../features/sequence/sequence-slice";
import { selectSequenceSettings } from "../../../features/settings/settings-slice";

function getUeIndex(
  searchParams: URLSearchParams,
  sequenceSettings: Array<{ seq_id: number; active_day: number }>,
  sequenceItemId: number,
): number {
  // 1. first try to get ueIdx param
  const paramIndex = Number(searchParams.get("ueIndex"));
  if (!Number.isNaN(paramIndex)) {
    return paramIndex;
  }
  // 2. if no id or index is set check the settings for last openend day
  const seqSetting = sequenceSettings.find(
    (elem) => Number(elem.seq_id) === sequenceItemId,
  );
  if (seqSetting) {
    return seqSetting.active_day;
  }
  // 3. default to 0
  return 0;
}

export const loader: LoaderFunction = async (
  { params, request },
  // handlerCtx,
) => {
  // const { onUnload } = handlerCtx as HandlerCtx;

  const year = selectCurrentSchoolyear(store.getState());
  // make sure to wait till school is loaded so we have the region
  const schoolPromise = store.dispatch(api.endpoints.getSchool.initiate(year));
  await schoolPromise;

  const data = await store.dispatch(activeSequenceView(params.sequenceId!));
  if (!data) {
    throw new Response("Sequence not found", { status: 404 });
  }

  const { searchParams } = new URL(request.url);
  const state = store.getState();

  // if no ueId is set in Query params, lookup id and normalize to ueId (default to first if no other is set)
  if (!searchParams.has("ueId")) {
    const { sequence } = state.sequences;
    const ueIdx = getUeIndex(
      searchParams,
      selectSequenceSettings(state),
      sequence.sequence_item_id,
    );
    const slot = sequence.timeslots[ueIdx];
    if (slot) {
      return replace(`?${new URLSearchParams({ ueId: slot.ueId })}`);
    }
  }

  // if no query param is used

  // const region = selectSchoolRegion(store.getState());

  // const queryPromises = [
  //   store.dispatch(
  //     api.endpoints.patchSequenceDetail.initiate(
  //       {
  //         id: params.sequenceId!,
  //         schoolyear: year,
  //         region,
  //       },
  //       { forceRefetch: true }, // always fetch until we can properly invalidate when Sequence Items change
  //     ),
  //   ),
  // ];
  //
  // // make sure to remove the subscriptions when the page unloads
  // onUnload(() => {
  //   [schoolPromise, ...queryPromises].forEach((promise) =>
  //     promise.unsubscribe(),
  //   );
  // });
  //
  // // wait for data to have loaded
  // await Promise.all(queryPromises);

  return null;
};
