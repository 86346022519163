import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { deepSet } from "../../common/utils/assortments";
import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import { createTags } from "../taglist/actions";
import { type Sequence } from "../sequence/types";
import { type SequenceItem } from "../sequence_items/types";
import { type AppThunk } from "../../app/store";
import { type Class } from "../classes/types";

export type SliceState = {
  classlists: Class[];
  sequences: { sequence_items: SequenceItem[] };
  sequences_list: Sequence[];
};

const initialState: SliceState = {
  classlists: [],
  sequences: { sequence_items: [] },
  sequences_list: [],
};

const timelineSlice = createSlice({
  name: "sequence_items",
  initialState,
  reducers: {
    gotSequenceItems(_, action: PayloadAction<SliceState>) {
      return action.payload;
    },
    saveSequenceItems(state, action: PayloadAction<SequenceItem[]>) {
      return {
        ...state,
        sequences: {
          ...state.sequences,
          sequence_items: action.payload,
        },
      };
    },

    stampClusterItem(state, action: PayloadAction<string>) {
      const cluster_index = Number(action.payload);
      const newSequenceItems = deepSet(
        [cluster_index, "lastupdate"],
        String(Date.now()),
        state.sequences.sequence_items,
      );
      return {
        ...state,
        sequences: {
          ...state.sequences,
          sequence_items: newSequenceItems,
        },
      };
    },
  },
});

export default timelineSlice.reducer;

// ACTIONS
const { actions } = timelineSlice;
export const { stampClusterItem } = actions;

// THUNKS

export function getSequenceItems(schoolyear: number): AppThunk {
  return (dispatch) =>
    fetch(
      `${URLS.API_SEQUENCE_ITEMS_URL_INIT}/__REDUNDANT_PATH__/${schoolyear}`,
      {
        method: "get",
        headers: { Authorization: `bearer ${Auth.getToken()}` },
      },
    ).then((response) => {
      if (response.ok) {
        response.json().then((seqItemData) => {
          dispatch(actions.gotSequenceItems(seqItemData));
          dispatch(createTags(seqItemData));
        });
      }
    });
}

export function updateSequenceItems(
  items: SequenceItem[],
  schoolyear: number,
): AppThunk {
  const user_id = Auth.getUserId();
  const payload = {
    sequence_items: items,
    user_id,
    schoolyear,
  };

  return (dispatch) => {
    dispatch(actions.saveSequenceItems(items));

    return fetch(URLS.API_SEQUENCE_ITEMS_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch(actions.saveSequenceItems(data.item.sequence_items));
        });
      }
    });
  };
}

/**
 * update from template usage
 * @param items
 * @param schoolyear
 * @returns {function(*=): Promise<T>}
 */
export function updateSequenceItemsFromTemplate(
  items: unknown,
  sequences: Sequence,
  schoolyear: number,
  templateId: string,
): AppThunk {
  const user_id = Auth.getUserId();
  const payload = {
    sequence_items: items,
    user_id,
    schoolyear,
    sequences,
    templateId,
  };
  return (dispatch) =>
    fetch(URLS.API_SEQUENCE_ITEMS_FROM_TEMPLATE, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch(actions.saveSequenceItems(data.item.sequence_items));
        });
      }
    });
}
