import React from "react";
import { TwitterPicker } from "react-color";
import { uniqueId } from "../../../../common/components/sequence_calendar/Utils";

const popover = {
  position: "absolute",
  zIndex: "2",
};
const cover = {
  position: "fixed",
  top: "20px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export default class Subject extends React.Component {
  constructor(props) {
    super(props);
    this.removeSubject = this.removeSubject.bind(this);
    this.renderListForm = this.renderListForm.bind(this);
    this.renderEditForm = this.renderEditForm.bind(this);
    this.titleChange = this.titleChange.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.pickerShowHide = this.pickerShowHide.bind(this);
    this.handlePickerClose = this.handlePickerClose.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateSubject = this.updateSubject.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.acceptDeleteNote = this.acceptDeleteNote.bind(this);
    this.cleanUpClassObj = this.cleanUpClassObj.bind(this);
    this._removeSubject = this._removeSubject.bind(this);

    this.state = {
      title: this.props.title,
      color: this.props.color,
      edit_mode: false,
      displayColorPicker: false,
      show_note: false,
    };
  }

  handleEditForm(e) {
    e.preventDefault();
    this.setState({
      edit_mode: true,
    });
  }

  handlePickerClose(e) {
    e.preventDefault();
    this.setState({ displayColorPicker: false });
  }

  pickerShowHide(e) {
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  cleanUpClassObj() {
    const probs_classes = []; // this.props.classes;
    this.props.classes.forEach((org) => {
      probs_classes.push({ ...org });
    });

    const update_classes = [];
    probs_classes.forEach((classes) => {
      let infected = 0;
      classes.subjects.forEach((subs) => {
        if (subs._id === this.props._id) {
          infected += 1;
        }
      });
      if (infected > 0) {
        update_classes.push(classes);
      }
    });

    const clean_list = [];

    update_classes.forEach((classes) => {
      const subs_list = [];
      classes.subjects.forEach((subs) => {
        if (subs._id !== this.props._id) {
          subs_list.push(subs);
        }
      });
      const foo = classes;
      foo.subjects = subs_list;
      clean_list.push(foo);
    });

    // cleanup timetables
    const tt_list = { ...this.props.timetable };
    const tmp_tt = [];
    if (tt_list.entries.length > 0) {
      tt_list.entries.forEach((tt) => {
        if (tt.class.subject._id !== this.props._id) {
          tmp_tt.push(tt);
        }
      });
      tt_list.entries = tmp_tt;
    }
  }

  _removeSubject(e, forceDelete) {
    e.preventDefault();
    this.removeSubject(forceDelete);
  }

  /**
   *  remove an subject
   */
  removeSubject(forceDelete) {
    if (forceDelete) {
      this.cleanUpClassObj();
      this.props.removeSubjectList(this.props._id);
    } else {
      let cnt = 0;
      // console.log(this.props);
      this.props.classes.forEach((classes) => {
        classes.subjects.forEach((subs) => {
          if (subs._id === this.props._id) {
            cnt += 1;
          }
        });
      });
      if (cnt === 0) {
        // no subjects in classes found -> delete
        this.props.removeSubjectList(this.props._id);
      } else {
        this.setState({
          show_note: true,
        });
      }
    }
  }

  titleChange(value) {
    this.setState({
      title: value,
    });
  }

  colorChange(color) {
    // console.log(color);
    this.setState({
      color: color.hex,
    });
  }

  cancelEdit(e) {
    e.preventDefault();
    this.setState({
      title: this.props.title,
      color: this.props.color,
      edit_mode: false,
      displayColorPicker: false,
    });
  }

  cancelDelete(e) {
    e.preventDefault();
    this.setState({
      show_note: false,
    });
  }

  acceptDeleteNote(e) {
    e.preventDefault();
    this.removeSubject(true);
  }

  updateSubject(e) {
    e.preventDefault();
    const item = {
      title: this.state.title,
      color: this.state.color,
      _id: this.props._id,
    };
    this.props.updateSubject(item);
  }

  renderListForm() {
    return (
      <div className="subject_item">
        <div key={uniqueId()} className="subject">
          <span className="color">
            <span
              className="circle"
              style={{ backgroundColor: this.props.color }}
            />
          </span>
          <span className="title">{this.props.title}</span>
          <span className="actions">
            <a
              href="#"
              onClick={(e) => {
                this.handleEditForm(e);
              }}
            >
              <img src="/assets/images/edit-icon.png" className="form-icon" />
            </a>
            &nbsp;
            <a
              href="#"
              onClick={(e) => {
                this._removeSubject(e, false);
              }}
            >
              <img src="/assets/images/delete-icon.png" className="form-icon" />
            </a>
          </span>
        </div>

        {this.state.show_note && (
          <div className="message-box notice">
            <div className="text">
              Willst du das Fach wirklich löschen?
              <br />
              Wir empfehlen keine Fächer zu löschen, die du zukünftig
              unterrichten könntest, selbst wenn du sie 2024/2025 nicht
              brauchst.
              <br />
              <br />
              <button
                className="btn btn-dark"
                onClick={(e) => {
                  this.acceptDeleteNote(e);
                }}
              >
                ja, bitte löschen
              </button>
              &nbsp; &nbsp; &nbsp;
              <button
                className="btn btn-dark"
                onClick={(e) => {
                  this.cancelDelete(e);
                }}
              >
                Abbrechen
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * edit localy first .....
   * @return {XML}
   */
  renderEditForm() {
    const enabled =
      this.state.color !== this.props.color ||
      this.state.title !== this.props.title;
    return (
      <div className="subject_item_container inside">
        <div className="fields">
          <div className="field_input">
            <label>Fach (Name)</label>
            <input
              className="inputfield"
              placeholder="z.B. Mathematik"
              value={this.state.title}
              onChange={(event) => this.titleChange(event.target.value)}
            />
          </div>
          <div className="file_color">
            <label>Farbe</label>
            <div
              className="color_wrapper"
              style={{ backgroundColor: this.state.color }}
              onClick={(e) => {
                this.pickerShowHide(e);
              }}
            >
              <div className="triangle_color_wrapper">
                <span className="triangle_white_down" />
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }} />
          {this.state.displayColorPicker ? (
            <div style={popover}>
              <div
                className="cover"
                style={cover}
                onClick={(e) => {
                  this.handlePickerClose(e);
                }}
              />
              <div className="picker">
                <TwitterPicker
                  key={`pciker-key-${this.props.key}`}
                  colors={this.props.pre_colors}
                  color={this.state.color}
                  onChange={this.colorChange}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="btn_wrapper">
          <button
            disabled={!enabled}
            className="btn btn-dark"
            onClick={(e) => {
              this.updateSubject(e);
            }}
          >
            Speichern
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            className="btn btn-dark"
            onClick={(e) => {
              this.cancelEdit(e);
            }}
          >
            Abbrechen
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="subject_list_wrapper">
        {this.state.edit_mode ? this.renderEditForm() : this.renderListForm()}
      </div>
    );
  }
}
