import React from "react";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import ABWeeksDialog from "./ABWeeksDialog";

export default class ABWeeksCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active_elem: -1,
    };

    this.setActive = this.setActive.bind(this);
    this.render_inner_wrapper = this.render_inner_wrapper.bind(this);
    this.resetActive = this.resetActive.bind(this);
    this.updateABSettings = this.updateABSettings.bind(this);
    this.fillInitialAbList = this.fillInitialAbList.bind(this);
    this.holidayAbSettings = this.holidayAbSettings.bind(this);
  }

  componentDidMount() {
    const { settings } = this.props;
    const { year } = this.props.schoolyear;

    const shouldInitializeAbWeeks =
      settings.settings.ab_weeks &&
      settings.settings.ab_weeks[year] &&
      Number(settings.settings.ab_weeks[year].active) === 1 &&
      (!settings.settings.ab_weeks[year].entries ||
        settings.settings.ab_weeks[year].entries.length === 0);

    const initializeAbWeeksWithHolidayBreak =
      shouldInitializeAbWeeks &&
      Number(settings.settings.ab_weeks[year].holiday_break) === 1;

    if (initializeAbWeeksWithHolidayBreak) {
      this.holidayAbSettings(true, this.fillInitialAbList());
    } else if (shouldInitializeAbWeeks) {
      const newSettings = immutableUpdate(settings, (draft) => {
        draft.schoolyear = year;
        draft.settings.ab_weeks[year].entries = this.fillInitialAbList();
      });
      this.props.updateSettings(newSettings);
    }
  }

  /**
   * set active kw col
   * @param kw
   */
  setActive(kw) {
    this.setState({
      active_elem: kw,
    });
  }

  fillInitialAbList() {
    return this.props.data.map((elem) => ({
      kw: elem.kw,
      ab_week: elem.ab_week,
      holiday: elem.holiday,
    }));
  }

  /**
   * if holiday break == true - no ab weeks on holidays
   * @param holiday_break
   * @param preparedEntries
   */
  holidayAbSettings(holiday_break, preparedEntries = null) {
    const { props } = this;
    const schoolyear = props.schoolyear.year;
    const { entries } = props.settings.settings.ab_weeks[schoolyear];
    const entryList = preparedEntries !== null ? preparedEntries : entries;

    // die erste ferien kw ermitteln
    const holidayIdx = entryList.findIndex((elem) => Boolean(elem.holiday));

    // ferien kw welche nicht das erste element ist
    const start_week_type =
      holidayIdx >= 1 ? entryList[holidayIdx - 1].ab_week : "A";

    // even odd strutktur bereitstellen
    const even_odd = start_week_type === "B" ? ["A", "B"] : ["B", "A"];

    const newEntries = immutableUpdate(entryList, (draft) => {
      if (!holiday_break) {
        // in den Ferien fortsetzen
        let cnt = 0;
        draft.forEach((elem, index) => {
          if (cnt === 2) cnt = 0;
          if (index >= holidayIdx) {
            elem.ab_week = even_odd[cnt];
            cnt += 1;
          }
        });
      } else {
        // in den ferien unterbrechen
        let cnt = 0;
        draft.forEach((elem, index) => {
          if (cnt === 2) cnt = 0;
          if (index >= holidayIdx) {
            if (elem.holiday) {
              elem.ab_week = "-";
            } else {
              elem.ab_week = even_odd[cnt];
              cnt += 1;
            }
          }
        });
      }
    });

    const newSettings = immutableUpdate(props.settings, (draft) => {
      draft.settings.ab_weeks[schoolyear].holiday_break = holiday_break;
      draft.settings.ab_weeks[schoolyear].entries = newEntries;
      draft.schoolyear = schoolyear;
    });
    this.props.updateSettings(newSettings);
  }

  updateABSettings(ab_week, idx) {
    const even_odd = ab_week === "A" ? ["A", "B"] : ["B", "A"];

    const schoolyear = this.props.schoolyear.year;
    const { settings } = this.props;
    const { entries } = settings.settings.ab_weeks[schoolyear];
    const holiday_break = settings.settings.ab_weeks[schoolyear].holiday_break
      ? settings.settings.ab_weeks[schoolyear].holiday_break
      : false;
    let cnt = 0;
    const newEntries = entries.map((elem, index) => {
      if (holiday_break && elem.holiday) {
        return elem; // next iteration
      }

      if (index >= idx) {
        const weekType = even_odd[cnt % 2];
        cnt += 1;
        return { ...elem, ab_week: weekType };
      }
      return elem;
    });

    const newSettings = immutableUpdate(settings, (draft) => {
      draft.schoolyear = schoolyear;
      draft.settings.ab_weeks[this.props.schoolyear.year].entries = newEntries;
    });
    this.props.updateSettings(newSettings);
  }

  /**
   * reset active kw col
   */
  resetActive() {
    this.setState({
      active_elem: -1,
    });
  }

  /**
   * render kw col with dialog components
   * @param type
   * @param kw
   * @returns {*}
   */
  render_inner_wrapper(type, kw, holiday, idx, holiday_break) {
    return (
      <ABWeeksDialog
        week_type={type}
        calendar_week={kw}
        idx={idx}
        active_idx={this.state.active_elem}
        holiday={holiday}
        holiday_break={holiday_break}
        resetActive={this.resetActive}
        setActiveCol={this.setActive}
        updateABList={this.updateABSettings}
        updateHolidayRhythm={this.holidayAbSettings}
      />
    );
  }

  render() {
    const { settings, data, cssWidth } = this.props;

    let ab_list;
    let holiday_break;
    if (
      settings.settings.ab_weeks &&
      settings.settings.ab_weeks[this.props.schoolyear.year].entries
    ) {
      ab_list = settings.settings.ab_weeks[this.props.schoolyear.year].entries;
      holiday_break =
        settings.settings.ab_weeks[this.props.schoolyear.year].holiday_break;
    }

    const { active_elem } = this.state;
    let inner_wrapper;

    const css_childs_container = {
      height: "auto",
    };

    const childs = data.map((d, ix) => {
      if (!ab_list[ix]) {
        return null; // empty return
      }

      const { ab_week } = ab_list[ix];

      let css_classes = `childs-row week-row-child-${d.year}-${d.kw}`;
      const childRowCss = {
        width: cssWidth,
      };

      if (d.month_break) {
        css_classes = `childs-row end-month week-row-child-${d.year}-${d.kw}`;
      }

      if (d.holiday) {
        css_classes += ` ${d.holiday}`;
      }

      if (Number(active_elem) === Number(d.kw)) {
        css_classes += " active";
      }

      inner_wrapper = this.render_inner_wrapper(
        ab_week,
        d.kw,
        d.holiday,
        ix,
        holiday_break,
      );
      return (
        <div
          key={d.id}
          id={d.id}
          ref={d.id}
          data-pos={d.pos_start}
          data-weeknumber={d.kw}
          data-weektype={ab_week}
          data-classname={css_classes}
          style={childRowCss}
          className={css_classes}
        >
          {inner_wrapper}
        </div>
      );
    });

    return (
      <div className="ab-inner">
        <div style={css_childs_container} className="childs-container">
          {childs}
        </div>
      </div>
    );
  }
}
