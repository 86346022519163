import React, { useEffect, useState, useRef } from "react";
import {
  Link,
  useParams,
  useSearchParams,
  useRouteError,
  type ErrorResponse,
} from "react-router-dom";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector, useAppDispatch } from "../../hooks";
import Articulation from "./Articulation/ArticulationComponent";
import FGEditor from "../../components/editor/FGEditor";
import Material from "../../components/material/Materials";
import SequenceMetaHeader from "./parts/HeaderSequenceMetaComponent";
import {
  sequenceMaterialSlice,
  sequenceMaterialThunks,
} from "../../../features/material/material-slice";
import { sequenceMaterialSelector } from "../../../features/sequence/sequence-slice";
import {
  PageTitle,
  useEffectExceptForFirstRender,
} from "../../../common/utils/hooks";
import * as ACTIONS from "../../../features/sequence/sequence-slice";
import { setActiveView } from "../../../features/active-view/active-view-slice";
import {
  selectABWeekSettings,
  selectSettings,
} from "../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";
import {
  selectSchoolRegion,
  selectSequenceDetail,
} from "../../combined-selectors/index";
import { type RootState } from "../../store";

function NotFoundErrorScreen() {
  const error = useRouteError() as ErrorResponse;
  if (error.status === 404) {
    return (
      <div className="tw-pf">
        <div className="flex flex-col items-center justify-center mt-40 text-gray-10">
          <div className="text-3xl font-bold">Diese Seite existiert nicht</div>
          <div className="text-xl mt-3.5 text-center">
            Vielleicht wurde die Sequenz, zu der der Link gehört,
            <br />
            gelöscht, oder im Link war ein Tippfehler.
          </div>
          <Link
            to="/zeitleiste"
            className="block mt-4.5 group px-1.5 bg-green text-sm text-gray-100 font-bold py-3 rounded hover:shadow-xl transition-shadow duration-200 focus:shadow-xl"
          >
            <span className="py-1.5 px-6 border-green group-focus:border-gray-100 border-dashed border-2 border-indigo-600 rounded-sm">
              Zur Zeitleiste
            </span>
          </Link>
        </div>
      </div>
    );
  }

  throw error;
}

const PLACEHOLDER_MAIN_NOTES: string =
  "Trage hier Notizen zu dieser Sequenz ein";

type State = {
  unsaved_editor_value: boolean;
  force_note_save: boolean;
  current_tab_settings: string;
  setupSettings: boolean;
};

const selectProps = createSelector(
  [
    selectSequenceDetail,
    selectCurrentSchoolyear,
    selectSettings,
    selectABWeekSettings,
    (state: RootState) => state.active_view,
    selectSchoolRegion,
  ],
  (sequences, schoolyear, settings, abWeekSettings, activeView, region) => ({
    sequenceNote: sequences.note,
    active_sequence: sequences.active_sequence_item,
    active_sequence_cluster: sequences.sequenceCluster,
    currentSchoolyear: schoolyear,
    settings, // setting active tabs from database
    abWeekSettings,
    active_view: activeView,
    region,
  }),
);
type Props = ReturnType<typeof selectProps>;

function Component() {
  const [state, setState] = useState<State>({
    unsaved_editor_value: false,
    force_note_save: false,
    current_tab_settings: "",
    setupSettings: false,
  });
  const nodeRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const { sequenceId = "" } = useParams();

  const [queryParams] = useSearchParams();
  // if ueId not exists in queryString - ueId is null
  // example request: sequenzen/{sequenceId}?ueId=626a97b0e42e43f9ae6faf5d
  const requestedUeId = queryParams.get("ueId");

  const props: Props = useAppSelector((rootState: RootState) =>
    selectProps(rootState, { ueId: requestedUeId }),
  );

  const handleClick = (e: MouseEvent): void => {
    if (nodeRef.current && nodeRef.current.contains(e.target as Node)) {
      return;
    }
    const { unsaved_editor_value } = state;
    if (unsaved_editor_value) {
      setState({
        ...state,
        force_note_save: true,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffectExceptForFirstRender(() => {
    setState({
      ...state,
      current_tab_settings: props.active_sequence.tab,
    });
  }, [props.active_sequence.tab]);

  useEffect(() => {
    if (!state.setupSettings) {
      const main_tab = props.active_sequence.tab
        ? props.active_sequence.tab
        : "schoolunites";

      setState({
        ...state,
        current_tab_settings: main_tab,
        setupSettings: true,
      });
    }

    // setClassTab
    const currentClassId = props.active_view.classroom_id;
    const sequenceClassId = props.active_sequence_cluster.classroomId;

    if (sequenceClassId && currentClassId !== sequenceClassId) {
      dispatch(setActiveView(sequenceClassId));
    }
  }); // intentionally no dependencies because that's how it was before, refactor this

  const handleMainNoteUpdate = (input: string) => {
    setState({
      ...state,
      unsaved_editor_value: true,
    });
    dispatch(ACTIONS.changeSequenceNote(input));
  };

  const resetForceToSaveNote = () => {
    setState({ ...state, force_note_save: false });
  };

  /**
   * update articulation text from heade
   */
  const updateSequenceItemsNote = () => {
    setState({
      ...state,
      unsaved_editor_value: false,
      force_note_save: false,
    });
    dispatch(
      ACTIONS.updateSequenceItemData(sequenceId, {
        ...props.active_sequence,
        note: props.sequenceNote,
      }),
    );
  };

  /**
   * update tab
   */
  const updateSequenceItemsTab = (selectedTab: string) => {
    setState({
      ...state,
      current_tab_settings: selectedTab,
    });
    dispatch(
      ACTIONS.updateSequenceItemData(sequenceId, {
        ...props.active_sequence,
        tab: selectedTab,
      }),
    );

    // temporaly hotfix: create timeslots with ueIds on first schoolunites tab click
    if (selectedTab === "schoolunites") {
      dispatch(ACTIONS.updateTimeSlots());
    }
  };

  const sequenceKeys = Object.keys(props.active_sequence);
  const sequenceIsPlausible = ["id", "cluster", "title"].every((key) =>
    sequenceKeys.includes(key),
  );

  const border_notes_css_class =
    state.current_tab_settings === "notes" ? "no-left-top" : "";
  const { force_note_save } = state;
  const title = `${props.active_sequence.title ?? "Sequenzdetail"} | Freigeist`;

  return (
    <div id="sequence_wrapper">
      <PageTitle title={title} />
      <div>
        <div id="component_nav">
          <div className="content-inner-wrapper">
            <SequenceMetaHeader />
          </div>
        </div>

        <div className="content-inner-wrapper">
          <Tabs
            name="sequenceMainTab"
            className="tab-main-wrapper"
            handleSelect={updateSequenceItemsTab}
            selectedTab={state.current_tab_settings}
          >
            <div className="tab-links">
              <TabLink disabled={!sequenceIsPlausible} to="notes">
                Notizen
              </TabLink>
              <TabLink disabled={!sequenceIsPlausible} to="material">
                Material
              </TabLink>
              <TabLink disabled={!sequenceIsPlausible} to="schoolunites">
                Unterrichtseinheiten
              </TabLink>
            </div>

            <div className={`content ${border_notes_css_class}`}>
              <TabContent for="notes">
                <div
                  ref={nodeRef}
                  className="tw-pf editor-wrapper note-editor-wrapper"
                >
                  <FGEditor
                    print_id="seq_notes_editor"
                    key="seq_notes_editor"
                    placeholder={PLACEHOLDER_MAIN_NOTES}
                    value={props.sequenceNote}
                    onChange={handleMainNoteUpdate}
                    onBlurCall={updateSequenceItemsNote}
                    force_save_outside={force_note_save}
                    resetForceToSaveNote={() => {
                      resetForceToSaveNote();
                    }}
                  />
                </div>
              </TabContent>

              <TabContent for="material">
                <Material
                  ueId={null}
                  actions={sequenceMaterialSlice.actions}
                  thunks={sequenceMaterialThunks}
                  modelSelector={sequenceMaterialSelector}
                  isVisible={state.current_tab_settings === "material"}
                />
              </TabContent>
              <TabContent for="schoolunites">
                <Articulation
                  isVisible={state.current_tab_settings === "schoolunites"}
                />
              </TabContent>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const errorElement = <NotFoundErrorScreen />;

export { Component, errorElement };

export { loader } from "./loader";
