import { Link, Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import Modal from "react-modal";
import parseHtmlToReact from "html-react-parser";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import AUTH from "../../common/services/Auth";
import {
  isPaymentActive,
  calculateEndDateFree,
} from "../../common/utils/PaymentCalculations";
import WistiaEmbed from "../components/wistia/WistiaEmbed";
import { withRouter } from "../../common/utils/routing";
import { isPayStateFree } from "../../common/utils/paystates";
import { FreigeistHeader } from "../../common/web-components/freigeist-header";
import * as AUTH_ACTIONS from "../../features/authentication/actions";
import * as PAYMENT_ACTIONS from "../../features/payment/actions";
import { setActiveView } from "../../features/active-view/active-view-slice";
import * as VIEW_ACTION from "../../features/dataview/actions";
import { sortClassesAlphabetically } from "../../common/utils/assortments";
import { selectSettings } from "../../features/settings/settings-slice";
import { selectCurrentSchoolyearData } from "../../features/current-schoolyear/current-schoolyear-slice";
import { api } from "../../services/api";

const ModalStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minHeight: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const switchClassView = (props) => {
  const searchParams = new URLSearchParams(props.location.search);
  const classId = Number(searchParams.get("classId"));

  if (classId && props.active_view.classroom_id !== classId) {
    props.set_active_view(classId);
  }
};

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  active_view: state.active_view,
  authentication: state.authentication,
  classes: sortClassesAlphabetically(state.classes),
  payment: state.payment,
  settings: selectSettings(state),
  currentNote: api.endpoints.getCurrentNote.select()(state).data,
});

const mapDispatchToProps = {
  set_active_view: setActiveView,
  logout: AUTH_ACTIONS.logout,
  updatePaymentSettings: PAYMENT_ACTIONS.handlePaymentSettings,
  getPaymentSettings: PAYMENT_ACTIONS.getPaymentSettings,
  switchSchoolyear: VIEW_ACTION.loadSchoolyearData,
  updateSettings: api.endpoints.updateSettings.initiate,
  stampFirstTimeLoginNote: AUTH_ACTIONS.stampFirstTimeLoginNote,
  markNoteAsRead: api.endpoints.markNoteAsRead.initiate,
};

/**
 * rendering of last payment note modal for REFS
 * @returns {*}
 */
function NotificationLayer(props) {
  useEffect(() => {
    if (props.currentNote) {
      document.getElementById("content-container").classList.add("isblured");
    } else {
      document.getElementById("content-container").classList.remove("isblured");
    }
  }, [props.currentNote]);

  if (!props.currentNote) {
    return null;
  }
  const { content, label, headline, link_txt, link_path } = props.currentNote;
  const LayerContent = content.replace(/(\r\n|\n|\r)/gm, "<br>");
  const has_link = link_path !== "";
  const notification_modal_styles = {
    content: {
      top: "85px",
      left: "50%",
      right: "50%",
      bottom: "auto",
      transform: "translateX(-50%)",
      width: "610px",
    },
  };
  return (
    <div className="modal-wrapper" id="user-notification">
      <Modal
        isOpen={!!props.currentNote}
        onRequestClose={props.closeNotification}
        style={notification_modal_styles}
        ariaHideApp
        className="paymentModal"
        overlayClassName="paymentOverlay"
        contentLabel="Hinweis"
      >
        <div id="notification" className="paymenModalContent">
          <div className="closer">
            <a onClick={props.closeNotification}>
              <img src="/assets/images/close_modal.png" />
            </a>
          </div>
          <div className="text-wrapper">
            <span className="label">{label}</span>
            <span className="headline">{headline}</span>
            {parseHtmlToReact(LayerContent)}

            {has_link && (
              <span className="link-wrapper">
                <a href={link_path} target="_blank" rel="noreferrer">
                  {link_txt}
                </a>
              </span>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_loader: false,
      payment_modal_open: false,
      first_time_layer_open: true,
    };
    this.logout = this.logout.bind(this);
    this.getClasslist = this.getClasslist.bind(this);
    this.renderLastPaymentNotificationModal =
      this.renderLastPaymentNotificationModal.bind(this);

    this.dontShowFirstReminderModal =
      this.dontShowFirstReminderModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.renderArchivContainer = this.renderArchivContainer.bind(this);
    this.switchArchivView = this.switchArchivView.bind(this);

    this.closeNotification = this.closeNotification.bind(this);
    this.renderFirstTimeLoginInfo = this.renderFirstTimeLoginInfo.bind(this);
    this.closeFirstTimeNotification =
      this.closeFirstTimeNotification.bind(this);
    this.renderTopNavigationBar = this.renderTopNavigationBar.bind(this);
  }

  /**
   * fired up on load after login.
   * load classlists....
   */
  UNSAFE_componentWillMount() {
    const element = document.getElementById("loading-spinner");
    element.classList.remove("running");

    // check if current active class view exists. in some cases of delete classes, the view with
    // active classes is wrong
    if (
      this.props.active_view &&
      this.props.active_view.classroom_id !== null
    ) {
      let founded_active = false;
      this.props.classes.forEach((elem) => {
        if (this.props.active_view.classroom_id === elem.classId) {
          founded_active = true;
        }
      });
      if (!founded_active && this.props.classes.length > 0) {
        this.props.set_active_view(this.props.classes[0].classId);
      }
    }
  }

  /**
   * abfrage der Registrierungsinfos zum anzeigen der entsprechenden Modal Layer
   */
  componentDidMount() {
    this.props.getPaymentSettings(AUTH.getUserId());

    if (
      this.props.authentication &&
      isPaymentActive(this.props.authentication.payment_test_mode) &&
      this.props.authentication.end_date_free
    ) {
      if (
        this.props.authentication &&
        (!this.props.authentication.payment_is_active ||
          this.props.authentication.payment_is_active === "false")
      ) {
        if (this.props.payment && !this.props.payment.payment_is_active) {
          const freeEndDate = DateTime.fromISO(
            this.props.authentication.end_date_free,
          )
            .startOf("day")
            .plus({ days: 1 });

          const currentDate = DateTime.now().startOf("day");
          const diffDays = freeEndDate.diff(currentDate).as("days");

          if (diffDays <= 0) {
            this.setState({
              payment_modal_open: true,
            });
          }
        }
      }
    }

    // set new class based on url param
    switchClassView(this.props);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.location.search !== prevProps.location.search) {
      switchClassView(props);
    }
  }

  getClasslist() {
    return this.props.classes.filter((elem) =>
      elem.subjects.some((subs) => subs.schooldays.length >= 0),
    );
  }

  logout(e) {
    e.preventDefault();
    this.props.logout();
    this.props.navigate("/login", { replace: true });
  }

  closeModal() {
    document.getElementById("content-container").classList.remove("isblured");
  }

  dontShowFirstReminderModal() {
    const update_payment_data = {};
    update_payment_data.dont_show_first_payment_modal = true;
    this.props.updatePaymentSettings(update_payment_data);
  }

  closeFirstTimeNotification() {
    document.getElementById("content-container").classList.remove("isblured");
    this.props.stampFirstTimeLoginNote();
    this.setState({
      first_time_layer_open: false,
    });
  }

  closeNotification() {
    const { props } = this;
    if (props.currentNote) {
      this.props.markNoteAsRead(props.currentNote.id);
    }
  }

  switchArchivView() {
    // sequence oder sequenz
    const path = this.props.location.pathname;
    const redirect = path.startsWith("/sequenzen/");
    this.props.switchSchoolyear(this.props.current_schoolyear.latest, redirect);
  }

  /**
   * rendering of first time Login Layer
   * @returns {*}
   */
  renderFirstTimeLoginInfo() {
    const { newLoginStrategy, loginCount } = this.props.authentication;
    const { first_time_layer_open } = this.state;
    if (newLoginStrategy && loginCount === 1) {
      const notification_modal_styles = {
        content: {
          top: "85px",
          left: "50%",
          right: "50%",
          bottom: "auto",
          transform: "translateX(-50%)",
          width: "610px",
        },
      };
      document.getElementById("content-container").classList.add("isblured");
      return (
        <div className="modal-wrapper" id="user-notification">
          <Modal
            isOpen={first_time_layer_open}
            onRequestClose={this.closeFirstTimeNotification}
            style={notification_modal_styles}
            ariaHideApp
            className="paymentModal firstTimeModal"
            overlayClassName="paymentOverlay"
            contentLabel="Hinweis"
          >
            <div
              id="notification"
              className="paymenModalContent first_time_note"
            >
              <div className="text-wrapper">
                Hallo {AUTH.getInitFirstname()},<br />
                die Einrichtung deines Kontos ist abgeschlossen und du kannst
                nun mit Freigeist deinen Unterricht planen.
                <br />
                Damit dir der Einstieg leichter fällt, haben wir ein kurzes
                Einführungsvideo vorbereitet:
                <br />
                <div className="video-wrapper">
                  <WistiaEmbed hashedId="km8uyqj95u" playerColor="#40B87E" />
                </div>
                Solltest du Fragen zu Freigeist haben, dann kannst du uns
                jederzeit gerne an{" "}
                <a className="mailto" href="mailto:hallo@freigeist-app.de">
                  hallo@freigeist-app.de
                </a>{" "}
                schreiben.
                <a className="btn" onClick={this.closeFirstTimeNotification}>
                  Loslegen
                </a>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
    return null;
  }

  /**
   * rendering of last payment note modal
   * @returns {*}
   */
  renderLastPaymentNotificationModal() {
    document.getElementById("content-container").classList.add("isblured");
    return (
      <div className="modal-wrapper" id="payment-modal-wrapper">
        <Modal
          isOpen={this.state.payment_modal_open}
          style={ModalStyles}
          ariaHideApp={false}
          className="paymentModal"
          overlayClassName="paymentOverlay"
          contentLabel="Probezeit"
        >
          <div className="paymenModalContent">
            <div className="text-wrapper">
              <span className="intro-text">
                <strong>
                  Wir hoffen dir hat das Arbeiten mit Freigeist gefallen.
                </strong>{" "}
                Um Freigeist weiter nutzen zu können, benötigst du ein Abo.
              </span>
              <div className="btn-wrapper">
                <Link to="/einstellungen?showProducts=show">
                  <button className="btn btn-step">Abo abschließen</button>
                </Link>
              </div>
              <div>
                <p>
                  <a
                    className="textlink"
                    href="#"
                    onClick={(e) => {
                      this.logout(e);
                    }}
                  >
                    Abmelden
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  renderArchivContainer() {
    return (
      <div className="archiv_container">
        <div className="row">
          <div className="label-left">
            Du siehst: Schuljahr {this.props.current_schoolyear.label}
          </div>
          <div className="action-right">
            <a onClick={this.switchArchivView}>
              zurück zum aktuellen Schuljahr
            </a>
          </div>
        </div>
      </div>
    );
  }

  /**
   * show toplevel payment info layer
   */
  renderTopNavigationBar() {
    if (this.props.authentication && !this.props.payment.payment_is_active) {
      const diff_days = calculateEndDateFree(this.props.payment.end_date_free);
      const backColor = diff_days < 8 ? "bg-red-dark" : "bg-gray-20";
      if (!isPayStateFree(this.props.payment.paystate_free) && diff_days >= 0) {
        return (
          <div
            className={`flex items-center justify-between h-10 text-xs text-gray-100 ${backColor}`}
          >
            <span className="ml-4">
              Du kannst Freigeist noch <strong>{diff_days} Tage</strong>{" "}
              kostenlos nutzen. Um Freigeist danach weiter zu nutzen, brauchst
              du ein Abo.
            </span>
            <span className="mx-4">
              <Link to="/einstellungen?showProducts=show">
                <span className="text-gray-100 underline underline-offset-1">
                  Schließe jetzt ein Abo ab
                </span>
              </Link>
            </span>
          </div>
        );
      }
    }
    return null;
  }

  render() {
    const modal =
      this.props.authentication && this.state.payment_modal_open
        ? this.renderLastPaymentNotificationModal()
        : "";
    const classList = this.getClasslist();

    const { current_schoolyear } = this.props;
    let archiv_class;
    let archiv_container;
    if (!current_schoolyear.is_active) {
      archiv_class = "archiv_view";
      document.getElementById("body").classList.add("archiv_view");
      archiv_container = this.renderArchivContainer();
    } else {
      document.getElementById("body").classList.remove("archiv_view");
    }

    let loader;
    let spinner_state;
    const { show_loader } = this.state;
    if (show_loader) {
      loader = "blured-loader";
      spinner_state = "running";
    }

    const notification = (
      <NotificationLayer
        currentNote={this.props.currentNote}
        closeNotification={this.closeNotification}
      />
    );

    const firsttime_notification = this.props.authentication
      ? this.renderFirstTimeLoginInfo()
      : null;

    const topnav_info_bar = this.renderTopNavigationBar();

    const classRoomId = this.props.active_view.classroom_id || 0;
    return (
      <div className={archiv_class}>
        {archiv_container}
        {topnav_info_bar}

        <FreigeistHeader
          name={this.props.authentication?.name ?? "?"}
          classes={classList}
          activeTab={`class-${classRoomId}`}
        />

        <div className="Main">
          <div id="loading-spinner" className={spinner_state}>
            <div className="logo-container">
              <img src="/assets/images/loading-animation-quadrat.gif" />
              <div className="text">Daten werden geladen</div>
            </div>
          </div>
          <div className={`children-wrapper ${loader}`}>
            <Outlet />
          </div>
        </div>

        {modal}
        {notification}
        {firsttime_notification}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
