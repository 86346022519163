const normalUrl = new URL(
  "../../assets/fonts/Roboto/Roboto-Regular.ttf",
  import.meta.url,
);

const boldUrl = new URL(
  "../../assets/fonts/Roboto/Roboto-Medium.ttf",
  import.meta.url,
);

const italicsUrl = new URL(
  "../../assets/fonts/Roboto/Roboto-Italic.ttf",
  import.meta.url,
);

const boldItalicsUrl = new URL(
  "../../assets/fonts/Roboto/Roboto-MediumItalic.ttf",
  import.meta.url,
);

const fonts = {
  Roboto: {
    normal: normalUrl.toString(),
    bold: boldUrl.toString(),
    italics: italicsUrl.toString(),
    bolditalics: boldItalicsUrl.toString(),
  },
};

export { fonts };
