/**
 * list of possible colors
 */
export const colors = [
  "#18598A",
  "#00AEDF",
  "#328870",
  "#66AC5F",
  "#E4DA4A",
  "#F9B903",
  "#F56903",
  "#B4423A",
  "#C64A81",
  "#66408B",
];
