export function region_countries(): Array<{ value: string; label: string }> {
  return [
    // { label: 'Bundesland', value: '', disabled: true },
    { label: "andere", value: "andere" },
    { label: "Baden-Württemberg", value: "baden-wuerttemberg" },
    { label: "Bayern", value: "bayern" },
    { label: "Berlin", value: "berlin" },
    { label: "Brandenburg", value: "brandenburg" },
    { label: "Bremen", value: "bremen" },
    { label: "Hamburg", value: "hamburg" },
    { label: "Hessen", value: "hessen" },
    { label: "Mecklenburg-Vorpommern", value: "mecklenburg-Vorpommern" },
    { label: "Niedersachsen", value: "niedersachsen" },
    { label: "Nordrhein-Westfalen", value: "nordrhein-westfalen" },
    { label: "Rheinland-Pfalz", value: "rheinland-pfalz" },
    { label: "Saarland", value: "saarland" },
    { label: "Sachsen", value: "sachsen" },
    { label: "Sachsen-Anhalt", value: "sachsen-anhalt" },
    { label: "Schleswig-Holstein", value: "schleswig-holstein" },
    { label: "Thüringen", value: "thueringen" },
  ];
}
