import { API_URL as apiUrl, AUTH_URL as authUrl } from "../../app/config";

// ---> OLD DATA

// settings routes
export const API_CLASSROOMS_URL = `${apiUrl}classrooms`;

export const API_FETCH_COMMON_SETTINGS = `${apiUrl}settings`;

// subjects
export const API_SUBJECTS_LIST_URL = `${apiUrl}subjectslist`;
export const API_SUBJECTS_LIST_ALL_URL = `${apiUrl}subjectslistall`;
export const API_SUBJECTS_IMPORT_SCHOOLYEAR = `${apiUrl}subjectlistmultiple`;

// classes
export const API_CLASSES_URL = `${apiUrl}classes`;
export const API_CLASSES_URL_UPDATE = `${apiUrl}classesupdate`;
export const API_CLASSES_URL_DELETE = `${apiUrl}classesdelete`;
export const API_CLASSES_IMPORT_SCHOOLYEAR = `${apiUrl}classesmultiple`;

// timetable
export const API_TIMETABLE_URL = `${apiUrl}timetable`;

// school_personal
export const API_SCHOOL_PERSONAL_URL = `${apiUrl}schoolpersonal`;
export const API_SWITCH_SCOOLYEAR = `${apiUrl}switchschoolyear`;

// user change password
export const API_CHANGE_USERNAME_URL = `${apiUrl}changeusername`;
export const API_CHANGE_PASSWORD_URL = `${apiUrl}changepassword`;
export const API_CHANGE_RESETPASSWORD_URL = `${authUrl}resetpassword`;
export const API_CHECK_RESET_TOKEN = `${authUrl}checkpasstoken`;
export const API_DO_PASSWORD_RESET = `${authUrl}dopasswordreset`;

// sequences
export const API_SEQUENCES_URL = `${apiUrl}sequences`;
export const API_SEQUNECESLIST_URL = `${apiUrl}sequenceslist`;

// sequence items
export const API_SEQUENCE_ITEMS_URL = `${apiUrl}sequence_items`;
export const API_SEQUENCE_ITEMS_URL_INIT = `${apiUrl}initsettings`;
export const API_SEQUENCE_ITEMS_FROM_TEMPLATE = `${apiUrl}sequence_items_template`;
export const API_SEQUENCE_ITEMS_UPDATE = `${apiUrl}sequence_items/data`;

// payment settings
export const API_STRIPE_PAYMENT_SETTINGS_URL = `${apiUrl}payment_settings`;

// material endpoints
// get materials
export const API_SEQUENCE_MATERIALS_URL = `${apiUrl}materials`;
// create material
export const API_SEQUENCE_MATERIALS_CREATE_FILE_URL = `${apiUrl}materials/files`;
// link preview /materials/link-preview
export const API_SEQUENCE_MATERIALS_LINK_PREVIEW = `${apiUrl}materials/link-preview`;
// create Link
export const API_SEQUENCE_MATERIALS_CREATE_LINK_URL = `${apiUrl}materials/links`;
// get material file download link
export const API_SEQUENCE_MATERIALS_GET_FILE_URL = `${apiUrl}materials/`;
// delete
export const API_DELETE_SEQUENCE_MATERIAL = `${apiUrl}materials/`;
export const API_DELETE_MATERIAL = `${apiUrl}materials/`;
export const API_UNDO_DELETE_MATERIAL = `${apiUrl}materials/`;

// auth
export const API_ROUTE_AUTH_REGISTER = `${authUrl}signup`;
export const API_ROUTE_AUTH_LOGIN = `${authUrl}login`;
export const API_ROUTE_AUTH_USER_EXISTS = `${authUrl}userexists`;
