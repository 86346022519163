import React from "react";
import AddTimetableForm from "../../../common/components/settings/timetables/AddTimetableForm";

class addTimetable extends React.Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    this.props.jumpToStep(4);
  }

  prevSection() {
    // this.props.updateClasses( classes )
    this.props.jumpToStep(2);
  }

  render() {
    return (
      <div id="timetable" className="step step3 register">
        <div className="step-text">
          <div className="title">Stundenplan</div>
          Hier kannst du deinen Stundenplan eintragen. Nur Fächer und Klassen
          die im Stundenplan vorkommen werden in Freigeist angezeigt. Du kannst
          deinen Stundenplan jederzeit unter Einstellungen ändern.
        </div>

        <AddTimetableForm
          prevSection={this.prevSection}
          nextSection={this.nextSection}
        />
      </div>
    );
  }
}
export default addTimetable;
