/**
 * Created by makkuz on 11.01.18.
 */

import React from "react";
import TagItem from "./TagItem";
import { uniqueId } from "../../../common/components/sequence_calendar/Utils";

export default class FilterTagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_tags: [],
      clear_all: false,
      ref_key: uniqueId(),
    };
    this.setToFilter = this.setToFilter.bind(this);
    this.removeFromFilter = this.removeFromFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  /**
   * on did mount fill internal state with props.
   */
  componentDidMount() {}

  setToFilter(value) {
    const { filter_tags } = this.state;
    filter_tags.push(value);
    this.setState({
      filter_tags,
      clear_all: false,
    });
    // console.log('add to filter');
    this.props.filterTags(filter_tags);
  }

  removeFromFilter(value) {
    const { filter_tags } = this.state;
    const index = filter_tags.indexOf(value);
    if (index > -1) {
      filter_tags.splice(index, 1);
    }
    this.setState({
      filter_tags,
      clear_all: false,
    });
    // console.log('remove from filter');
    this.props.filterTags(filter_tags);
  }

  clearFilter() {
    this.setState({
      filter_tags: [],
      clear_all: true,
      ref_key: uniqueId(),
    });
    this.props.filterTags([]);
  }

  render() {
    // console.log('insdie render FilterTagList');
    // console.log(this.state);

    const { filter_tags, clear_all, ref_key } = this.state;
    let show_all_css = "active";
    if (filter_tags.length > 0) {
      show_all_css = "";
    }

    return (
      <div className="tags-wrapper" key={ref_key}>
        <div className="title">Stichworte</div>

        <span onClick={this.clearFilter} className={show_all_css}>
          Alle
        </span>
        {this.props.items.map((item) => (
          <TagItem
            key={`tagitem-${item}`}
            title={item}
            setToFilter={this.setToFilter}
            removeFromFilter={this.removeFromFilter}
            removeActive={clear_all}
          />
        ))}
      </div>
    );
  }
}
