import React from "react";
import { TwitterPicker } from "react-color";
import { uniqueId } from "../../../../common/components/sequence_calendar/Utils";
import Subject from "./SubjectComponent";
import Auth from "../../../../common/services/Auth";
import { colors } from "../../../../common/constants/colorScheme";

export default class AddSubjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: props.subjects,
      displayColorPicker: false,
      schoolyear: props.schoolyear,
      title: "",
      color: "",
    };
    this.addSubject = this.addSubject.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);

    this.colorChange = this.colorChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);

    this.pickerShowHide = this.pickerShowHide.bind(this);
    this.handlePickerClose = this.handlePickerClose.bind(this);
    this.updateSubject = this.updateSubject.bind(this);
    this.removeSubjectList = this.removeSubjectList.bind(this);
  }

  handlePickerClose(e) {
    // console.log('close picker');
    e.preventDefault();
    this.setState({ displayColorPicker: false });
  }

  onTitleChange(value) {
    this.setState({
      title: value,
    });
  }

  /**
   * löscht ein Item aus der liste
   * @returns {*}
   */
  removeSubjectList(id) {
    const subjects = this.state.subjects.filter((p) => p._id !== id);
    this.setState({
      subjects,
    });
    this.props.updateSubjects(subjects);

    const class_list = this.props.classes.slice();
    if (class_list) {
      class_list.forEach((elem) => {
        if (elem.subjects.length > 0) {
          const new_sub_list = elem.subjects.filter((p) => p._id !== id);
          elem.subjects = new_sub_list;
        }
      });
    }
    this.props.updateClasses(class_list);

    const timelist = { ...this.props.timetable };
    let tt_changed = false;
    if (timelist.entries && timelist.entries.length > 0) {
      const new_entries = [];
      timelist.entries.forEach((elem) => {
        if (elem.class.subject._id !== id) {
          new_entries.push(elem);
        } else {
          tt_changed = true;
        }
      });
      timelist.entries = new_entries;
    }
    if (tt_changed) {
      this.props.updateTimetable(timelist);
    }
  }

  /**
   * update an subject inside list
   * @param subject
   */
  updateSubjectList(subject) {
    const { subjects } = this.state;
    const newSubjects = subjects.map((s) => {
      if (s._id === subject._id) {
        return { ...s, title: subject.title, color: subject.color };
      }
      return s;
    });

    this.setState({
      subjects: newSubjects,
    });
    this.props.updateSubjects(newSubjects);
  }

  /**
   * update an item inside list from child subject
   * on update check if timetable elements with subject exists -> update this too
   */
  updateSubject(item) {
    if (this.props.timetable.entries) {
      this.props.timetable.entries.forEach((elem) => {
        if (elem.class.subject && elem.class.subject._id === item._id) {
          elem.class.subject.title = item.title;
          elem.class.subject.color = item.color;
        }
      });
    }

    // check for classlists updates
    const found_classes = [];
    if (this.props.classes) {
      this.props.classes.forEach((elem) => {
        elem.subjects.forEach((sub) => {
          if (sub._id === item._id) {
            sub.title = item.title;
            sub.color = item.color;
            found_classes.push(elem);
          }
        });
      });
    }

    item.user_id = Auth.getUserId();
    item.schoolyear = this.state.schoolyear;
    this.updateSubjectList(item);
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    const { subjects } = this.state;
    this.props.nextSection(subjects);
  }

  prevSection() {
    this.props.prevSection();
  }

  colorChange(color) {
    this.setState({
      color: color.hex,
    });
    // this.props.onColorChange(color.hex);
  }

  pickerShowHide(e) {
    // console.log('close/show picker');
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  /**
   * add subject to db or to local storage for registration
   */
  addSubject(e) {
    e.preventDefault();
    const { title } = this.state;

    const color =
      this.state.color || colors[this.props.subjects?.length] || colors[0];

    const id = uniqueId();
    const subject = {
      title,
      color,
      schooldays: [],
      user_id: Auth.getUserId(),
      schoolyear: this.state.schoolyear,
      _id: id,
      old_ref_id: id,
    };

    const { subjects } = this.state;
    subjects.push(subject);
    this.setState({
      subjects,
      title: "",
      color: "",
    });
    // @TODO: add to schoolyear storage
    // let concat = this.props.subjects.concat(subject);
    // let new_list = Object.assign({}, this.props.subjects, concat);
    this.props.updateSubjects(subjects);
  }

  render() {
    const subject_list = this.props.subjects;

    const enabled = this.state.title.length > 0;
    const nextenabled = subject_list.length > 0;

    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "20px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    let user_color = this.state.color;

    if (!user_color && this.props.subjects && this.props.subjects.length > 0) {
      const { length } = this.props.subjects;
      if (colors[length]) {
        user_color = colors[length];
      }
    } else if (!user_color) {
      [user_color] = colors;
    }

    return (
      <div id="subjects" className="">
        <div className="subject_list">
          {subject_list.map((p, i) => (
            <Subject
              {...p}
              key={uniqueId()}
              nr={i}
              schoolyear={this.props.schoolyear}
              storage={this.props.storage}
              updateSubject={this.updateSubject}
              removeSubjectList={this.removeSubjectList}
              classes={this.props.classes}
              timetable={this.props.timetable}
              pre_colors={colors}
            />
          ))}
        </div>

        <div className="row">
          <div className="subject_item_container">
            <div className="fields">
              <div className="field_input">
                <label>Fach (Name)</label>
                <input
                  className="inputfield"
                  placeholder="z.B. Mathematik"
                  value={this.state.title}
                  onChange={(event) => this.onTitleChange(event.target.value)}
                />
              </div>
              <div className="file_color">
                <label>Farbe</label>
                <div
                  className="color_wrapper"
                  style={{ backgroundColor: user_color }}
                  onClick={(e) => {
                    this.pickerShowHide(e);
                  }}
                >
                  <div className="triangle_color_wrapper">
                    <span className="triangle_white_down" />
                  </div>
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>

            {this.state.displayColorPicker ? (
              <div style={popover}>
                <div
                  style={cover}
                  onClick={(e) => {
                    this.handlePickerClose(e);
                  }}
                />
                <div className="picker">
                  <TwitterPicker
                    key="main-pciker"
                    colors={colors}
                    color={user_color}
                    onChange={this.colorChange}
                  />
                </div>
              </div>
            ) : null}

            <div className="btn_wrapper">
              <button
                disabled={!enabled}
                className="btn btn-dark"
                onClick={(e) => {
                  this.addSubject(e);
                }}
              >
                anlegen
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Schule
              </a>
            </div>
            <div className="next">
              <button
                disabled={!nextenabled}
                className="btn btn-step"
                onClick={() => this.nextSection()}
              >
                Weiter zu Klassen
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}
