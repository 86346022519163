import { type Action, type PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  sequenceMaterialSlice,
  ueMaterialSlice,
} from "../material/material-slice";
import { moveDayslots } from "./move-dayslots";
import type {
  ArticulationBlock,
  Sequence,
  Slot,
  Slotday,
  LabelValue,
} from "./types";
import { uniqueId } from "../../common/components/sequence_calendar/Utils.js";
import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import { type MaterialModel } from "../material/types";
import { selectSchoolWithHolidays } from "../../app/combined-selectors/index";
import { type AppThunk } from "../../app/store";
import { type Subject } from "../subjects/types";
import {
  type SequenceItemApiResponse,
  type SequenceItemData,
} from "../sequence_items/types";

type SequenceState = {
  sequence: Sequence;
  sequenceItemData: SequenceItemApiResponse;
  subject: Subject;
  materialModel: MaterialModel;
  ueMaterialModel: MaterialModel;
};

const initState: SequenceState = {
  sequence: {
    _id: "",
    sequence_item_id: 0,
    user_id: 0,
    timeslots: [],
    schoolyear: 0,
    userId: "",
  },
  sequenceItemData: {
    id: "",
    schoolyear: 0,
    schooldays: [],
    title: "",
    color: "",
    classroomId: 0,
    classroomTitle: "",
    subjectlistId: "",
    scooldays: [],
    height: 0,
    data: {
      pos: 0,
      pos_end: 0,
      id: 0,
      note: "",
      didakt: "",
      weeks: 0,
      calc_date: "",
      calc_end_date: "",
      date: "",
      daterange: "",
      label_weeks: 0,
      label_hours: 0,
    },
  },

  subject: {
    color: "",
    createdAt: "",
    import_id: "",
    schooldays: [],
    schoolyear: 0,
    title: "",
    user_id: 0,
    _id: "",
  },
  materialModel: sequenceMaterialSlice.getInitialState(),
  ueMaterialModel: sequenceMaterialSlice.getInitialState(),
};

function createEmptyTimeslot(
  id: string,
  fields: Partial<Omit<Slot, "id">>,
): Slot {
  return {
    id,
    data: [],
    tab: "notes-unit",
    title: "",
    homework: "",
    didakt: "",
    lehrplanbezug: [],
    ueId: "",
    date: "",
    note: "",
    ...fields,
  };
}

const sequenceSlice = createSlice({
  name: "sequences",
  initialState: initState,
  reducers: {
    updatedTimeslots(state, action: PayloadAction<{ timeslots: Slot[] }>) {
      return {
        ...state,
        timeslots: action.payload.timeslots,
      };
    },

    /**
     * set the active sequence_item with metadata from calendar
     */
    activeSequence(
      state,
      action: PayloadAction<{
        sequenceItem: SequenceItemApiResponse;
        sequences: Sequence;
        subject: Subject;
      }>,
    ) {
      return {
        ...state,
        sequenceItemData: action.payload.sequenceItem,
        sequence: action.payload.sequences,
        subject: action.payload.subject,
      };
    },

    updatedActiveSequenceItem(state, action: PayloadAction<SequenceItemData>) {
      state.sequenceItemData.data = action.payload;
    },

    changeSequenceNote(state, action: PayloadAction<string>) {
      state.sequenceItemData.data.note = action.payload;
    },

    /**
     * set/get the slotdays
     */
    changeDaySlotTab(state, action) {
      const { id, tab } = action.payload;

      const foundTimeslot = state.sequence.timeslots.find(
        (el: Slot) => String(el.id) === String(id),
      );

      if (foundTimeslot) {
        foundTimeslot.tab = tab;
      } else {
        // add an empty new one
        state.sequence.timeslots.push(createEmptyTimeslot(id, { tab }));
      }
    },

    changeDaySlotTitle(state, action) {
      const { title, id } = action.payload;

      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === String(id),
      );

      if (foundTimeslot) {
        foundTimeslot.title = title;
      } else {
        state.sequence.timeslots.push(createEmptyTimeslot(id, { title }));
      }
    },
    /**
     * handle dayslot notes
     */
    changeDaySlotNote(
      state,
      action: PayloadAction<{ id: string; note: string }>,
    ) {
      const { id, note } = action.payload;

      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === String(id),
      );

      if (foundTimeslot) {
        foundTimeslot.note = note;
      } else {
        state.sequence.timeslots.push(createEmptyTimeslot(id, { note }));
      }
    },
    changeHomework(state, action: PayloadAction<{ id: string; text: string }>) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === String(action.payload.id),
      );
      if (foundTimeslot) {
        foundTimeslot.homework = action.payload.text;
      }
    },
    changeSequenceItemDiktat(state, action: PayloadAction<string>) {
      state.sequenceItemData.data.didakt = action.payload;
    },
    changeDidaktHinter(
      state,
      action: PayloadAction<{ id: string; text: string }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => Number(el.id) === Number(action.payload.id),
      );
      if (foundTimeslot) {
        foundTimeslot.didakt = action.payload.text;
      }
    },
    changeLehrplanbezug(
      state,
      action: PayloadAction<{
        id: string;
        value: Array<{ label: string; value: string }>;
      }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => Number(el.id) === Number(action.payload.id),
      );
      if (foundTimeslot) {
        foundTimeslot.lehrplanbezug = action.payload.value;
      }
    },
    /**
     * set dayslot id of active timelost
     */
    setDaySlotId(
      state,
      action: PayloadAction<{
        id: string;
        created_tab_new: boolean;
      }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => Number(el.id) === Number(action.payload.id),
      );

      if (!foundTimeslot) {
        const tab = action.payload.created_tab_new
          ? "notes-unit"
          : "articulation";
        state.sequence.timeslots.push(
          createEmptyTimeslot(action.payload.id, { tab }),
        );
      }
    },
    /**
     * set the moved dayslot elem in the new state
     */
    moveDayslotData(
      state,
      action: PayloadAction<{
        sourceIndex: number;
        targetIndex: number;
        slotDays: Slotday[];
        activeSlotIdx: number;
      }>,
    ) {
      const activeIndexBefore: number = action.payload.activeSlotIdx;
      const activeItem = state.sequence.timeslots[activeIndexBefore];

      // move slots
      const update = moveDayslots(action.payload, state.sequence.timeslots);

      const activeIndexAfter: number = update.timeslots.findIndex(
        (item: Slot) => (activeItem ? activeItem.ueId === item.ueId : false),
      );

      const newActiveItem = update.timeslots[activeIndexAfter];
      const newSlotDay = update.slotdays[activeIndexAfter];

      if (newActiveItem && newSlotDay) {
        state.sequence.timeslots = update.timeslots;
      }
    },
    resort_panes(
      state,
      action: PayloadAction<{ newBlocks: Slot["data"]; dayslotid: string }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === action.payload.dayslotid,
      );
      if (foundTimeslot) {
        foundTimeslot.data = action.payload.newBlocks;
      }
    },
    /**
     * add a pane with custom duration to timeslot
     */
    addPane(state, action: PayloadAction<{ duration: number; id: string }>) {
      // index slot
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => el.id === action.payload.id,
      );
      // neues objekt
      const newBlock: ArticulationBlock = {
        id: uniqueId(),
        duration: action.payload.duration,
        title: "",
        text: "",
      };

      if (foundTimeslot) {
        foundTimeslot.data = [...foundTimeslot.data, newBlock];
      }
    },
    /**
     * action for changing text inside pane
     */
    changePaneText(
      state,
      action: PayloadAction<{
        paneIndex: number;
        changeType: "text" | "title";
        text: string;
        dayslotid: string;
      }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === action.payload.dayslotid,
      );
      if (foundTimeslot) {
        const selectedPane = foundTimeslot.data[action.payload.paneIndex];
        if (selectedPane) {
          selectedPane[action.payload.changeType] = action.payload.text;
        }
      }
    },
    /**
     * change materal list
     */
    update_material(
      state,
      action: PayloadAction<{
        paneIndex: number;
        tags: LabelValue[];
        dayslotid: string;
      }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === action.payload.dayslotid,
      );
      if (foundTimeslot) {
        const selectedPane = foundTimeslot.data[action.payload.paneIndex];
        if (selectedPane) {
          selectedPane.material = action.payload.tags;
        }
      }
    },
    /**
     * action for pane resize
     */
    paneResize(
      state,
      action: PayloadAction<{
        id: number;
        duration: number;
        dayslotid: string;
      }>,
    ) {
      const foundTimeslot = state.sequence.timeslots.find(
        (el) => String(el.id) === action.payload.dayslotid,
      );
      if (foundTimeslot) {
        const selectedPane = foundTimeslot.data[action.payload.id];
        if (selectedPane) {
          selectedPane.duration = action.payload.duration;
        }
      }
    },
    deletePane(
      state,
      action: PayloadAction<{ paneIndex: number; dayslotid: string }>,
    ) {
      const foundTimeslot: Slot | undefined = state.sequence.timeslots.find(
        (el) => String(el.id) === action.payload.dayslotid,
      );
      if (foundTimeslot) {
        foundTimeslot.data = foundTimeslot.data.filter(
          (_, index) => action.payload.paneIndex !== index,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // delegate material actions to specific reducers
    builder
      // You can match a range of action types
      .addMatcher(
        (action: Action) =>
          action.type.startsWith(`${sequenceMaterialSlice.name}/`),
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          const newMaterialModel = sequenceMaterialSlice.reducer(
            state.materialModel,
            action,
          );
          return { ...state, materialModel: newMaterialModel };
        },
      )
      .addMatcher(
        (action: Action) => action.type.startsWith(`${ueMaterialSlice.name}/`),
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, action) => {
          const newUeMaterialModel = ueMaterialSlice.reducer(
            state.ueMaterialModel,
            action,
          );
          return { ...state, ueMaterialModel: newUeMaterialModel };
        },
      );
  },
  selectors: {
    sequenceMaterialSelector: (state: SequenceState) => state.materialModel,
    ueMaterialSelector: (state: SequenceState) => state.ueMaterialModel,
  },
});

// REDUCER

export default sequenceSlice.reducer;

// ACTION CREATORS

const { actions } = sequenceSlice;

export const {
  changeSequenceNote,
  changeDaySlotTab,
  changeDaySlotTitle,
  changeDaySlotNote,
  changeHomework,
  changeSequenceItemDiktat,
  changeDidaktHinter,
  changeLehrplanbezug,
  setDaySlotId,
  moveDayslotData,
  resort_panes,
  addPane,
  changePaneText,
  update_material,
  paneResize,
  deletePane,
} = actions;

// SELECTORS
export const { sequenceMaterialSelector, ueMaterialSelector } =
  sequenceSlice.selectors;

// THUNKS

// these actions are only used in thunks within this file so we don't export them

export function updateTimeSlots(): AppThunk {
  return (dispatch, getState) => {
    const state = getState();

    const payload = {
      sequence_item_id: state.sequences.sequenceItemData.data.id,
      timeslots: state.sequences.sequence.timeslots,
      schoolyear: state.current_schoolyear.year,
    };

    const { token } = getState().authentication;
    return fetch(URLS.API_SEQUENCES_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(actions.updatedTimeslots(data.sequence));
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          "Technischer Fehler!\nDeine Daten konnten nicht gespeichert werden, da der Server nicht erreichbar ist.\nBitte wende dich an unseren Support",
        );
      });
  };
}

/**
 *
 * @param user_id
 * @param sequence_id
 * @return {function(*=)}
 */
export function deleteSequences(
  user_id: number,
  sequence_id: number,
  schoolyear: number,
): AppThunk {
  return () =>
    fetch(
      `${URLS.API_SEQUENCES_URL}/move/${user_id}/${sequence_id}/${schoolyear}`,
      {
        method: "DELETE",
        headers: { Authorization: `bearer ${Auth.getToken()}` },
      },
    );
}
export function activeSequenceView(
  sequenceItemId: string,
): AppThunk<Promise<object | null>> {
  return async (dispatch, getState) => {
    const state = getState();
    const schoolWithHolidays = selectSchoolWithHolidays(state);
    const response = await fetch(
      `${URLS.API_SEQUENCES_URL}/${sequenceItemId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `bearer ${Auth.getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ region: schoolWithHolidays.region }),
      },
    );
    if (response.ok) {
      const data = await response.json();
      // 1: first check if sequence_item data object exists
      if (data.sequenceItem && data.sequenceItem.data) {
        dispatch(actions.activeSequence(data));
      }
      return {};
    }
    return null;
  };
}

export function updateSequenceItemData(
  sequenceItemId: string,
  sequenceItem: SequenceItemData,
): AppThunk {
  return (dispatch) =>
    fetch(`${URLS.API_SEQUENCE_ITEMS_UPDATE}/${sequenceItemId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(sequenceItem),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch(actions.updatedActiveSequenceItem(data.itemData));
        });
      } else {
        response.json().then(() => {
          dispatch(actions.updatedActiveSequenceItem(sequenceItem));
        });
      }
    });
}
