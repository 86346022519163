import { html2pdfmake } from "./html2pdfmake";
import { fonts } from "./fonts";

const _handleMaterial = (materialList) => {
  const list = Array.isArray(materialList) ? materialList : [];
  return list.map((elem) => elem.label);
};

const _prepareData = (timeslots) => {
  let start_minute = 0;
  const rows = [];
  timeslots.forEach((elem) => {
    const material = _handleMaterial(elem.material);
    const duration = start_minute + parseInt(elem.duration);
    const text = html2pdfmake(elem.text);
    const tableRow = [
      {
        text: [
          { text: "Minute\n", fontSize: 8, bold: false },
          { text: `${start_minute} - ${duration}`, fontSize: 8, bold: true },
        ],
        fontSize: 8,
        style: "",
        border: [false, true, false, true],
        margin: [0, 5, 0, 5],
      },
      {
        text: elem.title,
        bold: true,
        fontSize: 8,
        style: "",
        border: [false, true, false, true],
        margin: [0, 5, 0, 5],
      },
      {
        stack: text,
        fontSize: 8,
        style: "",
        border: [false, true, false, true],
        margin: [0, 5, 13, 5],
      },
      {
        text: material.join(", "),
        fontSize: 8,
        style: "",
        border: [false, true, false, true],
        margin: [0, 5, 0, 5],
      },
    ];
    rows.push(tableRow);
    start_minute = duration;
  });
  return rows;
};

export default (
  school,
  user,
  seq_data,
  dayslot,
  timeslots,
  dayslots,
  current_schoolyear,
) => {
  let schooldays = 0;
  let current_schoolday = 1;
  dayslots.forEach((elem) => {
    if (!elem.holiday) schooldays += 1;
    if (String(dayslot.id) === String(elem.day_id))
      current_schoolday = schooldays;
  });

  const timeslot_rows = _prepareData(timeslots);
  const material_list_str = _handleMaterial(dayslot.material_list);
  const lehrplanbezug = _handleMaterial(dayslot.lehrplanbezug);
  const homework = html2pdfmake(dayslot.homework);

  const tableHeaders = [
    { text: "Zeit", style: "tableHeader", border: [false, true, false, true] },
    {
      text: "Artikulation",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Lehrer-Schüler-Interaktion",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Medien und Material",
      style: "tableHeader",
      border: [false, true, false, true],
    },
  ];

  const tableWidths = [35, 75, "*", 100];

  const curr_hour = dayslot.current_hour + 1;

  const next_prev = { text: [] };

  const pdf_title = `${dayslot.sequence_title} ${dayslot.title} ${dayslot.id}`;

  if (dayslot.current_hour > 0) {
    const prev_idx = dayslot.current_hour - 1;
    let prev_title = "";
    if (dayslots[prev_idx]) {
      prev_title = dayslots[prev_idx].title;
    }
    next_prev.text.push({ text: "Davor: ", fontSize: 8, bold: true });
    next_prev.text.push({ text: `${prev_title}\n`, fontSize: 8, bold: false });
  }
  if (dayslot.current_hour < dayslots.length) {
    const next_idx = dayslot.current_hour + 1;
    let next_title = "";
    if (dayslots[next_idx]) {
      next_title = dayslots[next_idx].title;
    }
    next_prev.text.push({ text: "Danach: ", fontSize: 8, bold: true });
    next_prev.text.push({ text: next_title, fontSize: 8, bold: false });
  }

  const didakt = html2pdfmake(dayslot.didaktik);
  const documentDefinition = {
    footer(currentPage, pageCount) {
      return {
        margin: 5,
        columns: [
          {
            fontSize: 8,
            text: [
              { text: `Artikulationsschema "${dayslot.title}" | ` },
              {
                text: `Seite ${currentPage.toString()} von ${pageCount}`,
                bold: true,
              },
            ],
            alignment: "right",
            margin: [0, 0, 40, 0],
          },
        ],
      };
    },
    header: {
      margin: 40,
      columns: [
        {
          width: "*",
          text: [
            { text: `${school.school}\n`, fontSize: 8, bold: true },
            {
              text: `${school.street} ${school.nr}\n`,
              fontSize: 8,
              bold: false,
            },
            {
              text: `${school.zip} ${school.location}`,
              fontSize: 8,
              bold: false,
            },
          ],
          style: "header_left",
        },
        {
          width: "*",
          text: [
            { text: "Artikulationsschema\n", fontSize: 18, bold: true },
            { text: `${seq_data.classroomTitle} `, fontSize: 8, bold: false },
            { text: `${seq_data.title} `, fontSize: 8, bold: true },
            {
              text: `Schuljahr ${current_schoolyear.label}\n\n `,
              fontSize: 8,
              bold: true,
            },
          ],
          alignment: "center",
          style: "header_center",
        },
        {
          width: "*",
          text: [
            { text: "Lehrkraft: ", fontSize: 8, bold: false },
            { text: user.name, fontSize: 8, bold: true },
          ],
          alignment: "right",
          style: "header_right",
        },
      ],
    },
    pageSize: "A4",
    // pageOrientation: 'landscape',
    content: [
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 595 - 2 * 40,
            y2: 0,
            lineWidth: 0.5,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: [
          { text: `${seq_data.title} | `, fontSize: 12, bold: true },
          { text: `${dayslot.date} | `, fontSize: 12, bold: false },
          { text: `${curr_hour}. Schulstunde  `, fontSize: 12, bold: false },
        ],
      },
      {
        text: [{ text: `${dayslot.title}\n`, fontSize: 24, bold: true }],
      },
      {
        text: [
          { text: "Sequenz ", fontSize: 8, bold: true },
          { text: `${dayslot.sequence_title} | `, fontSize: 8, bold: false },
          { text: "Unterrichtseinheit ", fontSize: 8, bold: true },
          {
            text: `${current_schoolday} von ${schooldays}`,
            fontSize: 8,
            bold: false,
          },
        ],
        margin: [0, 10, 0, 0],
      },
      /*
			{
				text : [
					{ text: 'Davor:', fontSize: 8, bold: true },
					{ text: '', fontSize: 8, bold: false },
				],
			},
			{
				text : [
					{ text: 'Danach:', fontSize: 8, bold: true },
					{ text: '', fontSize: 8, bold: false },
				],
			},
			*/
      next_prev,
      {
        stack: didakt,
        margin: [0, 10, 0, 10],
      },
      {
        text: [{ text: "\n\nLehrplanbezug", fontSize: 12, bold: true }],
      },
      {
        text: [
          {
            text: `${lehrplanbezug.join(", ")}\n\n`,
            fontSize: 12,
            bold: false,
          },
        ],
      },
      {
        style: "tableFull",
        table: {
          widths: tableWidths,
          heights(row) {
            if (row === 0) {
              return 25;
            }
            return 60;
          },
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          body: [[...tableHeaders], ...timeslot_rows],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          paddingLeft() {
            return 0;
          },
          paddingRight() {
            return 0;
          },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        },
      },
      {
        text: "\n",
      },
      {
        style: "",
        table: {
          widths: ["*", "*"],
          headerRows: 1,
          keepWithHeaderRows: 1,
          dontBreakRows: true,
          body: [
            [
              { text: "Hausaufgaben", fontSize: 8, bold: true },
              { text: "Medien und Material", fontSize: 8, bold: true },
            ],
            [
              { stack: homework, fontSize: 8 },
              { text: material_list_str.join(", "), fontSize: 8 },
            ],
          ],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          paddingLeft() {
            return 5;
          },
          paddingRight() {
            return 5;
          },
          paddingTop() {
            return 5;
          },
          paddingBottom() {
            return 5;
          },
        },
      },
      {
        text: "\n",
      },
      {
        style: "",
        table: {
          widths: ["*"],
          headerRows: 1,
          heights: ["*", 200],
          keepWithHeaderRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: "Reflexion zur Unterrichtseinheit",
                fontSize: 8,
                bold: true,
              },
            ],
            [{ text: "" }],
          ],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          paddingLeft() {
            return 5;
          },
          paddingRight() {
            return 5;
          },
          paddingTop() {
            return 5;
          },
          paddingBottom() {
            return 5;
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableFull: {},
      tableHeader: {
        bold: false,
        fontSize: 10,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      introtext: {
        margin: [20, 20, 20, 20],
      },
    },
    pageMargins: [40, 90, 40, 60],
  };

  const str = `Artikulationsschema ${pdf_title}`
    .replace(/\s/g, "_")
    .replace(",", "_")
    .replace(".", "_");
  const fileName = `${str}.pdf`;

  window.pdfMake.createPdf(documentDefinition, null, fonts).download(fileName);
};
