import * as ACTIONS from "./actions";
import { deepSet } from "../../common/utils/assortments";

const initialScoolDay = { day: "", hour: "" };

const initState = {
  title: "",
  description: "",
  color: "",
  scooldays: [{ day: "", hour: "" }],
};
export function addSubjectForm(state = initState, action) {
  // console.log('Reducer addSubjectForm, ' + action.type);

  switch (action.type) {
    case ACTIONS.CHANGE_TITLE_FORM:
      return { ...state, title: action.text };

    case ACTIONS.CHANGE_DESCRIPTION_FORM:
      return { ...state, description: action.text };

    case ACTIONS.CHANGE_COLOR_FORM:
      return { ...state, color: action.text };

    case ACTIONS.CHANGE_HOURS_FORM:
      return { ...state, classtime: action.text };

    case ACTIONS.CHANGE_CLASSDAYS_FORM:
      return { ...state, classdays: action.text };

    case ACTIONS.CLEAR_FORM:
      return initState;

    case ACTIONS.ADD_SCOOLDAYS_ROW: {
      return {
        ...state,
        scooldays: Array.isArray(state.scooldays)
          ? [...state.scooldays, initialScoolDay]
          : [initialScoolDay],
      };
    }
    case ACTIONS.CHANGE_SCOOLDAY_TEXT: {
      const new_state = deepSet(
        ["scooldays", Number(action.key), action.index],
        action.value,
        state,
      );
      return new_state;
    }

    default:
      return state;
  }
}
