/**
 * Created by makkuz on 06.02.18.
 */
import React from "react";
import AddSubjectForm from "../../../common/components/settings/subjects/AddSubjectForm";

class addSubjects extends React.Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection(subjects) {
    this.props.updateSubjects(subjects);
    this.props.jumpToStep(2);
  }

  prevSection() {
    // this.props.updateClasses( classes )
    this.props.jumpToStep(0);
  }

  render() {
    return (
      <div id="subjects" className="step step2">
        <div className="step-text">
          <div className="title">Fächer</div>
          Trage hier die Fächer ein, die du unterrichtest. Du kannst später
          jederzeit Fächer ergänzen, löschen oder ändern.
        </div>

        <AddSubjectForm
          nextSection={this.nextSection}
          prevSection={this.prevSection}
          storage="local"
        />
      </div>
    );
  }
}
export default addSubjects;
