// utility for conditional classNames
type Part = Record<string, boolean> | string;

function classList(...args: Part[]): string {
  const strings = args.map((part) => {
    if (typeof part === "string") {
      return part.trim();
    }
    return Object.entries(part)
      .filter(([, show]) => show)
      .map(([className]) => className.trim())
      .join(" ");
  });
  return strings.join(" ");
}

export { classList };
