/**
 * Created by makkuz on 06.02.18.
 */
/**
 * Created by makkuz on 06.02.18.
 */
import React from "react";
import AddClassroomsForm from "./AddClassroomsFormComponent";

class addClasses extends React.Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.lastSection = this.lastSection.bind(this);
    this.updateClasses = this.updateClasses.bind(this);
    this.updateTimetable = this.updateTimetable.bind(this);
    this.finalizeSchoolyear = this.finalizeSchoolyear.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection(classes) {
    this.props.updateClasses(classes);
    this.props.jumpToStep(3);
  }

  prevSection() {
    // this.props.updateClasses( classes )
    this.props.jumpToStep(1);
  }

  lastSection() {
    this.props.jumpToStep(4);
  }

  updateClasses(list) {
    this.props.updateClasses(list);
  }

  updateTimetable(obj) {
    this.props.updateTimetable(obj);
  }

  finalizeSchoolyear() {
    this.props.finalizeSchoolyear();
  }

  render() {
    return (
      <div id="subjects" className="step step3">
        <div className="step-text">
          <div className="title">Klassen</div>
          Behältst du Klassen im nächsten Schuljahr? Hier kannst du deine
          Klassen einstellen. Du kannst das später unter Einstellungen ändern
          und ergänzen.
        </div>

        <AddClassroomsForm
          subjects={this.props.subjects}
          classes={this.props.classes}
          timetable={this.props.timetable}
          schoolyear={this.props.schoolyear}
          prevSection={this.prevSection}
          nextSection={this.nextSection}
          lastSection={this.lastSection}
          updateClasses={this.updateClasses}
          updateTimetable={this.updateTimetable}
          finalizeSchoolyear={this.finalizeSchoolyear}
          storage="local"
        />
      </div>
    );
  }
}
export default addClasses;
