import { region_countries } from "../../common/config/region_contries";
import { school_types } from "../../common/config/school_types";
import type { School } from "./types";

type Input = Pick<School, "region" | "schooltype">;
type Option = { value: string; label: string };

export function addRegionAndSchoolLabels(
  schoolData: Input,
): Input & Pick<School, "region_label" | "schooltype_label"> {
  const regionLabelOption: Option | undefined = region_countries().find(
    (item) => item.value === schoolData.region,
  );
  const schoolTypeLabelOption: Option | undefined = school_types().find(
    (item) => item.value === schoolData.schooltype,
  );
  return {
    ...schoolData,
    region_label: regionLabelOption?.label ?? "NotFound",
    schooltype_label: schoolTypeLabelOption?.label ?? "NotFound",
  };
}
