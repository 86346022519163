import React from "react";
import "@draft-js-plugins/linkify/lib/plugin.css";
import Editor from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createAutoListPlugin from "draft-js-autolist-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
} from "@draft-js-plugins/buttons";

import "@draft-js-plugins/inline-toolbar/lib/plugin.css";

import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Immutable from "immutable";
import ScreenNote from "../../../common/components/ScreenNotes";
import HoverLinkItem from "./HoverLinkItem";

const blockRenderMap = Immutable.Map({
  happyTimeBlock: {
    element: "div",
  },
  sadTimeBlock: {
    element: "div",
  },
});

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank",
  rel: "noreferrer noopener",
  theme: { link: "hover:underline" },
});
const autoListPlugin = createAutoListPlugin();

const cancelEditElementId = "cancel_edit_element";

class FGEditor extends React.Component {
  constructor(props) {
    super(props);

    if (props.hideToolbar) {
      this._inlineToolbar = false;
    } else {
      const inlineToolbarPlugin = createInlineToolbarPlugin();
      this._inlineToolbar = inlineToolbarPlugin;
      this.plugins = [inlineToolbarPlugin, autoListPlugin, linkifyPlugin];
    }

    let init_value = props.value;

    if (init_value === "") {
      init_value = "<p></p>";
    }
    const contentState = stateFromHTML(init_value);
    this.state = {
      // editorState: EditorState.createEmpty(),
      editorState: EditorState.createWithContent(contentState),
      editorStatePrevious: EditorState.createWithContent(contentState),
      isReadOnly: true,
      stickyHeader: false,
      print_id: props.print_id,
      has_content: init_value !== "<p></p>",
      disable_focus: false,
      initEditor: false,
    };
    this.saveToHoc = this.saveToHoc.bind(this);
    this.onChange = this.onChange.bind(this);
    this._renderWithToolbar = this._renderWithToolbar.bind(this);
    this._renderDefault = this._renderDefault.bind(this);
    this.focus = this.focus.bind(this);
    this.handleEditorState = this.handleEditorState.bind(this);
    this.cancelEditValues = this.cancelEditValues.bind(this);
    this.cancelEditValuesScreenNote =
      this.cancelEditValuesScreenNote.bind(this);
    this.print = this.print.bind(this);
    this.getSuccessNote = this.getSuccessNote.bind(this);
    this.closeSuccessNote = this.closeSuccessNote.bind(this);

    // this.plugins = [inlineToolbarPlugin, placeholderPlugin];
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentDidUpdate(previousProps, prevState) {
    if (previousProps.value !== this.props.value && !prevState.initEditor) {
      const htmlState = stateFromHTML(this.props.value);
      const newEditorState = EditorState.createWithContent(htmlState);
      // set start default values
      this.setState({
        editorState: EditorState.moveFocusToEnd(newEditorState),
        editorStatePrevious: EditorState.createWithContent(htmlState),
        has_content: this.props.value !== "<p></p>",
        disable_focus: false,
        initEditor: true,
      });
    }

    if (previousProps.force_save_outside !== this.props.force_save_outside) {
      if (this.props.force_save_outside) {
        this.saveToHoc();
        this.setState({
          disable_focus: true,
          isReadOnly: true,
          editorStatePrevious: this.state.editorState,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  handleEditorState(state, focus = false) {
    let { editorStatePrevious } = this.state;
    let hasContent = false;

    const html = stateToHTML(this.state.editorState.getCurrentContent());

    if (html !== "<p><br></p>") {
      hasContent = true;
    }

    if (state) {
      this.saveToHoc();
      editorStatePrevious = this.state.editorState;
    }

    if (focus) {
      this.editor.focus();
    }

    this.setState({
      isReadOnly: state,
      editorStatePrevious,
      has_content: hasContent,
    });
  }

  /**
   * handle onChange
   * @param editorState
   */
  onChange(editorState) {
    const currentContentState = this.state.editorState.getCurrentContent();
    const newContentState = editorState.getCurrentContent();
    let has_content = false;
    if (currentContentState !== newContentState) {
      const html = stateToHTML(editorState.getCurrentContent());
      if (html !== "<p><br></p>") {
        has_content = true;
      }
      this.props.onChange(html);
      this.setState({
        editorState,
        has_content,
      });
    } else {
      this.setState({
        editorState,
      });
    }
  }

  /**
   * show success note
   * @returns {*}
   */
  getSuccessNote() {
    return (
      <ScreenNote
        type="success"
        message="Deine Notizen wurden erfolgreich gespeichert!"
        closeCallback={this.closeSuccessNote}
        undo
        functionCallbackUndo={this.cancelEditValues}
      />
    );
  }

  /**
   * save to hoc component (redux)
   */
  saveToHoc() {
    const { editorState } = this.state;
    const html = stateToHTML(editorState.getCurrentContent());
    this.props.onBlurCall(html);
  }

  cancelEditValues() {
    const html = stateToHTML(
      this.state.editorStatePrevious.getCurrentContent(),
    );
    this.props.onChange(html);
    this.setState({
      editorState: this.state.editorStatePrevious,
      isReadOnly: true,
    });
    this.props.resetForceToSaveNote();
  }

  cancelEditValuesScreenNote() {
    this.cancelEditValues();
    this.setState({
      disable_focus: false,
    });
  }

  /**
   * set the focus to the component
   */
  focus(e) {
    const { disable_focus } = this.state;
    const cancelEditElement = document.getElementById(cancelEditElementId);
    if (
      e.target.id !== cancelEditElementId &&
      !(cancelEditElement && cancelEditElement.contains(e.target)) &&
      !disable_focus
    ) {
      this.editor.focus();
    }
  }

  listenToScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll >= 260 && this.state && !this.state.stickyHeader) {
      this.setState({
        stickyHeader: true,
      });
    }

    if (winScroll < 260 && this.state && this.state.stickyHeader) {
      this.setState({
        stickyHeader: false,
      });
    }
  }

  /**
   * close the error note layer
   */
  closeSuccessNote() {
    this.props.resetForceToSaveNote();
  }

  print() {
    const test =
      "<!DOCTYPE html>\n" +
      '<html lang="de">\n' +
      "  <head>\n" +
      '<style type="text/css">' +
      "body {\n" +
      "  font-family: 'Karla', sans-serif;\n" +
      "  font-size: 21px;\n" +
      "  line-height: 30px; }\n" +
      "  padding: 300px; }\n" +
      "\n" +
      "h1 {\n" +
      "  font-size: 52px;\n" +
      "  line-height: 58px;\n" +
      "  font-weight: bold; }\n" +
      "\n" +
      "h2 {\n" +
      "  font-size: 32px;\n" +
      "  line-height: 38px;\n" +
      "  font-weight: bold; }\n" +
      "\n" +
      "h3 {\n" +
      "  font-weight: bold; }" +
      "</style>\n" +
      "  </head>\n" +
      '  <body id="body">';

    const end = "</body></html>";

    const content = document.getElementById(this.state.print_id);
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(test + content.innerHTML + end);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  /**
   * render default with inline toolbar
   * @returns {*}
   * @private
   */
  _renderWithToolbar() {
    const { isReadOnly, print_id, editorState } = this.state;
    let cssClasses;
    if (isReadOnly) {
      cssClasses = "__editor_wrapper view_mode";
    } else {
      cssClasses = "__editor_wrapper edit_mode";
    }
    const { InlineToolbar } = this._inlineToolbar;

    return (
      <div id={print_id} className={cssClasses}>
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          ref={(element) => {
            this.editor = element;
          }}
          placeholder={this.props.placeholder}
          readOnly={isReadOnly}
          allowUndo
          blockRenderMap={blockRenderMap}
        />
        <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            )
          }
        </InlineToolbar>
      </div>
    );
  }

  /**
   * render default view
   * @returns {*}
   * @private
   */
  _renderDefault() {
    const { isReadOnly, print_id } = this.state;
    return (
      <div id={print_id}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          ref={(element) => {
            this.editor = element;
          }}
          placeholder={this.props.placeholder}
          readOnly={isReadOnly}
          blockRenderMap={blockRenderMap}
        />
      </div>
    );
  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { isReadOnly, stickyHeader, has_content } = this.state;

    const editor = !this._inlineToolbar
      ? this._renderDefault()
      : this._renderWithToolbar();

    return (
      <div
        className="fgEditor"
        onClick={(e) => {
          this.focus(e);
        }}
      >
        <div className={`editor-control ${stickyHeader ? "sticky" : ""}`}>
          <div className="left">
            {isReadOnly ? (
              <HoverLinkItem
                level="plain"
                disabled={false}
                defaultImage="/assets/images/stift_dunkel.png"
                hoverImage="/assets/images/stift_weiss.png"
                text="Notizen bearbeiten"
                onClick={() => {
                  this.handleEditorState(false, true);
                }}
              />
            ) : (
              <HoverLinkItem
                level="success"
                disabled={false}
                defaultImage="/assets/images/icon_ok.png"
                hoverImage=""
                text="speichern"
                onClick={() => {
                  this.handleEditorState(true);
                }}
              />
            )}
          </div>
          <div className="right">
            {!isReadOnly ? (
              <div className="text-icon" id={cancelEditElementId}>
                <HoverLinkItem
                  level="error"
                  disabled={false}
                  defaultImage="/assets/images/icon_abbrechen.png"
                  hoverImage=""
                  text="Änderungen verwerfen"
                  onClick={() => {
                    this.cancelEditValues();
                  }}
                />
              </div>
            ) : (
              <HoverLinkItem
                level="plain"
                disabled={!has_content}
                defaultImage="/assets/images/print.png"
                hoverImage="/assets/images/print_white.png"
                text="Text Drucken"
                onClick={() => {
                  this.print();
                }}
              />
            )}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        {editor}
        <iframe
          id="ifmcontentstoprint"
          title="ifmcontentstoprint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            border: 0,
          }}
        />
      </div>
    );
  }
}
export default FGEditor;
