import { DateTime } from "luxon";
import { fonts } from "./fonts";

const _prepareData = (
  class_id,
  data_items,
  monthsValues,
  lehrplanbezug,
  user,
) => {
  const hoc_list = [];
  data_items.forEach((elem) => {
    if (String(elem.classroom_id) === String(class_id)) {
      const subject = elem.title;
      if (!hoc_list[subject]) {
        hoc_list[subject] = [];
      }

      // sequenzen ermitteln
      if (elem.data && elem.data.length > 0) {
        elem.data.forEach((seq) => {
          // start und ende datum ermittelm

          const dateStart = DateTime.fromFormat(seq.calc_date, "yyyyMMdd");
          const dateEnd = DateTime.fromFormat(seq.calc_end_date, "yyyyMMdd");

          let currDate = dateStart;
          while (
            dateEnd > currDate ||
            currDate.toFormat("M") === dateEnd.toFormat("M")
          ) {
            const month_name = currDate.toFormat("MMMM");
            if (!hoc_list[subject][month_name]) {
              hoc_list[subject][month_name] = [];
            }
            let lp_bezug = "";

            if (lehrplanbezug[String(seq.id)]) {
              lp_bezug = lehrplanbezug[String(seq.id)].join(", ");
            }
            hoc_list[subject][month_name].push({
              name: seq.title,
              lehrplanbezug: lp_bezug,
            });

            currDate = currDate.plus({ months: 1 });
          }
        });
      }
    }
  });
  hoc_list.reverse();

  const lastMonth = monthsValues[monthsValues.length - 1];
  const rows = [];
  data_items.forEach((elem) => {
    if (hoc_list[elem.title]) {
      const tblRow = [];
      tblRow.push({
        image: writeRotatedText(elem.title),
        fit: [7, 53],
        alignment: "right",
        border: [false, true, true, true],
      });
      monthsValues.forEach((month) => {
        let border = [false, true, true, true];
        if (month === lastMonth) {
          border = [true, true, false, true];
        }

        if (hoc_list[elem.title][month]) {
          if (hoc_list[elem.title][month].length > 1) {
            const tmp_list = [];
            hoc_list[elem.title][month].forEach((list) => {
              let tmp;
              if (list.lehrplanbezug) {
                if (Number(user.userId) === 617) {
                  tmp = {
                    text: [{ text: list.name, bold: true }],
                    style: "tableContent",
                    border,
                  };
                } else {
                  tmp = {
                    text: [
                      { text: list.name, bold: true },
                      { text: `\nLehrplanbezug: ${list.lehrplanbezug}` },
                    ],
                    style: "tableContent",
                    border,
                  };
                }
              } else {
                tmp = {
                  text: [{ text: list.name, bold: true }],
                  style: "tableContent",
                  border,
                };
              }
              tmp_list.push(tmp);
            });

            const stack = { stack: tmp_list };
            tblRow.push(stack);
          } else {
            const mdata = hoc_list[elem.title][month][0];

            let tmp;
            if (mdata.lehrplanbezug) {
              tmp = {
                text: [
                  { text: mdata.name, bold: true },
                  { text: `\nLehrplanbezug: ${mdata.lehrplanbezug}` },
                ],
                style: "tableContent",
                border,
              };
            } else {
              tmp = {
                text: [{ text: mdata.name, bold: true }],
                style: "tableContent",
                border,
              };
            }
            tblRow.push(tmp);
          }
        } else {
          const tmp = { text: "", style: "tableContent", border };
          tblRow.push(tmp);
        }
      });
      rows.push(tblRow);
    }
  });

  return rows;
};

// define your function for generating rotated text
function writeRotatedText(text) {
  const canvas = document.createElement("canvas");
  // I am using predefined dimensions so either make this part of the arguments or change at will
  canvas.width = 42;
  canvas.height = 290;
  const ctx = canvas.getContext("2d");
  ctx.font = "32pt Arial";
  ctx.save();
  ctx.translate(42, 290);
  ctx.rotate(-0.5 * Math.PI);
  ctx.fillStyle = "#000";
  ctx.fillText(text, 0, -10);
  ctx.restore();
  return canvas.toDataURL();
}

export default (
  class_id,
  data_items,
  school,
  user,
  lehrplanbezug,
  class_name,
  current_schoolyear,
) => {
  const text_zeitraum = `Schuljahr ${current_schoolyear.label}`;
  const dateStart = DateTime.fromISO(school.startDate);
  const dateEnd = DateTime.fromISO(school.endDate);
  const monthsValues = [];

  let currDate = dateStart;
  while (
    dateEnd > currDate ||
    currDate.toFormat("M") === dateEnd.toFormat("M")
  ) {
    monthsValues.push(currDate.toFormat("MMMM"));
    currDate = currDate.plus({ months: 1 });
  }

  const tableHeaders = [
    { text: "", style: "tableHeader", border: [false, true, false, true] },
  ];

  const tableWidths = [10]; // first

  const monts_count = monthsValues.length - 1;

  const filler = [];
  filler.push({
    image: writeRotatedText("Sonstiges"),
    fit: [7, 53],
    alignment: "right",
    border: [false, true, true, true],
  });

  for (let i = 1; i <= monts_count; i += 1) {
    filler.push({ text: "", style: "tableContent" });
  }
  filler.push({ text: "", border: [true, true, false, true] });

  monthsValues.forEach((name) => {
    tableHeaders.push({
      text: name,
      style: "tableHeader",
      border: [false, true, false, true],
    });
    tableWidths.push("*");
  });

  const rows = _prepareData(
    class_id,
    data_items,
    monthsValues,
    lehrplanbezug,
    user,
  );

  const pdf_title = `Jahresplan  ${class_name}`;

  const documentDefinition = {
    header: {
      margin: 40,
      columns: [
        {
          width: "*",
          text: [
            { text: `${school.school}\n`, fontSize: 8, bold: true },
            {
              text: `${school.street} ${school.nr}\n`,
              fontSize: 8,
              bold: false,
            },
            {
              text: `${school.zip} ${school.location}`,
              fontSize: 8,
              bold: false,
            },
          ],
          style: "header_left",
        },
        {
          width: "*",
          text: [
            { text: "Jahresplan\n", fontSize: 18, bold: true },
            { text: `${class_name} `, fontSize: 8, bold: false },
            { text: `${text_zeitraum}\n\n `, fontSize: 8, bold: true },
          ],
          alignment: "center",
          style: "header_center",
        },
        {
          width: "*",
          text: [
            { text: "Lehrkraft: ", fontSize: 8, bold: false },
            { text: user.name, fontSize: 8, bold: true },
          ],
          alignment: "right",
          style: "header_right",
        },
      ],
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    content: [
      {
        style: "tableFull",
        table: {
          widths: tableWidths,
          heights(row) {
            if (row === 0) {
              return 25;
            }
            return 60;
          },
          headerRows: 1,
          dontBreakRows: false,
          keepWithHeaderRows: 1,
          body: [[...tableHeaders], ...rows, [...filler]],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          // paddingLeft: function(i, node) { return 4; },
          // paddingRight: function(i, node) { return 4; },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableFull: {},
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      tableContent: {
        bold: false,
        fontSize: 6,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      rooText: {
        margin: [10, 10, 10, 15],
      },
      textblock: {
        margin: [0, 5, 0, 5],
      },
      numberfirst: {
        margin: [0, 25, 0, 0],
      },
      footer_left: {
        margin: [40, 0, 0, 0],
        fontSize: 8,
        bold: false,
      },

      header_left: {
        // margin: [0, 20, 0, 40],
      },
      header_center: {
        // margin: [40, 40, 40, 40],
      },
      header_right: {
        // margin: [0, 20, 40, 40],
      },
    },
    pageMargins: [40, 90, 40, 60],
  };

  let str = pdf_title;
  str = str.replace(/\s/g, "_");
  str = str.replace(",", "_");
  str = str.replace(".", "_");
  str += ".pdf";
  window.pdfMake.createPdf(documentDefinition, null, fonts).download(str);
};
