import React from "react";

import StepZilla from "react-stepzilla";
import { connect } from "react-redux";
import PasswordForm from "./addPassword";
import Subjects from "./addSubjects";
import Classes from "./addClasses";
import Timetable from "./addTimetable";
import School from "./addSchool";
import Auth from "../../../common/services/Auth";
import { withRouter } from "../../../common/utils/routing";

import * as ACTIONS from "../../../features/registration/actions";
import * as SCHOOLYEAR_ACTIONS from "../../../features/current-schoolyear/current-schoolyear-slice";
import * as AUTH_ACTIONS from "../../../features/authentication/actions";

import { calcSchoolyearSettings } from "../../../common/config/schoolyear";

import * as API from "../../../common/constants/ApiRoutes";

const mapStateToProps = (state) => ({
  password: state.password,
  subjects: state.subjects,
  classes: state.classes,
  timetable: state.timetable,
});

const mapDispatchToProps = {
  handleRegister: ACTIONS.handleRegister,
  setSchoolYear: SCHOOLYEAR_ACTIONS.setSchoolYear,
  triggerLogout: AUTH_ACTIONS.logout,
  loginSuccess: AUTH_ACTIONS.doLoginSuccess,
};

class Registration extends React.Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    // set the initial component state
    this.storage = {
      password: "",
      subjects: [],
      classes: [],
    };

    this.state = {
      storage: this.storage,
      start_with: 0,
      register_data: {},
      redirect_login: false,
    };
  }

  UNSAFE_componentWillMount() {
    window.sessionStorage.setItem("step", 0);
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.has("data")) {
      Auth.deauthenticateUser();
      this.props.triggerLogout();
      sessionStorage.clear();
    }
  }

  /**
   * on did mount, check request data:
   * _ name
   * _ email
   * _ and some other stuff from registration process.
   *
   */
  componentDidMount() {
    document.title = "Freigeist | Registrierung";
    const queryParams = new URLSearchParams(this.props.location.search);
    if (!queryParams.has("data") && !Auth.isUserAuthenticated()) {
      this.props.navigate("/login");
    }

    const calc_year = calcSchoolyearSettings();
    this.props.setSchoolYear({
      is_active: true,
      label: calc_year.label,
      latest: calc_year.year,
      year: calc_year.year,
      region: "",
    });

    let register_data = false;

    // get data from query string and prepare into object
    if (queryParams.has("data")) {
      const data = queryParams.get("data");
      let decodedData = window.atob(data); // decode the string
      decodedData = decodeURIComponent(decodedData);
      register_data = Object.fromEntries(new URLSearchParams(decodedData));

      this.props.handleRegister(register_data);
      this.setState({
        register_data,
      });
    } else {
      // no register data exists.... back to login
    }

    // 1) first check if authenticated ignore if user exist check
    if (Auth.isUserAuthenticated()) {
      if (this.props.subjects && this.props.subjects.raw.length > 0) {
        this.setState({ start_with: 2 });
      } else if (this.props.classes && this.props.classes.length > 0) {
        this.setState({ start_with: 2 });
      } else if (
        this.props.timetable &&
        this.props.timetable.entries.length > 0
      ) {
        this.setState({ start_with: 3 });
      }
    } else {
      // if user is not authenticated and decoded data exists, check if email address free for register
      const email = encodeURIComponent(register_data.email);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${API.API_ROUTE_AUTH_USER_EXISTS}/${email}`);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.responseType = "json";
      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          // 2) Email address exists, setup flow for next steps and redirect to login
          if (xhr.response.exists) {
            this.setState({
              redirect_login: true,
            });
          } else {
            // user not exits
            this.setState({
              start_with: 0,
            });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert(
            "Technischer Fehler beim prüfen ob Ihre E-Mail Adresse bereits existiert",
          );
          const errors = xhr.response.errors ? xhr.response.errors : {};
          errors.summary = xhr.response.message;
        }
      });
      xhr.send(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.redirect_login !== this.state.redirect_login &&
      this.state.redirect_login
    ) {
      this.setState({
        redirect_login: false,
      });
      this.props.navigate("/login");
    }
  }

  /**
   * fill password data in list
   * @param password
   */
  updatePassword(password) {
    this.storage.password = password;
    this.setState({
      storage: this.storage,
    });
  }

  /**
   * fill subjects data
   * @param subjects
   */
  updateSubjects(subjects) {
    this.storage.subjects = subjects;
    this.setState({
      storage: this.storage,
    });
  }

  /**
   * fill subjects data
   * @param subjects
   */
  updateClasses(classes) {
    this.storage.classes = classes;
    this.setState({
      storage: this.storage,
    });
  }

  /**
   * Render the component.
   */
  render() {
    let start_with_step = window.sessionStorage.getItem("step")
      ? parseFloat(window.sessionStorage.getItem("step"))
      : this.state.start_with;
    if (this.state.start_with > 0)
      start_with_step = parseFloat(this.state.start_with);

    const steps = [
      {
        name: "Passwort",
        component: (
          <PasswordForm
            changePasswordForm={false}
            password={this.props.password.password}
            register_data={this.state.register_data}
            updatePassword={(u) => {
              this.updatePassword(u);
            }}
            onSubmit={this.props.loginSuccess}
          />
        ),
      },
      {
        name: "Fächer",
        component: (
          <Subjects
            updateSubjects={(u) => {
              this.updateSubjects(u);
            }}
          />
        ),
      },
      {
        name: "Klassen",
        component: (
          <Classes
            subjects={this.state.storage.subjects}
            updateClasses={(u) => {
              this.updateClasses(u);
            }}
          />
        ),
      },
      { name: "Stundenplan", component: <Timetable /> },
      {
        name: "Schule und Bundesland",
        component: <School />,
      },
    ];

    return (
      <div id="registration" className="content-wrapper stepzilla new_user">
        <h1>Freigeist einrichten</h1>

        <div className="step-progress">
          <StepZilla
            steps={steps}
            preventEnterSubmission
            // nextTextOnFinalActionStep={"Save"}
            // hocValidationAppliedTo={[3]}
            startAtStep={start_with_step}
            onStepChange={(step) => window.sessionStorage.setItem("step", step)}
          />
        </div>
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Registration));
