module Main exposing (main)

import Api
import Browser
import Browser.Dom
import Browser.Events
import Color
import ColorBrightness exposing (colorFromHex, isBrightColor)
import Data.Screens exposing (MediaValues, getMediaValue, isXsScreen)
import Data.Weekview
    exposing
        ( Day
        , DayData(..)
        , Lesson
        , LessonDetails(..)
        , TimeSlot(..)
        , getLessonDetailsForSequenceAndDay
        )
import Date exposing (Date)
import HeroIcons
import Html exposing (Html, a, br, button, div, header, input, label, li, main_, option, select, span, text, ul)
import Html.Attributes exposing (class, classList, disabled, href, id, placeholder, selected, style, value)
import Html.Events exposing (onClick, onInput)
import Http
import Json.Decode
import Json.Encode
import Language
import Ports
import Regex
import Task
import Time
import Url.Builder



-- MODEL


type alias Flags =
    { apiBaseUrl : String
    , token : String
    , holidays : Json.Encode.Value
    , schoolyearStart : Date
    , schoolyearEnd : Date
    , weekStart : String
    , hasSaturdayClasses : Bool
    }


flagsDecoder : Json.Decode.Decoder Flags
flagsDecoder =
    Json.Decode.map7
        (\apiBaseUrl token holidays schoolyearStart schoolyearEnd weekStart hasSaturdayClasses ->
            { apiBaseUrl = apiBaseUrl
            , token = token
            , holidays = holidays
            , schoolyearStart = schoolyearStart
            , schoolyearEnd = schoolyearEnd
            , weekStart = weekStart
            , hasSaturdayClasses = hasSaturdayClasses
            }
        )
        (Json.Decode.field "apiBaseUrl" Json.Decode.string)
        (Json.Decode.field "token" Json.Decode.string)
        (Json.Decode.field "holidays" Json.Decode.value)
        (Json.Decode.field "schoolyearStart" Api.isoDateDecoder)
        (Json.Decode.field "schoolyearEnd" Api.isoDateDecoder)
        (Json.Decode.field "weekStart" Json.Decode.string)
        (Json.Decode.field "hasSaturdayClasses" Json.Decode.bool)


{-| Type for representing the lifecycle of a request
-}
type RequestStatus a
    = Idle
    | Waiting
    | Success a
    | Failed String


type alias SequenceDialog =
    { date : Date
    , lesson : Maybe Lesson
    , title : String
    , duration : Int
    , request : RequestStatus Int
    }


type alias Model =
    { currentTime : Time.Posix
    , currentZone : Time.Zone
    , selectedDate : Date
    , daysAndLessons : List Day
    , apiBaseUrl : String
    , token : String
    , holidays : Json.Encode.Value
    , schoolyearStart : Date
    , schoolyearEnd : Date
    , hasSaturdayClasses : Bool
    , errMessage : Maybe String
    , screenWidth : Int
    , createSequenceDialog : SequenceDialog
    , shutDown : Bool
    }


initialDialog : SequenceDialog
initialDialog =
    { date = Date.fromRataDie 0
    , lesson = Nothing
    , title = ""
    , duration = 3
    , request = Idle
    }


initialModel : Model
initialModel =
    { currentTime = Time.millisToPosix 0
    , currentZone = Time.utc
    , selectedDate = Date.fromRataDie 0
    , daysAndLessons = []
    , apiBaseUrl = ""
    , token = ""

    -- the holidays are only passed through to the API endpoint so we never parse it
    -- to an Elm value but leave it as a JSON value instead
    , holidays = Json.Encode.null
    , schoolyearStart = Date.fromRataDie 0
    , schoolyearEnd = Date.fromRataDie 0
    , hasSaturdayClasses = False
    , errMessage = Nothing
    , screenWidth = 0
    , createSequenceDialog = initialDialog
    , shutDown = False
    }


getFromAndToDate : Bool -> Date -> { from : Date, to : Date }
getFromAndToDate hasSaturdayClasses date =
    let
        firstDay =
            Date.floor Date.Week date

        lastWeekDay =
            if hasSaturdayClasses then
                Date.Saturday

            else
                Date.Friday

        lastDay =
            Date.ceiling lastWeekDay firstDay
    in
    { from = firstDay, to = lastDay }


init : Json.Encode.Value -> ( Model, Cmd Msg )
init json =
    case Json.Decode.decodeValue flagsDecoder json of
        Ok { apiBaseUrl, token, holidays, schoolyearStart, schoolyearEnd, weekStart, hasSaturdayClasses } ->
            let
                getTimeAndScreenCmd =
                    Task.perform GotTimeAndScreenData <|
                        Task.map3
                            (\posix zone viewport ->
                                { posix = posix
                                , zone = zone
                                , screenWidth = ceiling viewport.scene.width
                                , urlDate = Result.toMaybe (Date.fromIsoString weekStart)
                                }
                            )
                            Time.now
                            Time.here
                            Browser.Dom.getViewport

                -- the Elm Url functions don't expect a slash in the end
                apiBaseUrlWithoutTrailingSlash =
                    String.dropRight 1 apiBaseUrl
            in
            ( { initialModel
                | apiBaseUrl = apiBaseUrlWithoutTrailingSlash
                , token = token
                , holidays = holidays
                , schoolyearStart = schoolyearStart
                , schoolyearEnd = schoolyearEnd
                , hasSaturdayClasses = hasSaturdayClasses
              }
            , getTimeAndScreenCmd
            )

        Err err ->
            ( { initialModel | errMessage = Just "Es gab einen Fehler beim Laden der Wochenansicht. Bitte versuche die Seite neu zu laden. Falls der Fehler bleibt verständige bitte das Freigeist Team unter hilfe@freigeist-app.de." }
            , Ports.logError
                { message = "Got bad flags from JavaScript"
                , key = "InitError"
                , data =
                    Json.Encode.object
                        [ ( "jsonError"
                          , Json.Encode.string (Json.Decode.errorToString err)
                          )
                        ]
                }
            )


type Msg
    = GotTimeAndScreenData { posix : Time.Posix, zone : Time.Zone, screenWidth : Int, urlDate : Maybe Date }
    | ClickedPreviousWeek
    | ClickedNextWeek
    | ClickedToday
    | ClickedDay Date
    | FetchResponse (Result Http.Error Api.CalendarLessonsResponse)
    | WindowResized Int
    | CloseErrBox
    | ClickedLessonWithoutSequence Date Lesson
    | ClickedCloseDialogButton
    | ChangedSequenceTitle String
    | ChangedSequenceDuration String
    | SubmittedCreateSequence
    | CreateSequenceResponse (Result Http.Error Int)
    | CreateSequenceRefresh (Result Http.Error Api.CalendarLessonsResponse)
    | QueryParamsChanged { weekStart : String }
    | ShutDown
    | NoOp



-- SIDE EFFECTS


fetchWeek : Model -> Date -> (Result Http.Error Api.CalendarLessonsResponse -> Msg) -> Cmd Msg
fetchWeek { apiBaseUrl, token, holidays, schoolyearStart, schoolyearEnd, hasSaturdayClasses } date toMsg =
    let
        { from, to } =
            getFromAndToDate hasSaturdayClasses date
    in
    Api.fetchCalendarLessons
        { apiBaseUrl = apiBaseUrl
        , token = token
        , from = from
        , to = to
        , holidays = holidays
        , schoolyearStart = schoolyearStart
        , schoolyearEnd = schoolyearEnd
        , toMsg = toMsg
        }


navigateToWeek : Date -> Cmd Msg
navigateToWeek date =
    Ports.navigate
        { url =
            Url.Builder.absolute
                [ "kalender" ]
                [ Url.Builder.string "weekstart" (Date.toIsoString date) ]
        , replace = True
        }



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        NoOp ->
            ( model, Cmd.none )

        GotTimeAndScreenData { urlDate, posix, zone, screenWidth } ->
            let
                initialDate =
                    case urlDate of
                        Just date ->
                            date

                        -- just a fallback, this should never happen
                        Nothing ->
                            Date.fromPosix zone posix
                                |> Date.clamp model.schoolyearStart model.schoolyearEnd
                                |> Date.floor Date.Monday
            in
            ( { model
                | currentTime = posix
                , selectedDate = initialDate
                , screenWidth = screenWidth
              }
            , fetchWeek model initialDate FetchResponse
            )

        ClickedPreviousWeek ->
            let
                date =
                    Date.add Date.Weeks -1 model.selectedDate
            in
            ( { model | selectedDate = date }
            , navigateToWeek date
            )

        ClickedNextWeek ->
            let
                date =
                    Date.add Date.Weeks 1 model.selectedDate
            in
            ( model
            , navigateToWeek date
            )

        ClickedToday ->
            let
                date =
                    Date.fromPosix model.currentZone model.currentTime
                        -- make sure date stays between schoolyear bounds
                        |> Date.clamp model.schoolyearStart model.schoolyearEnd
            in
            ( { model | selectedDate = date }
            , navigateToWeek date
            )

        ClickedDay date ->
            ( { model | selectedDate = date }
            , Cmd.none
            )

        FetchResponse (Ok { days, schoolyearStart, schoolyearEnd }) ->
            ( { model
                | daysAndLessons = days
                , schoolyearStart = schoolyearStart
                , schoolyearEnd = schoolyearEnd
              }
            , Cmd.none
            )

        FetchResponse (Err err) ->
            ( { model | errMessage = Just "Es gab einen Fehler beim Laden der Wochenansicht. Bitte versuche die Seite neu zu laden. Falls der Fehler bleibt verständige bitte das Freigeist Team unter hilfe@freigeist-app.de." }
            , Ports.logError
                { message = "Received a bad response for fetching calendar data"
                , key = "FetchResponseErr"
                , data =
                    Json.Encode.object
                        [ ( "httpError", Json.Encode.string (Api.httpErrorToString err) )
                        , ( "week", Json.Encode.string (Date.toIsoString model.selectedDate) )
                        ]
                }
            )

        CloseErrBox ->
            ( { model | errMessage = Nothing }, Cmd.none )

        WindowResized width ->
            ( { model | screenWidth = width }, Cmd.none )

        ClickedLessonWithoutSequence date lesson ->
            let
                newCreateSequenceDialog =
                    { initialDialog | date = date, lesson = Just lesson }
            in
            ( { model | createSequenceDialog = newCreateSequenceDialog }
            , Task.attempt
                (\_ -> NoOp)
                (Browser.Dom.focus "weekview-sequence-title-input")
            )

        ClickedCloseDialogButton ->
            ( { model | createSequenceDialog = initialDialog }
            , Cmd.none
            )

        ChangedSequenceTitle title ->
            let
                createSequenceDialog =
                    model.createSequenceDialog

                newCreateSequenceDialog =
                    { createSequenceDialog | title = title }
            in
            ( { model | createSequenceDialog = newCreateSequenceDialog }, Cmd.none )

        ChangedSequenceDuration duration ->
            let
                createSequenceDialog =
                    model.createSequenceDialog

                newNumber =
                    case String.toInt duration of
                        Just int ->
                            int

                        Nothing ->
                            0

                newCreateSequenceDialog =
                    { createSequenceDialog | duration = newNumber }
            in
            ( { model | createSequenceDialog = newCreateSequenceDialog }, Cmd.none )

        SubmittedCreateSequence ->
            let
                dialog =
                    model.createSequenceDialog

                newDialog =
                    { dialog | request = Waiting }

                sequenceStart =
                    Date.floor Date.Week dialog.date

                cmd =
                    case dialog.lesson of
                        Just lesson ->
                            Api.createSequence
                                { apiBaseUrl = model.apiBaseUrl
                                , token = model.token
                                , holidays = model.holidays
                                , schoolyearStart = model.schoolyearStart
                                , schoolyearEnd = model.schoolyearEnd
                                , classId = lesson.classId
                                , subjectId = lesson.subjectId
                                , sequenceStart = sequenceStart
                                , weekLength = dialog.duration
                                , title = dialog.title
                                , toMsg = CreateSequenceResponse
                                }

                        Nothing ->
                            Cmd.none
            in
            ( { model | createSequenceDialog = newDialog }
            , cmd
            )

        CreateSequenceResponse (Ok sequenceId) ->
            let
                dialog =
                    model.createSequenceDialog

                newDialog =
                    { dialog | request = Success sequenceId }
            in
            ( { model | createSequenceDialog = newDialog }
            , fetchWeek model model.selectedDate CreateSequenceRefresh
            )

        CreateSequenceResponse (Err err) ->
            let
                dialog =
                    model.createSequenceDialog

                newDialog =
                    { dialog | request = Failed "Sequenz konnte nicht erstellt werden." }
            in
            ( { model | createSequenceDialog = newDialog }
            , Ports.logError
                { message = "Received a bad response when trying to create a sequence"
                , key = "CreateSequenceResponseErr"
                , data =
                    Json.Encode.object
                        [ ( "httpError"
                          , Json.Encode.string (Api.httpErrorToString err)
                          )
                        ]
                }
            )

        CreateSequenceRefresh (Ok { days }) ->
            let
                dialog =
                    model.createSequenceDialog

                maybeLessonDetails =
                    case dialog.request of
                        Success sequenceId ->
                            getLessonDetailsForSequenceAndDay dialog.date sequenceId days

                        _ ->
                            Nothing

                cmd =
                    case maybeLessonDetails of
                        Just (EmptyLesson sequenceId index) ->
                            let
                                url =
                                    Url.Builder.absolute
                                        [ "sequenzen", sequenceId ]
                                        [ Url.Builder.int "ueIndex" index ]
                            in
                            Cmd.batch
                                [ Ports.navigate { url = url, replace = False }
                                , Task.perform (\_ -> NoOp) (Browser.Dom.setViewport 0 0)
                                ]

                        _ ->
                            Cmd.none
            in
            ( model, cmd )

        CreateSequenceRefresh (Err err) ->
            let
                dialog =
                    model.createSequenceDialog

                newDialog =
                    { dialog | request = Failed "Neue Daten konnten nicht geladen werden." }
            in
            ( { model | createSequenceDialog = newDialog }
            , Ports.logError
                { message = "Received a bad response for fetching calendar data during refresh after sequence creation"
                , key = "CreateSequenceRefreshErr"
                , data =
                    Json.Encode.object
                        [ ( "httpError", Json.Encode.string (Api.httpErrorToString err) )
                        , ( "week", Json.Encode.string (Date.toIsoString model.selectedDate) )
                        ]
                }
            )

        QueryParamsChanged { weekStart } ->
            let
                parsedDate =
                    case Date.fromIsoString weekStart of
                        Ok newDate ->
                            newDate

                        Err _ ->
                            -- if it fails take current date
                            Date.fromPosix model.currentZone model.currentTime

                date =
                    -- make sure date stays between schoolyear bounds
                    Date.clamp model.schoolyearStart model.schoolyearEnd parsedDate
            in
            ( { model | selectedDate = date }
            , fetchWeek model date FetchResponse
            )

        ShutDown ->
            ( { model | shutDown = True }, Cmd.none )



-- VIEW


type ButtonOrientation
    = ButtonLeft
    | ButtonMiddle
    | ButtonRight


viewNavButton :
    { children : List (Html Msg)
    , clickMsg : Msg
    , orientation : ButtonOrientation
    , isDisabled : Bool
    }
    -> Html Msg
viewNavButton { children, clickMsg, orientation, isDisabled } =
    let
        extraClasses =
            case orientation of
                ButtonLeft ->
                    "border rounded-l-full w-8"

                ButtonMiddle ->
                    "border-y px-6"

                ButtonRight ->
                    "border rounded-r-full w-8"
    in
    button
        [ class "flex items-center justify-center h-8 text-sm font-bold bg-gray-80"
        , class "border-black/10 transition-colors"
        , class extraClasses
        , classList
            [ ( "text-gray-30 hover:text-gray-100 hover:bg-green active:bg-green-dark", not isDisabled )
            , ( "text-gray-70 cursor-not-allowed", isDisabled )
            ]
        , onClick clickMsg
        , disabled isDisabled
        ]
        children


{-| Check if the first date is before the second
-}
isBefore : Date -> Date -> Bool
isBefore date1 date2 =
    Date.compare date1 date2 == LT


{-| Check if the first date is after the second
-}
isAfter : Date -> Date -> Bool
isAfter date1 date2 =
    Date.compare date1 date2 == GT


viewHeader : Model -> Html Msg
viewHeader { selectedDate, schoolyearStart, schoolyearEnd } =
    let
        firstSchoolWeekStart =
            Date.floor Date.Week schoolyearStart

        lastSchoolWeekEnd =
            Date.ceiling Date.Week schoolyearEnd
    in
    header [ class "flex justify-end" ]
        [ div [ class "flex items-stretch mt-4 mr-4" ]
            [ viewNavButton
                { clickMsg = ClickedPreviousWeek
                , children = [ div [ class "w-5 h-5" ] [ HeroIcons.arrowSmLeft [] ] ]
                , orientation = ButtonLeft
                , isDisabled = isBefore (Date.add Date.Weeks -1 selectedDate) firstSchoolWeekStart
                }
            , viewNavButton
                { clickMsg = ClickedToday
                , children = [ text "Heute" ]
                , orientation = ButtonMiddle
                , isDisabled = False
                }
            , viewNavButton
                { clickMsg = ClickedNextWeek
                , children = [ div [ class "w-5 h-5" ] [ HeroIcons.arrowSmRight [] ] ]
                , orientation = ButtonRight
                , isDisabled = isAfter (Date.add Date.Weeks 1 selectedDate) lastSchoolWeekEnd
                }
            ]
        ]


viewLessonDetails : Date -> Lesson -> List (Html Msg)
viewLessonDetails date lesson =
    let
        lessonDetails =
            lesson.lessons

        sharedBoxClasses =
            "h-17 border-2 rounded w-15 px-1.5 py-1 cursor-pointer overflow-hidden"

        unplannedClasses =
            "border-gray-90 bg-gray-90 text-gray-70 hover:border-gray-40"

        withBlockLink link child =
            a [ class "contents", href link ]
                [ child ]
    in
    case lessonDetails of
        [] ->
            [ div
                [ class sharedBoxClasses
                , class "border-gray-90 bg-gray-90 text-gray-70 hover:border-gray-40"
                , onClick (ClickedLessonWithoutSequence date lesson)
                ]
                [ text "Plan mich" ]
            ]

        _ ->
            let
                -- TODO remove this once we can display UE conflicts
                -- for now sort the list where planned UEs are at the front and take the first element only
                shortenedList =
                    List.take 1
                        (List.sortWith
                            (\a b ->
                                case ( a, b ) of
                                    ( ExistingLesson detailsA, ExistingLesson detailsB ) ->
                                        if detailsA.isPlanned == detailsB.isPlanned then
                                            EQ

                                        else if detailsA.isPlanned then
                                            LT

                                        else
                                            GT

                                    ( ExistingLesson _, EmptyLesson _ _ ) ->
                                        LT

                                    ( EmptyLesson _ _, ExistingLesson _ ) ->
                                        GT

                                    ( EmptyLesson _ _, EmptyLesson _ _ ) ->
                                        EQ
                            )
                            lessonDetails
                        )
            in
            List.map
                (\item ->
                    case item of
                        ExistingLesson { description, ueId, sequenceId, isPlanned } ->
                            let
                                link =
                                    Url.Builder.absolute
                                        [ "sequenzen", sequenceId ]
                                        [ Url.Builder.string "ueId" ueId ]
                            in
                            withBlockLink link
                                (div
                                    [ class sharedBoxClasses
                                    , class
                                        (if isPlanned then
                                            "border-gray-80 hover:text-green hover:border-gray-40"

                                         else
                                            unplannedClasses
                                        )
                                    ]
                                    [ text
                                        (if isPlanned then
                                            description

                                         else
                                            "Plan mich"
                                        )
                                    ]
                                )

                        EmptyLesson sequenceId ueIndex ->
                            let
                                link =
                                    Url.Builder.absolute
                                        [ "sequenzen", sequenceId ]
                                        [ Url.Builder.int "ueIndex" ueIndex ]
                            in
                            withBlockLink link
                                (div
                                    [ class sharedBoxClasses
                                    , class unplannedClasses
                                    ]
                                    [ text "Plan mich" ]
                                )
                )
                shortenedList


viewClassTitle : Bool -> String -> Html Msg
viewClassTitle shorten classTitle =
    let
        shortenMiddle word =
            if String.length word > 9 then
                String.left 3 word ++ ".." ++ String.right 3 word

            else
                word

        isNumberPlusLetters str =
            let
                numberPlusLettersRegex =
                    Maybe.withDefault Regex.never <|
                        Regex.fromString "^(\\d[a-zA-Z./]{0,2}|\\d{2}[a-zA-Z./]?)$"
            in
            Regex.contains numberPlusLettersRegex str

        smallWord word =
            div [] [ text word ]

        largeWord word =
            div [ class "md:text-3xl md:font-light" ] [ text word ]

        wrapperWithLargeWord children =
            div [ class "mt-4.5 md:mt-1 lg:mt-3 xl:mt-4.5" ] children

        wrapperWithoutLargeWord children =
            div [ class "mt-4.5 md:mt-5.5 lg:mt-8 xl:mt-9" ] children
    in
    if shorten then
        case String.words classTitle of
            [ word ] ->
                wrapperWithoutLargeWord [ div [] [ text (shortenMiddle word) ] ]

            words ->
                List.take 3 words
                    |> List.map (\str -> String.left 2 str)
                    |> String.join " "
                    |> text
                    |> List.singleton
                    |> wrapperWithoutLargeWord

    else
        case String.words classTitle of
            [ word ] ->
                if String.length word <= 3 then
                    wrapperWithLargeWord [ largeWord word ]

                else
                    wrapperWithoutLargeWord [ smallWord word ]

            [ firstWord, secondWord ] ->
                case ( isNumberPlusLetters firstWord, isNumberPlusLetters secondWord ) of
                    ( True, True ) ->
                        wrapperWithoutLargeWord [ smallWord classTitle ]

                    ( False, False ) ->
                        wrapperWithoutLargeWord [ smallWord classTitle ]

                    ( True, False ) ->
                        wrapperWithLargeWord [ largeWord firstWord, smallWord secondWord ]

                    ( False, True ) ->
                        wrapperWithLargeWord [ smallWord firstWord, largeWord secondWord ]

            words ->
                wrapperWithoutLargeWord [ smallWord (String.join " " words) ]


viewSubjectTitle : Bool -> String -> String
viewSubjectTitle shorten subjectTitle =
    if not shorten then
        subjectTitle

    else
        String.left 2 subjectTitle


responsiveness : { slotUnitHeight : MediaValues Float, gapSize : MediaValues Float }
responsiveness =
    { slotUnitHeight =
        { xs = 4.75
        , sm = Nothing
        , md = Nothing
        , lg = Just 5.75
        , xl = Just 6.5
        }
    , gapSize =
        { xs = 0.5
        , sm = Just 0.625
        , md = Just 0.75
        , lg = Just 1
        , xl = Just 1.25
        }
    }


viewLesson :
    { date : Date
    , lesson : Lesson
    , screenWidth : Int
    , isSelected : Bool
    }
    -> Html Msg
viewLesson { date, lesson, screenWidth, isSelected } =
    let
        hexCode =
            lesson.colorClass

        color =
            case colorFromHex hexCode of
                Just colorValue ->
                    colorValue

                Nothing ->
                    Color.white

        isColorBright =
            isBrightColor color

        isPlanned =
            List.any
                (\lessonDetails ->
                    case lessonDetails of
                        ExistingLesson details ->
                            details.isPlanned

                        EmptyLesson _ _ ->
                            False
                )
                lesson.lessons

        shortenTitles =
            isXsScreen screenWidth

        heightPerUnitInRem =
            getMediaValue screenWidth responsiveness.slotUnitHeight

        gapPerUnitInRem =
            getMediaValue screenWidth responsiveness.gapSize

        numberOfUnits =
            toFloat (lesson.durationInMinutes // 45)

        extendsOverLunkBreak =
            lesson.hour < 6 && lesson.hour + floor numberOfUnits > 6

        extraHeight =
            if extendsOverLunkBreak then
                getMediaValue screenWidth responsiveness.gapSize * 2

            else
                0

        -- computed height: duration + gap
        heightInRem =
            (numberOfUnits * heightPerUnitInRem)
                + ((numberOfUnits - 1) * gapPerUnitInRem)
                + extraHeight

        marginTop =
            -- break after 6. period
            if lesson.hour == 6 then
                style "margin-top" (String.fromFloat (getMediaValue screenWidth responsiveness.gapSize * 3) ++ "rem")

            else
                class ""
    in
    li
        [ style "height" (String.fromFloat heightInRem ++ "rem")
        , marginTop
        , class "flex overflow-hidden rounded-md xl:rounded-lg text-2xs"
        ]
        [ div
            [ class "relative flex flex-1 cursor-pointer"
            , style "background-color" lesson.colorClass
            , classList
                [ ( "text-gray-20", isColorBright )
                , ( "text-gray-100", not isColorBright )
                ]
            ]
            [ div
                [ class "sm:hidden absolute top-1 right-1 w-1.5 h-1.5 rounded-full"
                , classList
                    [ ( "bg-gray-100", not isColorBright )
                    , ( "bg-gray-20", isColorBright )
                    , ( "opacity-25", not isSelected && not isPlanned )
                    , ( "opacity-0", isSelected )
                    ]
                ]
                []
            , div [ class "flex-1" ]
                [ viewClassTitle shortenTitles lesson.className
                , div [ class "font-extrabold" ]
                    [ text (viewSubjectTitle shortenTitles lesson.subjectName) ]
                ]
            ]
        , div
            [ class "font-bold text-left bg-gray-100 p-1 lg:p-3 xl:p-4.5"
            , classList [ ( "hidden sm:block", not isSelected ) ]
            ]
            (viewLessonDetails date lesson)
        ]


dayWidthClasses : { hasSaturdayClasses : Bool, isSelected : Bool } -> Html.Attribute msg
dayWidthClasses { hasSaturdayClasses, isSelected } =
    classList
        [ ( "weekview-selected-day-width", isSelected )
        , ( "weekview-saturday-classes", isSelected && hasSaturdayClasses )
        , ( "flex-1", not isSelected )
        , ( "sm:flex-1 sm:max-w-xs", True )
        ]


viewDay :
    { date : Date
    , data : DayData
    , isSelected : Bool
    , today : Date
    , hasSaturdayClasses : Bool
    , isSummerVacation : Bool
    , screenWidth : Int
    }
    -> Html Msg
viewDay { date, data, isSelected, today, hasSaturdayClasses, isSummerVacation, screenWidth } =
    let
        isToday =
            date == today

        weekdayFormat =
            getMediaValue screenWidth
                { xs = "EEEEEE"
                , sm = Nothing
                , md = Nothing
                , lg = Just "EEEE"
                , xl = Nothing
                }

        monthDayFormat =
            getMediaValue screenWidth
                { xs = "d. MMM"
                , sm = Nothing
                , md = Nothing
                , lg = Just "d. MMMM"
                , xl = Nothing
                }
    in
    div
        [ dayWidthClasses { hasSaturdayClasses = hasSaturdayClasses, isSelected = isSelected }
        , onClick (ClickedDay date)
        , class "flex flex-col"
        ]
        [ div
            [ class "flex flex-col items-center justify-center h-8 text-center" ]
            [ div
                [ class "relative font-bold"
                , classList
                    [ ( "text-2xs sm:text-sm", not isSelected )
                    , ( "text-sm", isSelected )
                    , ( "before:absolute before:bg-green before:h-2 before:w-2 before:rounded-full before:-left-4", isToday )
                    , ( "before:top-1.5", isToday && isSelected )
                    , ( "before:top-0.5", isToday && not isSelected )
                    , ( "sm:before:top-1.5", isToday )
                    ]
                ]
                [ text (Date.formatWithLanguage Language.german weekdayFormat date) ]
            , div
                [ class "whitespace-nowrap"
                , classList
                    [ ( "text-3xs sm:text-2xs", not isSelected )
                    , ( "text-xs sm:text-2xs", isSelected )
                    ]
                ]
                [ text (Date.formatWithLanguage Language.german monthDayFormat date) ]
            ]
        , if isSummerVacation then
            viewHoliday { title = "Sommerferien", hasSaturdayClasses = hasSaturdayClasses, isSelected = isSelected }

          else
            case data of
                SchoolDay timeslots ->
                    viewSchoolday
                        { date = date
                        , isSelected = isSelected
                        , timeslots = timeslots
                        , screenWidth = screenWidth
                        }

                Holiday title ->
                    viewHoliday { title = title, hasSaturdayClasses = hasSaturdayClasses, isSelected = isSelected }
        ]


viewHoliday : { title : String, hasSaturdayClasses : Bool, isSelected : Bool } -> Html Msg
viewHoliday { title, hasSaturdayClasses, isSelected } =
    div
        [ dayWidthClasses { hasSaturdayClasses = hasSaturdayClasses, isSelected = isSelected }
        , class "flex-1 mt-6 bg-gray-80 rounded-md xl:rounded-lg"
        ]
        [ div
            [ class "pt-3 px-3.5 font-bold text-2xs xl:text-sm text-gray-50"
            , classList
                [ ( "hidden", not isSelected )
                , ( "sm:block", True )
                ]
            ]
            [ text title ]
        ]


viewSchoolday :
    { date : Date
    , isSelected : Bool
    , timeslots : List TimeSlot
    , screenWidth : Int
    }
    -> Html Msg
viewSchoolday { date, isSelected, timeslots, screenWidth } =
    ul [ class "mt-6 text-center space-y-2 sm:space-y-2.5 md:space-y-3 lg:space-y-4 xl:space-y-5" ] <|
        List.map
            (\slot ->
                case slot of
                    FreeSlot hour ->
                        let
                            marginTop =
                                -- break after 6. period
                                if hour == 6 then
                                    style "margin-top" (String.fromFloat (getMediaValue screenWidth responsiveness.gapSize * 3) ++ "rem")

                                else
                                    class ""
                        in
                        li
                            -- using custom values here as an exception discussed with Tobi
                            [ class "flex overflow-hidden h-[4.75rem] lg:h-[5.75rem] xl:h-[6.5rem] rounded-md xl:rounded-lg bg-gray-80"
                            , marginTop
                            ]
                            []

                    LessonSlot lesson ->
                        viewLesson
                            { date = date
                            , lesson = lesson
                            , screenWidth = screenWidth
                            , isSelected = isSelected
                            }
            )
            timeslots


viewMain : Model -> Html Msg
viewMain model =
    let
        daysAndLessons =
            model.daysAndLessons
                |> List.map (\{ dayInt, data } -> ( Date.fromRataDie dayInt, data ))

        today =
            Date.fromPosix model.currentZone model.currentTime
    in
    main_
        [ class "flex justify-center mx-4.5 sm:mx-6 md:mx-8 xl:mx-15 mt-8 min-h-[20rem]"
        , class "gap-3 sm:gap-4.5 lg:gap-6 xl:gap-10"
        ]
    <|
        List.map
            (\( date, data ) ->
                let
                    isSummerVacation =
                        not (Date.isBetween model.schoolyearStart model.schoolyearEnd date)
                in
                viewDay
                    { date = date
                    , data = data
                    , isSelected = Date.weekday date == Date.weekday model.selectedDate
                    , today = today
                    , hasSaturdayClasses = model.hasSaturdayClasses
                    , isSummerVacation = isSummerVacation
                    , screenWidth = model.screenWidth
                    }
            )
            daysAndLessons


viewErr : Maybe String -> Html Msg
viewErr maybeMessage =
    let
        { isShown, errMessage } =
            case maybeMessage of
                Just message ->
                    { isShown = True, errMessage = message }

                Nothing ->
                    { isShown = False, errMessage = "" }
    in
    div
        [ class "fixed inset-0 z-40 flex items-center justify-center transition-[opacity,visibility,backdrop-filter]"
        , classList [ ( "invisible", not isShown ) ]
        ]
        [ div [ class "absolute inset-0 bg-black/50" ] []
        , div [ class "relative w-full px-10 py-5 mx-10 bg-gray-100 rounded-xl h-1/2" ]
            [ div [] [ button [ class "block w-8 h-8 ml-auto", onClick CloseErrBox ] [ HeroIcons.x [] ] ]
            , div [ class "text-xl" ] [ text errMessage ]
            ]
        ]


viewDialog : SequenceDialog -> Html Msg
viewDialog ({ title, request } as dialog) =
    let
        isDisabled =
            case request of
                Waiting ->
                    True

                Success _ ->
                    True

                _ ->
                    False

        isSubmittable =
            not (String.isEmpty title)

        show =
            case dialog.lesson of
                Just _ ->
                    True

                Nothing ->
                    False
    in
    div
        [ class "fixed inset-0 z-40 flex items-center justify-center transition-[opacity,visibility]"
        , classList [ ( "invisible opacity-0", not show ) ]
        ]
        [ div
            [ class "absolute inset-0 bg-black/50 transition-[backdrop-filter] backdrop-blur" ]
            []
        , div [ class "relative w-full px-8 bg-gray-100 pt-7 pb-9 sm:rounded-lg sm:w-96" ]
            [ button
                [ Html.Attributes.title "Schließen"
                , class "absolute top-0 right-0 block m-1 sm:m-0 sm:translate-x-1/2 sm:-translate-y-1/2"
                , class "w-6 h-6 p-1 text-gray-100 rounded-full bg-gray-20"
                , onClick ClickedCloseDialogButton
                ]
                [ HeroIcons.x [] ]
            , div [ class "text-xl font-bold" ] [ text "Neue Sequenz erstellen" ]
            , case dialog.lesson of
                Just lesson ->
                    viewDialogBody isDisabled isSubmittable lesson

                Nothing ->
                    text ""
            ]
        ]


viewDialogBody : Bool -> Bool -> Lesson -> Html Msg
viewDialogBody isDisabled isSubmittable lesson =
    div [ class "text-sm text-gray-20" ]
        [ div [] [ text (lesson.subjectName ++ ", " ++ lesson.className) ]
        , div [ class "mt-3" ]
            [ label []
                [ text "Titel"
                , div [ class "mt-2" ]
                    [ input
                        [ placeholder "Sequenzname"
                        , class "input"
                        , id "weekview-sequence-title-input"
                        , disabled isDisabled
                        , onInput ChangedSequenceTitle
                        ]
                        []
                    ]
                ]
            ]
        , div [ class "mt-4.5" ]
            [ label []
                [ text "Dauer"
                , select
                    [ class "select"
                    , disabled isDisabled
                    , onInput ChangedSequenceDuration
                    ]
                    (List.map
                        (\weeks ->
                            option
                                [ value (String.fromInt weeks)
                                , selected (weeks == 3)
                                ]
                                [ text (String.fromInt weeks ++ " Wochen") ]
                        )
                        (List.range 1 25)
                    )
                ]
            ]
        , div [ class "flex flex-col mt-6 sm:flex-row gap-2" ]
            [ button
                [ class "fg-btn fg-btn-primary"
                , disabled (not isSubmittable || isDisabled)
                , onClick SubmittedCreateSequence
                ]
                [ text "Erstellen" ]
            , button
                [ class "fg-btn fg-btn-secondary"
                , disabled isDisabled
                , onClick ClickedCloseDialogButton
                ]
                [ text "Abbrechen" ]
            ]
        ]


view : Model -> Html Msg
view model =
    div [ class "text-gray-3 tw-pf" ]
        [ viewHeader model
        , viewMain model
        , viewErr model.errMessage
        , viewDialog model.createSequenceDialog
        ]



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    if not model.shutDown then
        Sub.batch
            [ Browser.Events.onResize (\w h -> WindowResized w)
            , Ports.newQueryParams QueryParamsChanged
            , Ports.shutDown (\_ -> ShutDown)
            ]

    else
        -- remove all subscriptions when shut down
        Sub.none



-- START


main : Program Json.Encode.Value Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }
