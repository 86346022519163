import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { classList } from "../../../common/utils/classList";
import PlusIcon from "../../../assets/images/svg/plus.svg";

function useInitialScroll() {
  useEffect(() => {
    const now = DateTime.now();
    const [item] = document.getElementsByClassName(
      `week-row-child-${now.year}-${now.weekNumber}`,
    );
    const scrollPos = item ? item.offsetLeft - 120 : 0;
    document.getElementById("cal-grid-wrapper").scrollTo({ left: scrollPos });
  }, []);
}

export default function CalMonthCol(props) {
  const {
    childsContainerHeight: cssChildsHeight,
    cssWidth,
    data,
    dataCluster: cluster,
    hasAbWeeks,
    showCreateMenu,
    activeCreateMenu,
    onCreateMenuChange,
    weeksWithSequences,
  } = props;

  useInitialScroll();

  return (
    <div style={{ height: cssChildsHeight }} className="childs-container">
      {data.map((d) => {
        const weekNumber = d.kw;
        const pos = d.pos_start;
        const onTemplates = (event) => {
          event.stopPropagation();
          onCreateMenuChange(null);
          props.showTemplates(pos, weekNumber, cluster);
        };
        const onCreateSequence = (event) => {
          event.stopPropagation();
          onCreateMenuChange(null);
          props.callAddItem(pos, weekNumber, cluster);
        };

        const isFreeWeek = weeksWithSequences.every(
          ({ start, end }) => pos < start || pos >= end,
        );

        return (
          <div
            key={d.id}
            style={{ width: cssWidth, touchAction: "none" }}
            className={classList({
              [`childs-row week-row-child-${d.year}-${d.kw}`]: true,
              [`childs-row end-month week-row-child-${d.year}-${d.kw}`]:
                d.month_break,
              [d.holiday]: d.holiday !== "",
              // active: state.activeElement === d.id,
              abweeks: hasAbWeeks,
            })}
            tabIndex="-1"
            onClick={() => {
              onCreateMenuChange(d.id);
            }}
            onPointerEnter={(event) => {
              if (event.pointerType === "mouse") {
                onCreateMenuChange(d.id);
              }
            }}
            onPointerLeave={(event) => {
              if (event.pointerType === "mouse") {
                onCreateMenuChange(null);
              }
            }}
          >
            {showCreateMenu && isFreeWeek && activeCreateMenu === d.id ? (
              <div className="flex flex-col relative w-full h-full border-4 border-solid box-border tw-pf calendar-inner-dialog border-green">
                <button
                  className="flex-1 w-full text-5xl text-green bg-green-soft text-center"
                  onClick={onCreateSequence}
                  title="Sequenz erstellen"
                >
                  <PlusIcon className="w-5.5 inline-block" />
                </button>
                <button
                  className="h-10 font-bold text-gray-100 leading-10 text-2xs bg-green hover:underline"
                  onClick={onTemplates}
                >
                  Vorlagen
                </button>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
