// react-rnd somehow cancels touchstart events which causes click events
// not to be fired on touch devices.
// the following function progrmmatically triggers a click event after
// detecting pointerevents on touch device to fix this
// https://github.com/react-grid-layout/react-draggable/issues/728
function fixReactRndTouchClicks(element, setElement) {
  return {
    onPointerDown: (e) => {
      if (e.pointerType === "touch") {
        setElement(e.target);
      }
    },
    onPointerMove: (e) => {
      if (e.pointerType === "touch") {
        setElement(null);
      }
    },
    onPointerUp: (e) => {
      if (e.pointerType === "touch" && element === e.target) {
        e.target.click();
      }

      setElement(null);
    },
  };
}

export { fixReactRndTouchClicks };
