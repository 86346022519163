import * as authenticated from "../pages/authenticated";
// app layouts
import AppLayout from "../layouts/Layout";
import LayoutRegister from "../layouts/LayoutRegister";
import AppLayoutSettings from "../layouts/LayoutSettings";
import LayoutLogin from "../layouts/LayoutLogin";
import LayoutGreen from "../layouts/LayoutGreen";

// TODO move settings page once open MRs are closed Aug 2024
import SettingsDashboardPage from "../../common/components/settings/SettingsDashboard";

// pages
import * as timeline from "../pages/timeline";
import * as templates from "../pages/templates";
import * as sequenceDetail from "../pages/sequence-detail";
import * as schoolyearSetup from "../pages/schoolyear-setup";
import * as downloads from "../pages/downloads";
import * as login from "../pages/login";
import * as passwordReset from "../pages/password-reset";
import * as passwordChangeReset from "../pages/password-change";
import * as registration from "../pages/registration";
import * as schoolyears from "../pages/schoolyears";
import * as designSystem from "../pages/design-system";
import * as calendar from "../pages/calendar";
import redirects from "./redirects";

export default [
  /* registration */
  {
    Component: LayoutRegister,
    children: [
      { path: "/registrieren", ...registration },
      { path: "/passwort-vergessen", ...passwordReset },
      { path: "/passwort-aendern", ...passwordChangeReset },
    ],
  },

  /* login */
  {
    Component: LayoutLogin,
    children: [{ path: "/login", ...login }],
  },
  // authenticated routes
  {
    // use this loader as a central place to add (and remove) RTK Query subscriptions
    // for data that is needed throughout the logged in parts of the app (more than one page)
    loader: authenticated.loader,
    children: [
      /* sequences */
      {
        Component: AppLayout,
        children: [
          {
            path: "/zeitleiste",
            ...timeline,
          },
          {
            path: "/sequenzen/:sequenceId",
            ...sequenceDetail,
          },
        ],
      },

      /* weekview */
      {
        path: "/kalender",
        ...calendar,
      },
      /* design */
      {
        path: "/design",
        ...designSystem,
      },

      /* settings */
      {
        Component: LayoutGreen,
        children: [
          {
            path: "/schuljahre/einrichten",
            ...schoolyearSetup,
          },
        ],
      },
      {
        path: "/schuljahre",
        ...schoolyears,
      },
      {
        Component: AppLayoutSettings,
        children: [
          {
            path: "/einstellungen",
            Component: SettingsDashboardPage,
          },
          {
            path: "/downloads",
            ...downloads,
          },
          {
            path: "/vorlagen",
            ...templates,
          },
        ],
      },
    ],
  },
  /* redirects */
  ...redirects,
];
