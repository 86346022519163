/* eslint-disable import/no-import-module-exports */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Settings as LuxonSettings } from "luxon";
import { RouterProvider } from "react-router-dom";
import store, { storeRehydrationPromise } from "./app/store";
import { globalNavigationClickHandler } from "./common/utils/routing";
import { featureFlags } from "./app/feature-flags";
import "core-js";
import createRouter from "./app/router";
import { initSentry } from "./app/sentry";

// eslint-disable-next-line no-console
console.log("FEATURE FLAGS", featureFlags);

// configure luxon
LuxonSettings.defaultLocale = "de-DE";

async function init() {
  // make sure store is restored from localStorage via redux-persist
  await storeRehydrationPromise;
  initSentry();

  // make sure browser is created after store has been rehydrated
  const router = createRouter();

  // ensure SPA routing for all normal links on the page
  function Root() {
    return (
      <Provider store={store}>
        <div
          id="appWrapper"
          onClick={(e) => globalNavigationClickHandler(e, router)}
        >
          <RouterProvider router={router} fallbackElement={<div>???</div>} />
        </div>
      </Provider>
    );
  }

  const renderApp = () => {
    ReactDOM.render(<Root />, document.getElementById("appMountPoint"));
  };

  if (module.hot) {
    module.hot.accept(() => renderApp());
  }
  renderApp();
}

init();
