import React from "react";
import AddClassroomsForm from "../../../common/components/settings/classes/AddClassroomsForm";

class addClasses extends React.Component {
  constructor(props) {
    super(props);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.lastSection = this.lastSection.bind(this);
  }

  /**
   * fill storage in register and go away
   */
  nextSection(classes) {
    this.props.updateClasses(classes);
    this.props.jumpToStep(3);
  }

  prevSection() {
    // this.props.updateClasses( classes )
    this.props.jumpToStep(1);
  }

  lastSection() {
    this.props.jumpToStep(4);
  }

  render() {
    return (
      <div id="subjects" className="step step3">
        <div className="step-text">
          <div className="title">Klassen</div>
          Trage hier die Klassen ein, die du im kommenden Schuljahr unterrichten
          wirst. Du kannst später, unter Einstellungen, Klassen ergänzen,
          löschen oder ändern.
        </div>

        <AddClassroomsForm
          subjects={this.props.subjects}
          prevSection={this.prevSection}
          nextSection={this.nextSection}
          lastSection={this.lastSection}
          storage="local"
        />
      </div>
    );
  }
}
export default addClasses;
