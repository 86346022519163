import React from "react";
import { Link } from "react-router-dom";
import { classList } from "../../utils/classList";

function LoginForm({
  onSubmit,
  errorTypeWrongCredentials,
  errorTypeAutoLogoutOffline,
  errorTime,
  successMessage,
}) {
  return (
    <div
      className={`wrapper forms ${errorTypeWrongCredentials} ${errorTypeAutoLogoutOffline}`}
      id="login"
    >
      <form action="/" onSubmit={onSubmit}>
        <h2 className="card-heading">Bei Freigeist einloggen:</h2>

        {successMessage && <p className="success-message">{successMessage}</p>}

        <div className="field-line">
          <label htmlFor="email">E-Mail</label>
          <input type="text" name="email" id="email" />
        </div>

        <div className="field-line">
          <label htmlFor="password">Passwort</label>
          <input
            key={errorTime}
            type="password"
            name="password"
            id="password"
            className={classList({
              shake: errorTypeWrongCredentials,
            })}
          />
        </div>

        {errorTypeWrongCredentials && (
          <div className="error-message box">
            <div className="text">
              <strong>E-Mail oder Passwort sind falsch</strong>
              <br />
              Bitte probiere noch einmal dich einzuloggen. Solltest du dein
              Passwort vergessen haben,{" "}
              <Link to="/passwort-vergessen">
                kannst du es hier zurücksetzen lassen.
              </Link>
            </div>
          </div>
        )}

        {errorTypeAutoLogoutOffline && (
          <div className="error-message box notice_logout">
            <div className="text">
              <strong>Du wurdest automatisch ausgeloggt</strong>
              <br />
              Deine Internetverbindung war unterbrochen. Da ohne
              <br />
              aktive Internetverbindung die Gefahr besteht, dass
              <br />
              Planungsdaten verloren gehen, haben wir dich
              <br />
              automatisch ausgeloggt.
            </div>
          </div>
        )}

        <div className="button-line">
          {/* we need to put a key on this button to force a rerender to which triggers the animation */}
          <button className="btn btn-step" type="submit">
            Anmelden
          </button>
          <span className="password_link">
            <Link to="/passwort-vergessen">Passwort vergessen?</Link>
          </span>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
