const {
  COMMIT_REF = "XXXXXX",
  BUILD_TIME = "yyyy-MM-dd HH:mm:ss",
  STAGE,
  NODE_ENV,
  API_URL,
  AUTH_URL,
} = process.env;

export { COMMIT_REF, BUILD_TIME, STAGE, NODE_ENV, API_URL, AUTH_URL };
