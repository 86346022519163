import React from "react";
import "@draft-js-plugins/linkify/lib/plugin.css";
import Editor from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import {
  ItalicButton,
  BoldButton,
  HeadlineTwoButton,
} from "@draft-js-plugins/buttons";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import Immutable from "immutable";

const blockRenderMap = Immutable.Map({
  happyTimeBlock: {
    element: "div",
  },
  sadTimeBlock: {
    element: "div",
  },
});

class FGEditor extends React.Component {
  constructor(props) {
    super(props);

    if (props.hideToolbar) {
      this._inlineToolbar = false;
    } else {
      const inlineToolbarPlugin = createInlineToolbarPlugin();
      this._inlineToolbar = inlineToolbarPlugin;
      this.plugins = [inlineToolbarPlugin];
    }

    const init_value = props.value === "" ? "<p></p>" : props.value;
    const contentState = stateFromHTML(init_value);
    this.state = {
      editorState: EditorState.createWithContent(contentState),
    };
    this.saveToHoc = this.saveToHoc.bind(this);
    this.onChange = this.onChange.bind(this);
    this.focus = this.focus.bind(this);
  }

  onChange(editorState) {
    const currentContentState = this.state.editorState.getCurrentContent();
    const newContentState = editorState.getCurrentContent();
    if (currentContentState !== newContentState) {
      const html = stateToHTML(editorState.getCurrentContent());
      this.props.onChange(html);
      this.setState({ editorState });
    } else {
      // do nothing on change is triggered by focus
      this.setState({ editorState });
    }
  }

  focus() {
    if (this.props.handleSortable) {
      this.props.handleSortable(false);
    }
    this.editor.focus();
  }

  saveToHoc() {
    const { editorState } = this.state;
    const html = stateToHTML(editorState.getCurrentContent());
    this.props.onBlurCall(html);
  }

  render() {
    const editor = !this._inlineToolbar ? (
      <Editor
        editorState={this.state.editorState}
        onChange={this.onChange}
        plugins={this.plugins}
        ref={(element) => {
          this.editor = element;
        }}
        placeholder={this.props.placeholder}
        onBlur={this.saveToHoc}
        blockRenderMap={blockRenderMap}
      />
    ) : (
      <div className="__editor_wrapper">
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          ref={(element) => {
            this.editor = element;
          }}
          placeholder={this.props.placeholder}
          onBlur={this.saveToHoc}
          blockRenderMap={blockRenderMap}
        />
        <this._inlineToolbar.InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
              </div>
            )
          }
        </this._inlineToolbar.InlineToolbar>
      </div>
    );

    return (
      <div
        className="fgEditor"
        onClick={(e) => {
          this.focus(e);
        }}
      >
        {editor}
      </div>
    );
  }
}
export default FGEditor;
