import { DateTime, Settings } from "luxon";
import { dateRange } from "../../utils/date";
import { findDatesInMap } from "../sequence_calendar/Utils.js";

Settings.defaultLocale = "de-DE";

export function draw_timeline(slots = 5, to = 45) {
  return Array.from({ length: to + 1 }, (_, index) => ({
    marker: index % slots === 0 ? "long" : "short",
    pos: index,
  }));
}

export function getTimeRows(slots = 5, to = 45) {
  const timerows = [];
  for (let i = 0; i < to; i += slots) {
    timerows.push({
      pos: i,
      minute: i,
    });
  }
  return timerows;
}

/**
 * get day list of sequence
 * mak: ab weeks über die Settings prüfen, da diese die Reihenfolge beinhaltet
 * @param startDateIsoStr
 * @param endDateIsoStr
 * @return {Array}
 */
export function calendar_days(
  startDateIsoStr,
  endDateIsoStr,
  schooldays,
  holidays,
  calendarMap,
  is_ab_weeks_active = false,
  ab_settings,
) {
  const startDate = DateTime.fromISO(startDateIsoStr).startOf("day");
  const lastDate = DateTime.fromISO(endDateIsoStr).startOf("day");

  const dayRange = dateRange(startDate, lastDate);
  let hourCounter = 1;

  const days = dayRange.reduce((acc, currDate) => {
    const find_day_kw = currDate.weekNumber;
    const find_ab_week_to_kw = calendarMap.find(
      (p) => Number(p.kw) === find_day_kw,
    );

    // ab settings active and configured entries exists?
    let ab_week_from_setting = false;
    if (
      find_ab_week_to_kw &&
      find_ab_week_to_kw.ab_week &&
      is_ab_weeks_active &&
      ab_settings.entries.length > 0
    ) {
      ab_week_from_setting = ab_settings.entries.find(
        (p) => Number(p.kw) === find_day_kw,
      );
      if (
        ab_week_from_setting &&
        ab_week_from_setting.ab_week &&
        ab_week_from_setting.ab_week !== find_ab_week_to_kw.ab_week
      ) {
        find_ab_week_to_kw.ab_week = ab_week_from_setting.ab_week;
      }
    }

    let isSchool = false;
    let slot_duration = 0;
    let day_weektype = false;

    if (is_ab_weeks_active) {
      const schoolday = schooldays.find(
        (sd) =>
          sd.day === currDate.weekday &&
          (find_ab_week_to_kw.ab_week === sd.week_type ||
            find_ab_week_to_kw.ab_week === "-"),
      );

      if (schoolday) {
        isSchool = true;
        slot_duration = schoolday.hour;
        day_weektype = schoolday.week_type ?? false;
      }
    } else {
      const schoolday = schooldays.find(
        (sd) => Number(sd.day) === currDate.weekday,
      );

      if (schoolday) {
        isSchool = true;
        slot_duration = schoolday.hour;
        day_weektype = schoolday.week_type ?? false;
      }
    }

    /*
    Marko: wir haben aktuell den Fall, wenn man AB Wochen in den Ferien deaktiviert und A und B  Wochen identisch sind
    (also keine awechselnde AB Woche) dann wird dieser Tag übersprungen. Wir prüfen also erstmal ob AB Woche identisch
    geplant sind, wenn ja überspringen wir die Prüfung ob AB Woche.
    Workaround mit Tobi besprochen.
    */
    const abWeekClones = schooldays.filter(
      (sd) =>
        sd.day === currDate.weekday && find_ab_week_to_kw?.ab_week === "-",
    );
    const hasAbWeekClones =
      abWeekClones.length === 2 &&
      abWeekClones[0].count === abWeekClones[1].count &&
      abWeekClones[0].hour === abWeekClones[1].hour;

    // check if current week inside ab weeks
    if (day_weektype && !hasAbWeekClones) {
      const day_kw = currDate.weekNumber;
      const ab_week_to_kw = calendarMap.find((p) => Number(p.kw) === day_kw);
      if (is_ab_weeks_active && ab_week_to_kw.ab_week !== day_weektype) {
        isSchool = false;
      }
    }

    if (isSchool) {
      let holiday = "";
      let holiday_duration = "";
      let holiday_title = "";

      let hour_text = "";
      if (Number(slot_duration) === 90) {
        hour_text = `Stunden ${hourCounter} / ${hourCounter + 1}`;
        hourCounter += 2;
      } else {
        hour_text = `Stunde ${hourCounter}`;
        hourCounter += 1;
      }

      holidays.forEach((el) => {
        if (el.start && el.end) {
          const b_compareDate = currDate;
          const b_startDate = DateTime.fromFormat(el.start, "d-M-yyyy").minus({
            days: 1,
          });
          const b_endDate = DateTime.fromFormat(el.end, "d-M-yyyy").plus({
            days: 1,
          });

          if (b_startDate < b_compareDate && b_compareDate < b_endDate) {
            holiday = "holiday";
            holiday_duration = 1;
            holiday_title = el.title;
          }
        } else if (String(el.start) === currDate.format("yyyyMd")) {
          holiday = "holiday";
          holiday_duration = 1;
          holiday_title = el.title;
        }
      });

      const newDay = {
        date: currDate.toUTC().toISO(),
        dayslotdate: currDate.toFormat("cccc, d. MMMM"),
        short: currDate.toFormat("d. MMM"),
        day: currDate.weekdayShort,
        holiday,
        holiday_duration,
        holiday_title,
        day_id: currDate.toFormat("yyyyMd"),
        slot_duration: Number(slot_duration),
        hours_text: hour_text,
        title: "",
        week_type: day_weektype,
      };
      return [...acc, newDay];
    }
    return acc;
  }, []);

  return days;
}

// helpers for prepare data structures
export function prepareCalendarDataFromTimeslots(calendar, timeslots) {
  return calendar.map((elem, idx) => {
    const slot = timeslots[idx];
    if (!slot) {
      return elem;
    }
    return {
      ...elem,
      title: slot.title,
      ueId: slot.ueId ?? null,
    };
  });
}

export function setUpSequenceItem(
  subjectOrg,
  sequenceItemOrg,
  school,
  ab_weeks,
  ab_settings = false,
  lessonDuration,
) {
  const newSchooldaysMap = subjectOrg.schooldays.reduce((obj, elem) => {
    if (ab_settings && elem.week_type) {
      const key = `day:${elem.day}-week_type:${elem.week_type}`;
      const count = key in obj ? obj[key].count + 1 : 1;
      return { ...obj, [key]: { ...elem, count } };
    }
    const key = `${elem.day}`;
    const count = key in obj ? obj[key].count + 1 : 1;
    return { ...obj, [key]: { ...elem, count } };
  }, {});
  const newSchoolDays = Object.values(newSchooldaysMap);

  // remap and calcuate hours and days...
  const calculatedList = newSchoolDays.map((item) => ({
    ...item,
    hour: item.count * lessonDuration,
    day: Number(item.day) + 1,
  }));

  const subject = { ...subjectOrg, scooldays: calculatedList };

  const map_res = findDatesInMap(
    school.calendarMap,
    sequenceItemOrg.data.pos,
    sequenceItemOrg.data.weeks,
    school.holidays,
    subject.scooldays,
    ab_settings,
    ab_weeks,
    lessonDuration,
  );

  return {
    ...sequenceItemOrg,
    data: {
      ...sequenceItemOrg.data,
      label_weeks: map_res.label_weeks,
      label_hours: map_res.label_scoolhours,
    },
    schooldays: subject.schooldays,
    scooldays: subject.scooldays,
  };
}
