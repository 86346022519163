import React from "react";
import CreatableSelect from "react-select/creatable";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import FGEditor from "../../../../common/components/sequences/FGEditor";
import PdfArtikulation from "../../../../common/pdf/PDFArtikulation";
import * as ACTIONS from "../../../../features/sequence/sequence-slice";
import {
  selectCurrentSchoolyearData,
  selectCurrentSchoolyear,
} from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { selectGetSchoolData } from "../../../../services/api";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { type RootState } from "../../../store";
import { selectSequenceDetail } from "../../../combined-selectors/index";
import {
  type Slot,
  type ArticulationBlock,
} from "../../../../features/sequence/types";

const selectProps = createSelector(
  [
    (state: RootState, ueParams: { ueId: string | null }) =>
      selectSequenceDetail(state, ueParams),
    (state: RootState) => state.taglist,
    selectCurrentSchoolyearData,
    (state: RootState) =>
      selectGetSchoolData(state, selectCurrentSchoolyear(state)),
    (state: RootState) => state.authentication,
  ],
  (
    sequenceState: ReturnType<typeof selectSequenceDetail>,
    taglist,
    schoolyearData,
    school,
    authentication,
  ) => ({
    dayslotid: sequenceState.dayslotid,
    editor_idx: sequenceState.editor_idx,
    active_sequence: sequenceState.active_sequence_item,
    timeslots: sequenceState.timeslots,
    dayslot_didakt: sequenceState.dayslot_didakt,
    dayslot_homework: sequenceState.dayslot_homework,
    active_sequence_cluster: sequenceState.sequenceCluster,
    dayslot_lehrplanbezug: sequenceState.dayslot_lehrplanbezug,
    dayslotdate: sequenceState.dayslotdate,
    dayslot_title: sequenceState.dayslot_title,
    active_slot_idx: sequenceState.active_slot_idx,
    active_timeslot: sequenceState.active_timeslot,
    dayslots: sequenceState.slotdays, // zeitblöcke
    authentication,
    taglist,
    current_schoolyear: schoolyearData,
    school,
  }),
);

type Props = ReturnType<typeof selectProps>;

type ListItem = { label: string; value: string };

/**
 * Artikulationspdf
 */
function printArtikulationsPdf(props: Props, material_list: ListItem[]) {
  PdfArtikulation(
    props.school,
    props.authentication,
    props.active_sequence_cluster,
    {
      date: props.dayslotdate,
      title: props.dayslot_title,
      didaktik: props.dayslot_didakt,
      lehrplanbezug: props.dayslot_lehrplanbezug,
      sequence_title: props.active_sequence.title,
      homework: props.dayslot_homework,
      material_list,
      current_hour: props.active_slot_idx,
      id: props.dayslotid,
    },
    props.active_timeslot,
    props.dayslots,
    props.current_schoolyear,
  );
}
function SidebarArticulationComponent() {
  const [queryParams] = useSearchParams();

  const ueId = queryParams.get("ueId");
  // calling it props here, to make refactoring to a function component easier
  const props = useAppSelector((state: RootState) =>
    selectProps(state, { ueId }),
  );
  const dispatch = useAppDispatch();
  const material_preselect =
    props.taglist.material_tags && props.taglist.material_tags.length > 0
      ? [...props.taglist.material_tags]
      : [];
  const _material_filter: Record<string, string> = {};
  if (props.taglist.material_tags && props.taglist.material_tags.length > 0) {
    props.taglist.material_tags.forEach((elem: ListItem) => {
      _material_filter[elem.label] = elem.label;
    });
  }

  const material_list_for_day: ListItem[] = [];
  const _material_list_for_day: Record<string, string> = {};

  const lehrplanbezug_list: ListItem[] = [];
  const lehrplansequence: ListItem[] = [];
  const __lehrplanbezug_list: Record<string, string> = {};
  const seq_lehrplanbezug_list: Record<string, string> = {};

  const lp_key = String(props.active_sequence_cluster._id);
  if (
    props.taglist.lehrplanbezug_tags &&
    props.taglist.lehrplanbezug_tags[lp_key]
  ) {
    const tmp_list = props.taglist.lehrplanbezug_tags[lp_key];

    tmp_list.forEach((elem: ListItem) => {
      __lehrplanbezug_list[elem.label] = elem.label;

      lehrplanbezug_list.push({
        label: elem.label,
        value: elem.value,
      });
    });
  }

  props.timeslots.forEach((elem: Slot) => {
    if (elem.data && elem.data.length > 0) {
      elem.data.forEach((data: ArticulationBlock) => {
        if (data.material) {
          data.material.forEach((material) => {
            if (!_material_filter[material.value]) {
              _material_filter[material.value] = material.value;
              material_preselect.push({
                label: material.label,
                value: material.value,
              });
            }
            if (Number(elem.id) === Number(props.dayslotid)) {
              if (!_material_list_for_day[material.value]) {
                _material_list_for_day[material.value] = material.value;
                material_list_for_day.push({
                  label: material.label,
                  value: material.value,
                });
              }
            }
          });
        }
      });
    }
    if (elem.lehrplanbezug) {
      elem.lehrplanbezug.forEach((lb) => {
        if (!__lehrplanbezug_list[lb.value]) {
          __lehrplanbezug_list[lb.value] = lb.value;
          lehrplanbezug_list.push({
            label: lb.label,
            value: lb.value,
          });
        }
        if (!seq_lehrplanbezug_list[lb.value]) {
          seq_lehrplanbezug_list[lb.value] = lb.value;
          lehrplansequence.push({
            label: lb.label,
            value: lb.value,
          });
        }
      });
    }
  });

  const idx_update_key = `${props.dayslotid}-${props.editor_idx}`;

  let { dayslot_didakt } = props;
  if (!dayslot_didakt) {
    dayslot_didakt = "";
  }

  let text_homework = props.dayslot_homework;
  if (text_homework === "<p><br></p>") {
    text_homework = "";
  }

  return (
    <div className="sidebar attachments-wrapper">
      <div className="section didakt-hinter">
        <div className="title">Didaktischer Hintergrund</div>
        <div className="text">
          <FGEditor
            key={`didkat-hinter-dayslotid-${idx_update_key}`}
            placeholder="Hier hinklicken um Informationen zum didaktischen Hintergrund einzutragen"
            value={dayslot_didakt}
            onChange={(e: string) => {
              dispatch(
                ACTIONS.changeDidaktHinter({ id: props.dayslotid, text: e }),
              );
            }}
            onBlurCall={() => {
              dispatch(ACTIONS.updateTimeSlots());
            }}
          />
        </div>
      </div>

      <div className="section">
        <div className="title">Hausaufgaben</div>
        <div className="text">
          <FGEditor
            key={`homework_editor-${idx_update_key}`}
            placeholder="Hier hinklicken um Hausaufgaben einzutragen"
            value={text_homework}
            onChange={(e: string) => {
              dispatch(
                ACTIONS.changeHomework({ id: props.dayslotid, text: e }),
              );
            }}
            onBlurCall={() => {
              dispatch(ACTIONS.updateTimeSlots());
            }}
          />
        </div>
      </div>

      <div className="section">
        <div className="title">Lehrplanbezug</div>
        <div className="text css-1rtrksz">
          <CreatableSelect
            key={`seq-lp-${idx_update_key}`}
            isMulti
            onChange={(val) => {
              dispatch(
                ACTIONS.changeLehrplanbezug({
                  id: props.dayslotid,
                  // spread here to make the type writeable
                  value: [...val],
                }),
              );
            }}
            options={lehrplanbezug_list}
            placeholder="Hier hinklicken um den Lehrplanbezug einzutragen"
            className="cleanSelect"
            classNamePrefix="react-select"
            onBlur={() => {
              dispatch(ACTIONS.updateTimeSlots());
            }}
            value={props.dayslot_lehrplanbezug}
          />
        </div>
      </div>

      <div className="section">
        <div className="title readonly">Gefundenes Material</div>
        <div className="text css-1rtrksz">
          {material_list_for_day.map((elem, idx) => (
            <div key={`tag-${idx}`} className="css-xwjg1b">
              <div className="css-10odw4q">{elem.label}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="section">
        <div className="flex items-center">
          <img
            className="inline-block mr-2"
            src="/assets/images/pdf-icon.png"
          />
          <a
            className="font-bold"
            href="#"
            onClick={() => {
              printArtikulationsPdf(props, material_list_for_day);
            }}
          >
            Artikulationsschema herunterladen
          </a>
        </div>
      </div>
    </div>
  );
}

export default SidebarArticulationComponent;
