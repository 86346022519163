import * as Sentry from "@sentry/react";

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    localStorage.setItem("token", token);
  }

  static customerData(data) {
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("userId", data.userId);
    localStorage.setItem("userRole", data.userRole);
    localStorage.setItem("email", data.email);
    localStorage.setItem("init_firstname", data.name);
    localStorage.setItem("regState", data.regState);
    localStorage.setItem("regDate", data.regDate);

    localStorage.setItem(
      "dont_show_first_payment_modal_persist",
      data.dont_show_first_payment_modal,
    );

    Sentry.setUser({
      id: data.userId,
    });
  }

  static setFirstReminderModalSee(state) {
    localStorage.setItem("showPayReminderFirstModal", state);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem("token") !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("email");
    localStorage.removeItem("init_firstname");
    localStorage.removeItem("regState");
    localStorage.removeItem("regDate");
    localStorage.removeItem("showPayReminderFirstModal");
    localStorage.removeItem("dont_show_first_payment_modal_persist");

    Sentry.configureScope((scope) => scope.setUser(null));
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getHideFirstReminderModalPersist() {
    return localStorage.getItem("dont_show_first_payment_modal_persist");
  }

  static getFirstReminderModalSee() {
    return localStorage.getItem("showPayReminderFirstModal");
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static getUserData() {
    return localStorage.getItem("userData");
  }

  static getInitFirstname() {
    const item = localStorage.getItem("init_firstname");
    if (item) {
      const names = item.split(" ");
      return names[0];
    }
    return "";
  }

  static getUserId() {
    return localStorage.getItem("userId");
  }

  static getEmail() {
    return localStorage.getItem("email");
  }

  static getRegState() {
    return localStorage.getItem("regState");
  }

  static getRegDate() {
    return localStorage.getItem("regDate");
  }

  static getUserRole() {
    return localStorage.getItem("userRole");
  }
}
export default Auth;
