import { DateTime } from "luxon";

const IS_PAYMENT_ACTIVE = true;
/**
 * handle test view
 * @param test_mode
 * @returns {boolean}
 */
export function isPaymentActive(test_mode: boolean) {
  if (!IS_PAYMENT_ACTIVE && test_mode) {
    return true;
  }
  return IS_PAYMENT_ACTIVE;
}

export function calculateEndDateFree(endDate: string) {
  const freeEndDate = DateTime.fromISO(endDate).startOf("day");
  return Math.ceil(freeEndDate.diff(DateTime.now().startOf("day")).as("days"));
}

/**
 * berechnet das erste Zahlungsdatum anhand des Registrierungsdatum
 */
export function getFirstPayDate(end_date_free: string) {
  const currentDate = DateTime.now().startOf("day");
  const endDate = DateTime.fromISO(end_date_free);
  const diffDays = endDate.diff(currentDate).as("days");

  if (diffDays <= 0) {
    return "";
  }
  return endDate.plus({ days: 1 }).toUnixInteger();
}
