import * as TACTIONS from "./actions";

const initState = { material_tags: [], lehrplanbezug_tags: {} };
export function taglist(state = initState, action) {
  switch (action.type) {
    case TACTIONS.SET_TAGLIST: {
      const new_state = {
        ...state,
        material_tags: action.material_tags,
        lehrplanbezug_tags: action.lehrplanbezug_tags,
      };
      return new_state;
    }
    default:
      return state;
  }
}
