import React from "react";
import { connect } from "react-redux";
import * as ACTIONS from "../../features/authentication/actions";

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

const mapDispatchToProps = {
  sendResetLink: ACTIONS.sendResetLink,
};

// http://sahatyalkabov.com/how-to-implement-password-reset-in-nodejs/

class PasswordResetPage extends React.Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);
    // set the initial component state
    this.state = {
      email: "",
      email_valid: false,
    };

    this.processForm = this.processForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    document.title = "Freigeist | Passwort vergessen";
  }

  onChange(value) {
    this.setState({
      email: value.target.value,
      email_valid: true,
    });
  }

  /**
   * Process the form.
   * @param {object} event - the JavaScript event object
   */
  processForm() {
    this.props.sendResetLink(this.state.email);
  }

  /**
   * Render the component.
   */
  render() {
    const auth = this.props.authentication;

    const successMessage = auth && Number(auth.change_request_send) === 1;
    const errorMessage =
      auth && auth.request_error && auth.request_error.message
        ? auth.request_error.message
        : "";
    const action_show = this.state.email_valid;

    return (
      <div className="password-change forms" id="password">
        <h1 className="headline">Passwort ändern</h1>

        <div className="description">
          Gib hier deine Emailadresse ein, mit der du dich bei Freigeist
          registriert hast.
          <br />
          Wir schicken dir dann eine Email, mit der du dein Passwort
          zurücksetzen kannst.
        </div>

        {successMessage && (
          <p className="success-message">
            Die Email ist auf dem Weg (Es kann bis zu 5 Minuten dauern, bevor
            die Email ankommt.
            <br />
            Bitte überprüfe auch den Spam Ordner)
          </p>
        )}
        {errorMessage && (
          <div className="message-box notice">{errorMessage}</div>
        )}

        <div className="field-line">
          <label htmlFor="email">E-Mail</label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              this.onChange(e);
            }}
            value={this.state.email}
          />
        </div>

        <div className="button-line">
          <button
            disabled={!action_show}
            className="btn btn-dark"
            onClick={this.processForm}
            type="submit"
          >
            E-Mail anfordern
          </button>
        </div>
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetPage);
