export function school_types(): Array<{ value: string; label: string }> {
  return [
    { label: "andere", value: "andere" },
    { label: "Grundschule", value: "Grundschule" },
    { label: "Mittelschule", value: "Mittelschule" },
    { label: "Realschule", value: "Realschule" },
    { label: "Gymnasium", value: "Gymnasium" },
    {
      label: "Berufsschule/Berufsoberschule (BOS)",
      value: "Berufsschule/Berufsoberschule (BOS)",
    },
    { label: "Fachoberschule (FOS)", value: "Fachoberschule (FOS)" },
    {
      label: "Förderschule/Sonderpädagogisches Förderzentrum (SFZ)",
      value: "SFZ",
    },
    {
      label: "Schule für Kranke/Klinikschule",
      value: "Schule für Kranke/Klinikschule",
    },
    { label: "Ganztagsschule", value: "Ganztagsschule" },
    { label: "Gesamtschule", value: "Gesamtschule" },
  ];
}
