import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type SliceState = { classroom_id: number | null };

// First approach: define the initial state using that type
const initialState: SliceState = { classroom_id: null };

const activeViewSlice = createSlice({
  name: "activeView",
  initialState, // type SliceState is inferred for the state of the slice
  reducers: {
    setActiveView: (state, action: PayloadAction<number | null>) => ({
      ...state,
      classroom_id: action.payload,
    }),
  },
});

export const { setActiveView } = activeViewSlice.actions;

export default activeViewSlice.reducer;
