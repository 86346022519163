import { createSelector } from "@reduxjs/toolkit";
import type { SequenceTemplate, Option } from "./types";
import { api } from "../../services/api";

const optionIsEqual = (a: Option) => (b: Option) =>
  a.label === b.label && a.value === b.value;

const selectSequenceTemplatesResult =
  api.endpoints.getSequenceTemplates.select();

// Other code such as selectors can use the imported `RootState` type
export const selectSequenceTemplatesResultUniqueLists = createSelector(
  [(result) => result.data],
  (templates: SequenceTemplate[]) => {
    if (!templates) {
      return { tags: [], subjects: [], grades: [], list: [] };
    }
    return {
      tags: [...new Set(templates.map((item) => item.tags ?? []).flat())],
      subjects: templates.reduce<Option[]>(
        (collected, item) =>
          item.selected_subject &&
          item.selected_subject.label &&
          !collected.some(optionIsEqual(item.selected_subject))
            ? [...collected, item.selected_subject]
            : collected,
        [],
      ),
      grades: templates.reduce<Option[]>(
        (collected, item) =>
          item.selected_grade &&
          item.selected_grade.label &&
          !collected.some(optionIsEqual(item.selected_grade))
            ? [...collected, item.selected_grade]
            : collected,
        [],
      ),
      list: templates,
    };
  },
);

export const selectSequenceTemplatesWithUniqueLists = createSelector(
  [selectSequenceTemplatesResult],
  (result) => selectSequenceTemplatesResultUniqueLists(result),
);
