import * as ACTIONS from "./actions";

export function authentication(state = null, action) {
  switch (action.type) {
    case ACTIONS.LOGIN_SUCCESS: {
      const userData = action.data;
      return {
        ...state,
        ...userData,
        // TODO remove this when all occurances of name are replaced with first/lastname
        name: `${userData.firstname} ${userData.lastname}`,
      };
    }

    case ACTIONS.LOGIN_ERROR:
      return state;

    case ACTIONS.CHANGE_PASSWORD:
      return { ...state, changePassNote: "success" };

    case ACTIONS.CHANGE_PASSWORD_ERROR:
      return { ...state, changePassNote: "" };

    case ACTIONS.STAMP_FIRST_TIME_LOGIN_NOTE:
      return { ...state, newLoginStrategy: false };

    case ACTIONS.CHANGE_USERNAME:
      return {
        ...state,
        firstname: action.firstname,
        lastname: action.lastname,
        name: `${action.firstname} ${action.lastname}`, // TODO remove this once old combined name is no longer used
      };

    case ACTIONS.SEND_RESET_LINK:
      return {
        ...state,
        change_request_send: action.status,
        request_error: action.error,
      };

    case "LOGOUT":
      return null;

    default:
      return state;
  }
}
