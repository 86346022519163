import * as Sentry from "@sentry/react";
import { ElmCalendarError } from "../common/utils/custom-errors";
import { COMMIT_REF, STAGE } from "./config";
import store from "./store";

function initSentry(): void {
  // only load sentry in production
  if (STAGE === "production") {
    Sentry.init({
      dsn: "https://68e22d62a02640cf85a4c8dd897230a9@o1120146.ingest.sentry.io/6155277",
      normalizeDepth: 10,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          // maskAllText: true,
          // blockAllMedia: true,
        }),
      ],
      environment: STAGE,
      release: `freigeist-ui@${COMMIT_REF}`,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (error && error instanceof ElmCalendarError) {
          // Sentry needs param reassignment here (as described here: https://docs.sentry.io/platforms/javascript/configuration/filtering/#event-hints)
          // eslint-disable-next-line no-param-reassign
          event.fingerprint = ["{{ default }}", `elm:${error.key}`];
        }
        return event;
      },
    });

    Sentry.setTag("appVersion", COMMIT_REF);
    Sentry.setUser({ id: store.getState().authentication?.userId });
  }
}

export { initSentry };
