import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

export default function LayoutGreen() {
  useEffect(() => {
    document.getElementById("content-container")?.classList.remove("isblured");
    const element = document.getElementById("loading-spinner");
    element?.classList.remove("running");
  }, []);

  return (
    <div className="layout-green">
      <div id="topnav">
        <div className="content-inner-wrapper header">
          <div className="items">
            <div className="item logo">
              <Link to="/login">
                <img src="/assets/images/freigeist.png" />
              </Link>
            </div>
            <div className="item account">
              <Link to="/zeitleiste">abbrechen</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="Main">
        <div className="children-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
