import * as ACTIONS from "./actions";

const default_timetable = {
  ttId: 0,
  saturday: false,
  schoolyear: 0,
  entries: [],
};

export function timetable(state = default_timetable, action) {
  switch (action.type) {
    /**
     * use saturday set to true or false
     */
    case ACTIONS.USE_SATURDAY:
      return { ...state, entries: state.entries, saturday: action.saturday };

    /**
     * 1) check if an entry in entries exists
     * 2) replace if exists (delete and push )
     * 3) have fun...
     *
     * add an new timetable entry to list
     */
    case ACTIONS.HANDLE_ENTRIES: {
      const foundItem = state.entries.find(
        (el) => el.day === action.item.day && el.hour === action.item.hour,
      );

      if (foundItem) {
        const new_list = state.entries.filter((item) => item !== foundItem);

        // clear element dont push new one
        if (action.item.class === "clear") {
          // pruefen ob es sich beim löschen um ein AB Wochen element handelt
          if (
            action.ab_weeks !== "default" &&
            foundItem.classes_ab_weeks &&
            (foundItem.classes_ab_weeks.A || foundItem.classes_ab_weeks.B)
          ) {
            // wenn ja, prüfen ob noch min ein wert dazu besteht - wenn ja keine änderungen
            return state;
          }
          // kein element mehr da - komplett löschen
          return { ...state, entries: new_list };
        }
        // push new one
        return { ...state, entries: [...new_list, action.item] };
      }
      if (action.item.class === "clear") {
        return state;
      }
      return { ...state, entries: [...state.entries, action.item] };
    }
    case ACTIONS.FETCH_TIMETABLE:
    case ACTIONS.UPSERT_TIMETABLE: {
      let new_state = state;
      // console.log('fetch from db');
      if (action.timetable) {
        new_state = action.timetable;
      }
      return new_state;

      /**
       * clear all entries for debugging
       */
    }
    case ACTIONS.CLEAR_ALL:
      return { ...state, saturday: state.saturday, entries: [] };

    default: {
      return state;
    }
  }
}
