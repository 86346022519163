import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

type PasswordState = {
  password: string;
  password_repeat: string;
};

const initialState: PasswordState = {
  password: "",
  password_repeat: "",
};

const passwordSlice = createSlice({
  name: "password",
  initialState: initialState as PasswordState,
  reducers: {
    updatePassword: (state, action: PayloadAction<string>) => ({
      ...state,
      password: action.payload,
    }),
    updatePasswordRepeat: (
      state: PasswordState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      password_repeat: action.payload,
    }),
  },
});

export const { updatePassword, updatePasswordRepeat } = passwordSlice.actions;

export default passwordSlice.reducer;
