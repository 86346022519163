import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AddSubjectForm from "./subjects/AddSubjectForm";
import AddClassesForm from "./classes/AddClassroomsForm";
import AddTimetableForm from "./timetables/AddTimetableForm";
import AddSchoolForm from "./school/AddSchoolForm";
import PasswordForm from "../auth/password/PasswordForm";
import { UserNameForm } from "./others/UserNameForm";
import { LessonMinutesForm } from "./others/LessonMinutesForm";
import ChangeTaktungForm from "./others/ChangeTaktungForm";
import { withRouter } from "../../utils/routing";
import SchoolTypeStateBox from "../generic/schoolTypeStateBox/SchoolTypeStateBox";
import Auth from "../../services/Auth";
import * as PAYMENT_ACTIONS from "../../../features/payment/actions";
import { HolidayEditForm } from "./holidays/holidayEditForm";
import {
  selectCurrentSchoolyearData,
  selectCurrentSchoolyear,
} from "../../../features/current-schoolyear/current-schoolyear-slice";
import { api, selectGetSchoolData } from "../../../services/api";
import { StripeSubscription } from "./payment/Stripe";

const mapStateToProps = (state) => ({
  current_schoolyear: selectCurrentSchoolyearData(state),
  school: selectGetSchoolData(state, selectCurrentSchoolyear(state)),
  payment: state.payment,
});

const mapDispatchToProps = {
  updatePaymentSettings: PAYMENT_ACTIONS.handlePaymentSettings,
  getPaymentSettings: PAYMENT_ACTIONS.getPaymentSettings,
  upsertSchool: api.endpoints.upsertSchool.initiate,
};

const msgNewSchoolyearSetup =
  "Neues Schuljahr anlegen und altes archivieren (verfügbar ab dem 1. Mai)";

class SettingsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.changePassword = this.changePassword.bind(this);
    this.updatePaymentSettings = this.updatePaymentSettings.bind(this);
    this.getPaymentSettings = this.getPaymentSettings.bind(this);
  }

  componentDidMount() {
    // load paymant data on mount
    this.props.getPaymentSettings(Auth.getUserId());

    document.title = "Freigeist | Einstellungen";

    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById("hash".slice(1));
      if (element) {
        element.scrollIntoView({
          // optional params
          behaviour: "smooth",
          block: "start",
          inline: "center",
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.current_schoolyear.is_archive &&
      this.props.current_schoolyear.is_archive
    ) {
      this.props.navigate("/zeitleiste");
    }
  }

  /**
   * fired from stripe Abo form
   */
  getPaymentSettings() {
    this.props.getPaymentSettings(Auth.getUserId());
  }

  updatePaymentSettings(settings) {
    this.props.updatePaymentSettings(settings);
  }

  changePassword(value) {
    this.props.changePassword(value);
  }

  render() {
    const { props } = this;
    return (
      <div className="wrapper" id="settings-dashboard">
        <div className="setting-row-wrapper subject">
          <h2>Klassen</h2>
          <div className="description">
            Trage hier die Klassen ein, die du dieses Schuljahr unterrichtest.
          </div>
          <AddClassesForm fetchData />
        </div>

        <div className="setting-row-wrapper subject">
          <h2>Fächer</h2>
          <div className="description">
            Trage hier die Fächer ein, die du dieses Schuljahr unterrichtest.
          </div>
          <AddSubjectForm fetchData />
        </div>

        <div id="timetable" className="setting-row-wrapper timetable">
          <div className="head-wrapper">
            <h2>Stundenplan</h2>
            <div className="description">
              Trage hier deine Stunden ein, die du dieses Schuljahr
              unterrichtest.
            </div>
          </div>
          <LessonMinutesForm />
          <AddTimetableForm fetchData />
        </div>
        {this.props.current_schoolyear.year >= 2024 && (
          <div id="holidays" className="setting-row-wrapper holidays tw-pf">
            <div className="head-wrapper">
              <h2 className="font-bold">Schuljahr, Ferien und Feiertage</h2>
              <HolidayEditForm />
              <div className="description">
                {this.props.current_schoolyear.year !== 2024 ? (
                  <Link
                    className="text-base mt-5 block underline underline-offset-2"
                    to="/schuljahre/einrichten"
                  >
                    {msgNewSchoolyearSetup}
                  </Link>
                ) : (
                  <div className="text-base mt-5 block underline underline-offset-2 text-gray-60 cursor-not-allowed">
                    {msgNewSchoolyearSetup}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div id="username" className="setting-row-wrapper changename">
          <div className="head-wrapper">
            <h2>Taktung im Artikulationsschema</h2>
            <div className="description" />
          </div>
          <ChangeTaktungForm />
        </div>

        <div className="setting-row-wrapper">
          <div className="head-wrapper">
            <h2>Schule</h2>
            <div className="description">
              Die Angaben zur Schule verwenden wir in den Plänen, die wir für
              dein Schriftwesen erstellen. Du kannst die Felder auch leer
              lassen.
            </div>
          </div>
          <AddSchoolForm context="settings" />
        </div>

        {this.props.current_schoolyear.year <= 2023 && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(
                event.target,
                event.nativeEvent.submitter,
              );
              const formObject = Object.fromEntries(formData.entries());
              const schoolData = {
                ...props.school,
                ...formObject,
              };
              props.upsertSchool(schoolData);
            }}
            className="setting-row-wrapper"
          >
            <SchoolTypeStateBox schoolData={this.props.school} />

            <div className="btn-wrapper region-schooltype-btns">
              <div className="next">
                <button type="submit" className="btn btn-step">
                  Speichern
                </button>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </form>
        )}

        <div id="stripeAbo" className="setting-row-wrapper">
          <div className="head-wrapper">
            <h2>Abo</h2>
            <div className="description" />
          </div>
          <StripeSubscription
            getPaymentSettings={this.getPaymentSettings}
            paymentData={this.props.payment}
          />
        </div>

        <div id="password" className="setting-row-wrapper changepassword">
          <div className="head-wrapper">
            <h2>Passwort ändern</h2>
            <div className="description" />
          </div>
          <PasswordForm changePassword changePasswordForm />
        </div>

        <div id="username" className="setting-row-wrapper changename">
          <div className="head-wrapper">
            <h2>Name</h2>
            <div className="description" />
          </div>
          <UserNameForm />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SettingsDashboard));
