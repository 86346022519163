import React, { useState } from "react";
import { classList } from "../../../common/utils/classList";

type Level = "success" | "error" | "plain";
type Colors = {
  text: string;
  bg: string;
};

function colorMapping(level: Level): Colors {
  switch (level) {
    case "success":
      return { text: "text-green", bg: "bg-green" };

    case "error":
      return { text: "text-red", bg: "bg-red" };

    default:
      return {
        text: "text-gray-10 hover:enabled:text-green",
        bg: "bg-gray-80 group-hover:group-enabled:bg-green",
      };
  }
}

function HoverImageLink(props: {
  disabled: boolean;
  defaultImage: string;
  hoverImage: string;
  text: string;
  level: Level;
  onClick: () => void;
}) {
  const colors = colorMapping(props.level);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const imageSrc =
    isHovered && !props.disabled && props.hoverImage
      ? props.hoverImage
      : props.defaultImage;

  return (
    <button
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        props.onClick();
      }}
      className={classList(
        "flex items-center gap-2 group cursor-pointer",
        "font-bold",
        colors.text,
        { "opacity-30": props.disabled },
      )}
      disabled={props.disabled}
    >
      <span
        className={classList(
          "inline-flex items-center justify-center aspect-square w-[23px] rounded-full",
          { [colors.bg]: true },
        )}
      >
        <img className="w-2.5" src={imageSrc} />
      </span>
      <span className="underline">{props.text}</span>
    </button>
  );
}

export default HoverImageLink;
