import React from "react";
import Dropdown from "react-dropdown";
import TagsInput from "react-tagsinput";
import { connect } from "react-redux";
import Auth from "../../../common/services/Auth";
import { uniqueId } from "../../../common/components/sequence_calendar/Utils";
import { AutosizeInput } from "../../../common/components/generic/AutosizeInput";
import { api } from "../../../services/api";

const mapDispatchToProps = {
  upsertSequenceTemplate: api.endpoints.upsertSequenceTemplate.initiate,
};

class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subjects: [],
      selected_subject: {
        label: "",
        value: "",
      },
      grade: [],
      selected_grade: {
        label: "",
        value: "",
      },
      tags: [],
      schoolyear: "",
      saved_to_backend: false,
      data_submited: false,
      sequence_cal: {},
    };
    this.setGrade = this.setGrade.bind(this);
    this.setSubject = this.setSubject.bind(this);
    this.changeTags = this.changeTags.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.cancelTemplate = this.cancelTemplate.bind(this);
    this.setTemplateTitle = this.setTemplateTitle.bind(this);
    this.saveToBackend = this.saveToBackend.bind(this);
    this.autosizingRenderInput = this.autosizingRenderInput.bind(this);
  }

  /**
   * on did mount fill internal state with props.
   */
  componentDidMount() {
    let i;
    const grade = [];
    for (i = 1; i <= 13; i += 1) {
      grade.push({
        label: i,
        value: i,
      });
    }
    grade.push({
      label: "keiner zugeordnet",
      value: "",
    });

    if (this.props.makeUpdate) {
      const subjects = [];
      this.props.subjects.forEach((obj) => {
        subjects.push({
          label: obj.title,
          value: obj._id,
        });
      });
      subjects.push({
        label: "keinem Fach zuordnen",
        value: "",
      });

      this.setState({
        tags: this.props.selected_sequence.tags,
        title: this.props.selected_sequence.title,
        selected_subject: this.props.selected_sequence.selected_subject,
        selected_grade: this.props.selected_sequence.selected_grade,
        subjects,
        grade,
        schoolyear: this.props.schoolyear,
        sequence_cal: this.props.selected_sequence.sequence_cal,
        sequences_list: this.props.selected_sequence.sequences_list,
        template_id: this.props.selected_sequence.template_id,
      });
    } else {
      const { subjects, selected_subject } = this.state;

      this.props.subjects.forEach((obj) => {
        if (obj._id === this.props.selected_subject) {
          selected_subject.label = obj.title;
          selected_subject.value = obj._id;
        }
        subjects.push({
          label: obj.title,
          value: obj._id,
        });
      });
      subjects.push({
        label: "keinem Fach zuordnen",
        value: "",
      });

      const sequence_cal = this.props.selected_sequence;
      const title = `${this.props.selected_sequence.title} - ${this.props.classroom_title} - ${this.props.schoolyear_label}`;
      sequence_cal.title = title;

      this.setState({
        title,
        subjects,
        selected_subject,
        grade,
        sequence_cal,
      });
    }
  }

  /**
   * set the template name
   * @param e
   */
  setTemplateTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  /**
   * set Jahrgangsstufe
   * @param option
   */
  setGrade(option) {
    this.setState({
      selected_grade: option,
    });
  }

  /**
   * set schulklasse
   * @param option
   */
  setSubject(option) {
    this.setState({
      selected_subject: option,
    });
  }

  saveToBackend(data) {
    const { title } = data;
    this.props
      .upsertSequenceTemplate(data)
      .then(() => {
        this.setState({
          saved_to_backend: true,
          backend_state: "success",
        });
        if (this.props.makeUpdate) {
          this.props.cancelTemplate(true, title);
        } else {
          this.props.cancelTemplate(true);
        }
      })
      .catch(() => {
        this.setState({
          saved_to_backend: false,
          backend_state: "error",
        });
      });
  }

  /**
   * add / remove Tags
   * @param tags
   */
  changeTags(tags) {
    this.setState({ tags });
  }

  /**
   * save to backend
   */
  saveTemplate() {
    if (this.props.makeUpdate) {
      const data = {
        ...this.state,
        user_id: Auth.getUserId(),
        sequence_cal: {
          ...this.state.sequence_cal,
          title: this.state.title,
        },
        insert_new: false,
      };
      this.setState({
        data_submited: true,
      });
      this.saveToBackend(data);
    } else {
      const data = {
        ...this.state,
        user_id: Auth.getUserId(),
        schoolyear: this.props.schoolyear,
        template_id: uniqueId(),
        old_ref_id: this.state.sequence_cal.id,
        sequence_cal: {
          ...this.state.sequence_cal,
          id: uniqueId(), // new seq id
          title: this.state.title,
        },
        insert_new: true,
      };
      this.setState({
        data_submited: true,
      });
      this.saveToBackend(data);
    }
  }

  /**
   * cancel template save setup
   */
  cancelTemplate() {
    this.props.cancelTemplate();
  }

  autosizingRenderInput(props) {
    const { onChange, value, addTag, className, ...other } = props;
    return (
      <AutosizeInput
        type="text"
        onChange={onChange}
        value={value}
        className="h-[37px] box-border border-0 bg-transparent mt-px mb-1.5 py-1.5 px-[7px] text-sm"
        {...other}
      />
    );
  }

  render() {
    const { subjects, selected_subject, grade, selected_grade, title, tags } =
      this.state;
    const tagInputProps = {
      placeholder: "",
    };

    return (
      <div className="template-modal create">
        <h1>Vorlage speichern</h1>
        <div className="field-group">
          <label>
            Name der Vorlage
            <input
              onChange={(e) => {
                this.setTemplateTitle(e);
              }}
              autoComplete="off"
              className="inputtext"
              value={title}
              type="text"
              name="template_name"
              id="template_name"
              placeholder=""
            />
          </label>
        </div>

        <div className="dropdown-wrapper">
          <div className="row">
            <div className="field-group dropdowns">
              <div className="col">
                <label>Fach</label>
                <Dropdown
                  options={subjects}
                  onChange={this.setSubject}
                  value={selected_subject}
                  placeholder="Fach wählen"
                />
              </div>
              <div className="col col-last">
                <label>Jahrgangsstufe</label>
                <Dropdown
                  options={grade}
                  onChange={this.setGrade}
                  value={selected_grade}
                  placeholder="Jahrgangsstufe wählen"
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>
        </div>

        <div className="field-group">
          <label>
            Stichworte
            <div className="tags-wrapper">
              <TagsInput
                value={tags}
                onChange={this.changeTags}
                inputProps={tagInputProps}
                renderInput={this.autosizingRenderInput}
              />
            </div>
            <div className="footnote">
              Stichworte helfen dir, deine Vorlagen zu sortieren und später
              schneller wiederzufinden.
            </div>
          </label>
        </div>

        <div className="field-group">
          <div className="btn_wrapper">
            <button className="btn btn-step" onClick={this.saveTemplate}>
              Speichern
            </button>
            <button
              className="btn btn-dark cancel"
              onClick={this.cancelTemplate}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(CreateTemplate);
