export const CHANGE_TITLE_FORM = "CHANGE_TITLE_FORM";
export const CHANGE_DESCRIPTION_FORM = "CHANGE_DESCRIPTION_FORM";
export const CHANGE_COLOR_FORM = "CHANGE_COLOR_FORM";

export const CHANGE_HOURS_FORM = "CHANGE_HOURS_FORM";
export const CHANGE_CLASSDAYS_FORM = "CHANGE_CLASSDAYS_FORM";

export const CLEAR_FORM = "CLEAR_FORM";

export const ADD_SCOOLDAYS_ROW = "ADD_SCOOLDAYS_ROW";
export const CHANGE_SCOOLDAY_TEXT = "CHANGE_SCOOLDAY_TEXT";

export function changeTitle(text) {
  return { type: CHANGE_TITLE_FORM, text };
}
export function changeDescription(text) {
  return { type: CHANGE_DESCRIPTION_FORM, text };
}
export function changeColor(text) {
  return { type: CHANGE_COLOR_FORM, text };
}

export function addScooldayRow() {
  return { type: ADD_SCOOLDAYS_ROW };
}

export function changeScooldayRowText(key, index, text) {
  return {
    type: CHANGE_SCOOLDAY_TEXT,
    key,
    index,
    value: text,
  };
}

export function clearForm() {
  return { type: CLEAR_FORM };
}
