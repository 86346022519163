import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import FormField from "./FormField";

function PasswordField(props) {
  const {
    minStrength = 3,
    thresholdLength = 7,
    label,
    fieldId,
    placeholder,
    required = false,
    children = null,
  } = props;

  // initialize internal component state
  const [state, setState] = useState({
    password: "",
    strength: 0,
    strengthMessage: "",
  });

  useEffect(() => {
    // this import is only there to trigger downloading as soon as the component renders
    import("zxcvbn");
  }, []);

  const { password, strength, strengthMessage } = state;

  // set default minStrength to 3 if not a number or not specified
  // minStrength must be a a number between 0 - 4

  const boundedMinStrength =
    typeof minStrength === "number" ? Math.max(Math.min(minStrength, 4), 0) : 3;

  // set default thresholdLength to 7 if not a number or not specified
  // thresholdLength must be a minimum value of 7

  const boundedThresholdLength =
    typeof thresholdLength === "number" ? Math.max(thresholdLength, 7) : 7;

  const stateChanged = async (fieldState) => {
    // update the internal state using the updated state from the form field
    const zxcvbn = await import("zxcvbn");
    setState({
      ...state,
      password: fieldState.value,
      strength: zxcvbn(fieldState.value).score,
    });
    props.onStateChanged(fieldState);
  };

  const validatePasswordStrong = async (value) => {
    const zxcvbn = await import("zxcvbn");
    // ensure password is long enough
    let $strength_message = "";
    if (value.length <= boundedThresholdLength) {
      $strength_message = "Dein Passwort ist zu kurz";
    }

    // ensure password is strong enough using the zxcvbn library
    if (zxcvbn(value).score < boundedMinStrength) {
      $strength_message = "Dein Passwort ist unsicher";
    }

    if (zxcvbn(value).score >= boundedMinStrength) {
      $strength_message = "Dein Passwort ist sicher";
      props.setPasswordValue(value);
    }

    setState({ ...state, strengthMessage: $strength_message });
  };

  const passwordLength = password.length;

  // password strength meter is only visible when password is not empty
  const strengthClass = [
    "strength-meter mt-2",
    passwordLength > 0 ? "visible" : "invisible",
  ]
    .join(" ")
    .trim();

  return (
    <div className="position-relative">
      {/* Pass the validation and stateChanged functions as props to the form field */}
      <FormField
        type="password"
        label={label}
        fieldId={fieldId}
        placeholder={placeholder}
        validator={validatePasswordStrong}
        onStateChanged={stateChanged}
        required={required}
      >
        {children}

        {/* Render the password strength meter */}
        <div className="strength-meter-wrapper">
          <div className={strengthClass} style={{ clear: "both" }}>
            <div className="strength-meter-fill" data-strength={strength} />
          </div>
          <div className="strength-meter-text">{strengthMessage}</div>
        </div>

        <div className="after-field-content">
          Benutze Zahlen, Sonderzeichen und Groß- Kleinschreibung um die
          Sicherheit zu erhöhen.
        </div>
      </FormField>
    </div>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PasswordField;
