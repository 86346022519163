import React from "react";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { DateTime } from "luxon";
import { connect } from "react-redux";
import DaySlots from "../DaySlots";
import Timeslots from "./timeslots/TimeslotsComponent";
import FGEditor from "../../../components/editor/FGEditor";
import SidebarArticulation from "../parts/SidebarArticulationComponent";
import Material from "../../../components/material/Materials";
import {
  ueMaterialSlice,
  ueMaterialThunks,
} from "../../../../features/material/material-slice";

import { withRouter } from "../../../../common/utils/routing";
import { deepSet } from "../../../../common/utils/assortments";
import * as ACTIONS from "../../../../features/sequence/sequence-slice";
import {
  selectLessonDuration,
  selectSettings,
} from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { ueMaterialSelector } from "../../../../features/sequence/sequence-slice";
import { api } from "../../../../services/api";
import { selectSequenceDetail } from "../../../combined-selectors/index";

const mapStateToProps = (state, ownProps) => {
  const queryParams = new URLSearchParams(ownProps.location.search);
  const ueId = queryParams.get("ueId");
  const sequenceDetail = selectSequenceDetail(state, { ueId });
  return {
    activeSlotIdx: sequenceDetail.active_slot_idx,
    active_sequence: sequenceDetail.active_sequence_item, // new,
    timeslots: sequenceDetail.timeslots,
    dayslots: sequenceDetail.slotdays, // zeitblöcke
    dayslotid: sequenceDetail.dayslotid,
    dayObjId: sequenceDetail.dayObjId,
    dayslotdate: sequenceDetail.dayslotdate,
    sequences: sequenceDetail,
    dayslot_title: sequenceDetail.dayslot_title,
    dayslot_tab: sequenceDetail.dayslot_tab,
    dayslot_note: sequenceDetail.dayslot_note,
    dayslot_hours: sequenceDetail.dayslot_hours,
    editor_idx: sequenceDetail.editor_idx,
    settings: selectSettings(state),
    schoolyear: selectCurrentSchoolyear(state),
    lessonDuration: selectLessonDuration(state),
  };
};

const mapDispatchToProps = {
  moveDayslotData: ACTIONS.moveDayslotData,
  setDaySlotId: ACTIONS.setDaySlotId,
  updateTimeSlots: ACTIONS.updateTimeSlots,
  changeDaySlotTitle: ACTIONS.changeDaySlotTitle,
  changeDaySlotTab: ACTIONS.changeDaySlotTab,
  changeDaySlotNote: ACTIONS.changeDaySlotNote,
  updateSettings: api.endpoints.updateSettings.initiate,
};

// TODO how get we get a proper date here?
// very hacky way to get date
const hackyDateParsing = (dayslotdate, dayslotid) => {
  const year = dayslotid.slice(0, 4);
  const day = dayslotdate.split(" ")?.[1]?.replace(/\.$/, "");
  const month = dayslotid.slice(4).replace(new RegExp(`${day}$`), "");

  if (!(year && month && day)) {
    return null;
  }
  const date = DateTime.fromObject({ year, day, month }, { zone: "local" });
  return !date.invalid ? date : null;
};

class ArticulationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unsaved_editor_value: false,
      force_note_save: false,
    };

    this.setEditSlotId = this.setEditSlotId.bind(this);
    this.handleSlotMove = this.handleSlotMove.bind(this);
    this.handleDayNote = this.handleDayNote.bind(this);
    this.changeDayslotTitle = this.changeDayslotTitle.bind(this);
    this.changeDayslotTab = this.changeDayslotTab.bind(this);
    this.updateItems = this.updateItems.bind(this);
    this.resetForceToSaveNote = this.resetForceToSaveNote.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.dayslot_tab !== this.props.dayslot_tab) {
      this.updateItems();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick(e) {
    if (this.note_node && this.note_node.contains(e.target)) {
      return;
    }
    if (this.state) {
      const { unsaved_editor_value } = this.state;
      if (unsaved_editor_value) {
        this.setState({
          force_note_save: true,
        });
      }
    }
  }

  handleDayNote(input) {
    this.props.changeDaySlotNote({ id: this.props.dayslotid, note: input });
    this.setState({
      unsaved_editor_value: true,
    });
  }

  /**
   * set the clicked active day from articulation to the settings object
   */
  handleSequenceActiveDaySettings(activeDay) {
    const { props } = this;

    const sequences = props.settings.settings.sequences ?? [];
    const sequenceId = Number(props.params.sequenceId);
    const { schoolyear } = props;
    const index = sequences.findIndex(
      (elem) => elem.seq_id && Number(elem.seq_id) === sequenceId,
    );

    const newSettings =
      index === -1
        ? // append new item to sequence settings
          deepSet(
            ["settings", "sequences", sequences.length],
            { seq_id: sequenceId, active_day: activeDay },
            props.settings,
          )
        : // set active day on found sequence
          deepSet(
            ["settings", "sequences", index],
            { ...sequences[index], active_day: activeDay },
            props.settings,
          );

    props.updateSettings({ ...newSettings, schoolyear });
  }

  handleSlotMove(data) {
    const { props } = this;
    const moveData = {
      ...data,
      slotDays: props.dayslots,
      activeSlotIdx: props.activeSlotIdx,
    };
    props.moveDayslotData(moveData);

    props.updateTimeSlots();
  }

  /**
   * set id as dayslotid and check if an array element with this data exists.
   * if not create it and update state.
   * @param id
   */
  setEditSlotId(id, clicked_index) {
    if (id !== this.props.dayslotid) {
      const created_tab_new = !!this.props.active_sequence.tab_created;
      this.props.setDaySlotId({ id, created_tab_new });

      this.handleSequenceActiveDaySettings(clicked_index);
    }
  }

  /**
   * update timeslots data (sequences)
   * @param type
   */
  updateItems() {
    this.props.updateTimeSlots();
    this.setState({
      unsaved_editor_value: false,
      force_note_save: false,
    });
  }

  /**
   * change the title of active day
   * @param e
   */
  changeDayslotTitle(e) {
    this.props.changeDaySlotTitle({
      id: this.props.dayslotid,
      title: e.target.value,
    });
  }

  /**
   * change the tab of active day
   * @param value
   * @param namespace
   */
  changeDayslotTab(value) {
    this.props.changeDaySlotTab({ id: this.props.dayslotid, tab: value });
  }

  resetForceToSaveNote() {
    this.setState({
      force_note_save: false,
    });
  }

  render() {
    const { props } = this;
    const sequence_title = props.active_sequence.title;
    const title_dayslot_date = props.dayslotdate;
    const hours_text = props.dayslot_hours;
    const { title } = props.active_sequence;
    const { weeks } = props.active_sequence;
    const { hours } = props.active_sequence;
    const idx_update_key = `${props.dayslotid}-${props.editor_idx}`;
    let { dayslot_note } = props;

    if (!dayslot_note || dayslot_note === "<p>undefined</p>") {
      dayslot_note = "";
    }

    const unit_count = props.sequences.slot_duration / props.lessonDuration;

    const current_tab =
      props.dayslot_tab && props.dayslot_tab ? props.dayslot_tab : "notes-unit";

    const { force_note_save } = this.state;

    const isMaterialVisible = props.isVisible && current_tab === "material";

    const { dayslotdate, dayslotid, sequences } = props;
    const date =
      dayslotdate && dayslotid
        ? hackyDateParsing(dayslotdate, dayslotid)
        : null;

    const { ueMaterialModel } = sequences;
    const timeslots = props.timeslots.map((slot) => ({
      ...slot,
      hasMaterials: ueMaterialModel.material.some((material) =>
        material.ues.includes(slot.ueId),
      ),
    }));

    return (
      <div id="articluation_wrapper" className="tw-pf">
        <div className="bg-gray-80 pl-5">
          <div className="content-inner-wrapper">
            <DaySlots
              activeSlotIdx={props.activeSlotIdx}
              timeslots={timeslots}
              daylist={props.dayslots}
              onSlotClick={this.setEditSlotId}
              onSlotMove={this.handleSlotMove}
              lessonDuration={props.lessonDuration}
            />
          </div>
        </div>

        <div className="tw-pf font-bold pt-3.5 pb-3 px-5">
          <div className="flex font-bold gap-2 text-gray-10">
            <input
              type="text"
              className="flex-1 text-xl font-bold text-gray-10 placeholder:text-xl placeholder:font-bold"
              value={props.dayslot_title}
              placeholder="Thema der Unterrichtseinheit hier eintragen"
              onChange={(e) => this.changeDayslotTitle(e)}
              onBlur={() => this.updateItems()}
            />
            {date ? (
              <a
                className="text-sm underline opacity-60"
                href={`/kalender?weekstart=${date.toISODate()}`}
              >
                Im Kalender anzeigen
              </a>
            ) : null}
          </div>
          <div className="text-sm font-normal">
            {title_dayslot_date} | {unit_count}{" "}
            {unit_count > 1 ? "Schulstunden" : "Schulstunde"}
          </div>
        </div>

        <Tabs
          name="schoolunitTab"
          className="tabs-second-wrapper"
          handleSelect={this.changeDayslotTab}
          selectedTab={current_tab}
        >
          <div className="tab-links">
            <TabLink to="notes-unit">Notizen</TabLink>
            <TabLink to="material">Material</TabLink>
            <TabLink to="articulation">Artikulation</TabLink>
          </div>

          <div className="content">
            <TabContent for="notes-unit">
              <div
                ref={(note_node) => {
                  this.note_node = note_node;
                }}
                className="editor-wrapper note-editor-wrapper"
              >
                <FGEditor
                  print_id={`print_note-${idx_update_key}`}
                  key={`seq_note_editor-${idx_update_key}`}
                  placeholder="Trage hier Notizen zu dieser Unterrichtseinheit ein"
                  value={dayslot_note}
                  onChange={this.handleDayNote}
                  onBlurCall={() => this.updateItems()}
                  force_save_outside={force_note_save}
                  resetForceToSaveNote={() => {
                    this.resetForceToSaveNote();
                  }}
                />
              </div>
            </TabContent>

            <TabContent for="material">
              <Material
                ueId={this.props.dayObjId}
                actions={ueMaterialSlice.actions}
                thunks={ueMaterialThunks}
                modelSelector={ueMaterialSelector}
                isVisible={isMaterialVisible}
              />
            </TabContent>

            <TabContent for="articulation">
              <div id="slot_edit" className="content-inner-wrapper">
                <div className="wrapper-slot-edit">
                  <div className="timeslot-header">
                    <div className="flex">
                      <div className="sequence-title">{sequence_title}</div>
                    </div>
                    <div className="flex text-right">
                      {title_dayslot_date}
                      &nbsp;<span className="demiliter">|</span>&nbsp;
                      {hours_text} von {hours} {title} ({weeks} Wochen)
                    </div>
                  </div>

                  <div className="flex-wrapper">
                    <Timeslots />

                    <SidebarArticulation />

                    <div style={{ clear: "both" }} />
                  </div>
                </div>
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(ArticulationComponent)),
);
