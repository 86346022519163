import { DateTime } from "luxon";
import { fonts } from "./fonts";

/**
 *
 * @param date_elem
 * @param data
 * @param saturday
 * @param length
 * @private
 */
const _prepareData = (weeklist, data_items, sequences_list) => {
  const list = [];
  if (data_items.data) {
    data_items.data.forEach((item) => {
      list.push(item);
    });
  }

  const rows = [];
  weeklist.forEach((week, idx) => {
    rows[idx] = [];

    const txt = `${week.date.substr(0, 3)} bis ${week.end_date.substr(0, 3)}\n${
      week.month_name
    }`;
    const row = {
      week: txt,
      seq_title: "",
      themen: [],
      lehrplanbezug: [],
      material: [],
    };

    rows[idx].push(row);

    list.forEach((lkw) => {
      if (
        week.compDate >= lkw.calc_date &&
        week.compEndDate <= lkw.calc_end_date
      ) {
        // ermitle timeslots zur lkw id = sequence_item_id
        const themen = [];
        const _lehrplanbezug = [];
        const lehrplanbezug = [];
        const material = [];
        const _material = [];
        sequences_list.forEach((sl) => {
          if (Number(sl.sequence_item_id) === Number(lkw.id)) {
            if (sl.timeslots && sl.timeslots.length > 0) {
              sl.timeslots.forEach((ts) => {
                if (
                  String(week.kw) === DateTime.fromISO(ts.date).toFormat("W")
                ) {
                  themen.push(ts.title);

                  if (ts.lehrplanbezug && ts.lehrplanbezug.length > 0) {
                    ts.lehrplanbezug.forEach((lp) => {
                      if (!_lehrplanbezug[lp.label] && lp.label) {
                        _lehrplanbezug[lp.label] = lp.label;
                        lehrplanbezug.push(lp.label);
                      }
                    });
                  }

                  if (ts.data && ts.data.length > 0) {
                    ts.data.forEach((dp) => {
                      if (dp.material && dp.material.length > 0) {
                        dp.material.forEach((lp) => {
                          if (!_material[lp.label] && lp.label) {
                            _material[lp.label] = lp.label;
                            material.push(lp.label);
                          }
                        });
                      }
                    });
                  }
                }
              });
            }
          }
        });

        const newRow = {
          week: txt,
          seq_title: lkw.title,
          themen,
          lehrplanbezug,
          material,
        };
        rows[idx].push(newRow);
      }
    });
  });

  // console.log('rows sorted?');
  // console.log(rows);

  const tableRows = [];
  rows.forEach((row) => {
    if (row.length > 1) {
      // console.log('länge: ' + row.length);

      if (row.length === 2) {
        // console.log('length 2');

        const item = [
          {
            text: row[1].week,
            style: "defaultRow",
            border: [false, true, false, true],
          },
          {
            text: row[1].seq_title,
            lineHeight: 1.5,
            bold: true,
            style: "defaultRow",
            border: [false, true, false, true],
          },
          {
            text: row[1].themen.join("\n"),
            lineHeight: 1.5,
            bold: true,
            style: "defaultRow",
            border: [false, true, false, true],
          },
          {
            text: row[1].lehrplanbezug.join(", "),
            style: "defaultRow",
            border: [false, true, false, true],
          },
          {
            text: row[1].material.join(", "),
            style: "defaultRow",
            border: [false, true, false, true],
          },
        ];
        tableRows.push(item);
      } else {
        // check if more rows - make border only on first on top
        const last_nr = row.length;

        // console.log('last nr : ' + last_nr);

        for (let i = 1; i < row.length; i += 1) {
          // console.log('i = ' + i);

          if (i === 1) {
            const item = [
              {
                text: row[i].week,
                style: "defaultRow",
                border: [false, false, false, false],
              },
              {
                text: row[i].seq_title,
                lineHeight: 1.5,
                bold: true,
                style: "defaultRow",
                border: [false, false, false, false],
              },
              {
                text: row[i].themen.join("\n"),
                lineHeight: 1.5,
                bold: true,
                style: "defaultRow",
                border: [false, false, false, false],
              },
              {
                text: row[i].lehrplanbezug.join(", "),
                style: "defaultRow",
                border: [false, false, false, false],
              },
              {
                text: row[i].material.join(", "),
                style: "defaultRow",
                border: [false, false, false, false],
              },
            ];
            tableRows.push(item);
          } else {
            const border =
              i === last_nr - 1
                ? [false, false, false, true]
                : [false, false, false, false];

            const item = [
              { text: "", style: "defaultRow", border },
              {
                text: row[i].seq_title,
                lineHeight: 1.5,
                bold: true,
                style: "defaultRow",
                border,
              },
              {
                text: row[i].themen.join("\n"),
                lineHeight: 1.5,
                bold: true,
                style: "defaultRow",
                border,
              },
              {
                text: row[i].lehrplanbezug.join(", "),
                style: "defaultRow",
                border,
              },
              {
                text: row[i].material.join(", "),
                style: "defaultRow",
                border,
              },
            ];
            tableRows.push(item);
          }
        }
      }
    } else {
      const item = [
        {
          text: row[0].week,
          style: "defaultRow",
          border: [false, true, false, true],
        },
        { text: "", style: "defaultRow", border: [false, true, false, true] },
        { text: "", style: "defaultRow", border: [false, true, false, true] },
        { text: "", style: "defaultRow", border: [false, true, false, true] },
        { text: "", style: "defaultRow", border: [false, true, false, true] },
      ];
      tableRows.push(item);
    }
  });
  return tableRows;
};

export default (
  from,
  to,
  data_items,
  sequences_list,
  school,
  user,
  classes,
  subject,
  current_schoolyear,
) => {
  let startDate = "";
  if (school.calendarMap[from.value]) {
    startDate = DateTime.fromISO(
      school.calendarMap[from.value].calc_date,
    ).toFormat("dd. MMMM");
  }

  let endDate = "";
  if (school.calendarMap[to.value]) {
    endDate = DateTime.fromISO(school.calendarMap[to.value].calc_date)
      .set({ weekday: 5 })
      .toFormat("dd. MMMM yyyy");
  }

  const text_zeitraum = `${startDate} bis ${endDate}`;
  const tableHeaders = [
    { text: "Woche", style: "tableHeader", border: [false, true, false, true] },
    {
      text: "Sequenz",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Themen",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Lehrplanbezug",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Medien und Material",
      style: "tableHeader",
      border: [false, true, false, true],
    },
  ];

  const tableWidths = [80, 120, 130, "*", "*"];

  const cal_list = [];
  for (let i = from.value; i <= to.value; i += 1) {
    cal_list.push(school.calendarMap[i]);
  }
  const seq_formated = _prepareData(cal_list, data_items, sequences_list);

  const pdf_title = `Trimesterplan ${classes.label} ${subject.label} ${text_zeitraum}`;

  const documentDefinition = {
    footer(page, pages) {
      return {
        margin: [40, 0, 40, 0],
        columns: [
          { bold: true, fontSize: 8, text: "" },
          {
            alignment: "right",
            text: [
              { text: "Seite ", bold: true },
              { text: page.toString() },
              " von ",
              { text: pages.toString() },
            ],
            fontSize: 8,
          },
        ],
      };
    },
    header: {
      margin: 40,
      columns: [
        {
          width: "*",
          text: [
            { text: `${school.school}\n`, fontSize: 8, bold: true },
            {
              text: `${school.street} ${school.nr}\n`,
              fontSize: 8,
              bold: false,
            },
            {
              text: `${school.zip} ${school.location}`,
              fontSize: 8,
              bold: false,
            },
          ],
          style: "header_left",
        },
        {
          width: "*",
          text: [
            { text: "Trimesterplan\n", fontSize: 18, bold: true },
            { text: classes.label, fontSize: 8, bold: false },
            { text: ` ${subject.label} `, fontSize: 8, bold: true },
            {
              text: ` Schuljahr ${current_schoolyear.label}\n`,
              fontSize: 8,
              bold: false,
            },
            { text: `${text_zeitraum}\n\n `, fontSize: 8, bold: true },
          ],
          alignment: "center",
          style: "header_center",
        },
        {
          width: "*",
          text: [
            { text: "Lehrkraft: ", fontSize: 8, bold: false },
            { text: user.name, fontSize: 8, bold: true },
          ],
          alignment: "right",
          style: "header_right",
        },
      ],
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    content: [
      {
        style: "tableFull",
        table: {
          widths: tableWidths,
          heights(row) {
            if (row === 0) {
              return 25;
            }
            return "*";
          },
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          body: [[...tableHeaders], ...seq_formated],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          // paddingLeft: function(i, node) { return 4; },
          // paddingRight: function(i, node) { return 4; },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      defaultRow: {
        fontSize: 8,
        margin: [0, 5, 0, 5],
      },
      tableFull: {},
      tableHeader: {
        bold: false,
        fontSize: 11,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      rooText: {
        margin: [10, 10, 10, 15],
      },
      textblock: {
        margin: [0, 5, 0, 5],
      },
      numberfirst: {
        margin: [0, 25, 0, 0],
      },
      footer_left: {
        margin: [40, 0, 0, 0],
        fontSize: 8,
        bold: false,
      },

      header_left: {
        // margin: [0, 20, 0, 40],
      },
      header_center: {
        // margin: [40, 40, 40, 40],
      },
      header_right: {
        // margin: [0, 20, 40, 40],
      },
    },
    pageMargins: [40, 90, 40, 60],
  };
  let str = pdf_title;
  str = str.replace(/\s/g, "_");
  str = str.replace(",", "_");
  str = str.replace(".", "_");
  str += ".pdf";
  window.pdfMake.createPdf(documentDefinition, null, fonts).download(str);
};
