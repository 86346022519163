import { type DateTime, type DurationUnit } from "luxon";
// create an array of dates from start till end date
function dateRange(
  fromDateTime: DateTime,
  toDateTime: DateTime,
  unit: DurationUnit = "days",
) {
  const length = Math.round(toDateTime.diff(fromDateTime).as(unit) + 1);
  return Array.from({ length }, (_, i) => fromDateTime.plus({ [unit]: i }));
}

export { dateRange };
