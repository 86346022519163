import * as ACTIONS from "./actions";

const test = {
  full: [],
  filtered: [],
  lastfilter: 0,
  raw: [], // list for new added without class,
  isFetched: false,
};
export function subjects(state = test, action) {
  // console.log('Reducer subjects, ' + action.type);
  switch (action.type) {
    /**
     * add subjectlist stuff
     */
    case ACTIONS.ADD_SUBJECT_LIST: {
      const concat = state.raw.concat(action.subject);
      return { ...state, raw: concat };
    }

    /**
     * update an exists with title and color
     */
    case ACTIONS.UPDATE_SUBJECTS_LIST: {
      const newSubjects = state.raw.map((s) =>
        s._id === action.subject._id
          ? {
              ...s,
              title: action.subject.title,
              color: action.subject.color,
            }
          : s,
      );
      return { ...state, raw: newSubjects };
    }

    case ACTIONS.REMOVE_SUBJECT_LIST: {
      const new_list = state.raw.filter((p) => p._id !== action.index);
      return { ...state, raw: new_list };
    }

    case ACTIONS.FETCH_SUBJECTS_LIST: {
      const new_state = { ...state, raw: action.subjects, isFetched: true };
      return new_state;
    }

    case ACTIONS.ADD_SUBJECT_FAILED:
      return state;

    default:
      return state;
  }
}
