import React from "react";
import { useRemoveLoadingSpinner } from "../../common/utils/hooks";

const fontSizes = [
  "text-3xs",
  "text-2xs",
  "text-xs",
  "text-sm",
  "text-base",
  "text-lg",
  "text-xl",
  "text-2xl",
  "text-3xl",
  "text-4xl",
  "text-5xl",
  "text-6xl",
  "text-7xl",
  "text-8xl",
  "text-9xl",
];

const spacings = [
  "w-0",
  "w-0.5",
  "w-1",
  "w-1.5",
  "w-2",
  "w-2.5",
  "w-3",
  "w-3.5",
  "w-4",
  "w-4.5",
  "w-5",
  "w-6",
  "w-7",
  "w-8",
  "w-9",
  "w-10",
  "w-11",
  "w-12",
  "w-13",
  "w-14",
  "w-15",
  "w-16",
  "w-17",
  "w-20",
  "w-24",
  "w-28",
  "w-32",
  "w-36",
  "w-40",
  "w-44",
  "w-48",
  "w-52",
  "w-56",
  "w-60",
  "w-64",
  "w-72",
  "w-80",
  "w-96",
];

const grays = [
  "bg-gray-10",
  "bg-gray-20",
  "bg-gray-25",
  "bg-gray-30",
  "bg-gray-40",
  "bg-gray-50",
  "bg-gray-60",
  "bg-gray-70",
  "bg-gray-80",
  "bg-gray-90",
  "bg-gray-100",
];

const colors = [
  "bg-black",
  "bg-green",
  "bg-green-dark",
  "bg-green-faint",
  "bg-red",
  "bg-red-dark",
  "bg-transparent",
  "bg-current",
];

function Principles() {
  return (
    <div>
      <section>
        <h2 className="mt-16 mb-4 text-4xl font-bold">Schriftgrößen</h2>
        <ul>
          {fontSizes.map((fontSizeClass) => (
            <li key={fontSizeClass} className={fontSizeClass}>
              {fontSizeClass}
            </li>
          ))}
          <li />
        </ul>
      </section>
      <section>
        <h2 className="mt-16 mb-4 text-4xl font-bold">Spacer</h2>
        <ul className="space-y-2">
          {spacings.map((spacingClass) => (
            <li key={spacingClass} className="flex items-center">
              <div className="w-8">{spacingClass.split("-")[1]}</div>
              <div
                className={`${spacingClass} h-4 bg-[rgb(252,165,165)] rounded`}
              />
            </li>
          ))}
          <li />
        </ul>
      </section>
      <section>
        <h2 className="mt-16 mb-4 text-4xl font-bold">Gray</h2>
        <ul className="flex space-x-2">
          {grays.map((grayClass, index) => {
            const textClass =
              index < grays.length / 2 ? "text-gray-90" : "text-gray-20";
            return (
              <li
                key={grayClass}
                className={`rounded-full border-2 border-gray-100 w-20 h-20 ${textClass} ${grayClass} flex items-center text-center justify-center font-bold`}
              >
                {grayClass.replace("bg-", "")}
              </li>
            );
          })}
          <li />
        </ul>
      </section>
      <section>
        <h2 className="mt-16 mb-4 text-4xl font-bold">Colors</h2>
        <ul className="flex space-x-2">
          {colors.map((colorClass) => {
            const textClass =
              colorClass !== "bg-transparent" ? "text-gray-80" : "text-gray-20";
            return (
              <li
                key={colorClass}
                className={`rounded-full p-1 w-20 h-20 ${colorClass} flex items-center text-center justify-center font-bold`}
              >
                <span className={textClass}>
                  {colorClass.replace("bg-", "")}
                </span>
              </li>
            );
          })}
          <li />
        </ul>
      </section>
    </div>
  );
}

export function Component() {
  useRemoveLoadingSpinner();

  return (
    <div className="tw-pf">
      <main className="p-8">
        <h1 className="text-6xl font-bold">Design System</h1>
        <section className="flex mt-8 text-lg text-center space-x-4">
          <div className="p-2 font-bold text-gray-20 bg-[rgb(187,247,208)] rounded shadow w-40">
            Freigeist
          </div>
          <div className="px-3 py-2 font-bold text-white bg-[rgb(240,253,250)] rounded shadow">
            ❤️
          </div>
          <div className="p-2 font-bold text-gray-20 bg-[rgb(233,213,255)] rounded shadow w-40">
            Tailwind
          </div>
        </section>
        <Principles />
      </main>
    </div>
  );
}
