import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import { uniqueId } from "../../common/components/sequence_calendar/Utils";

export const USE_SATURDAY = "USE_SATURDAY";
export const HANDLE_ENTRIES = "HANDLE_ENTRIES";
export const CLEAR_ALL = "CLEAR_ALL";

export const UPSERT_TIMETABLE = "UPSERT_TIMETABLE";
const UPSERT_TIMETABLE_ERROR = "UPSERT_TIMETABLE_ERROR";

export const FETCH_TIMETABLE = "FETCH_TIMETABLE";
const FETCH_TIMETABLE_ERROR = "FETCH_TIMETABLE_ERROR";

/**
 * insert or upsert timetable
 * @param tt
 * @param year
 * @return {function(*=)}
 */
export function upsertTimetable(tt, year, from_state = true) {
  return (dispatch, getState) => {
    const updatedTimetable = {
      // Marko: update with data from store directly...
      // bad or genial - i dont no.... go to the hell
      ...(from_state ? getState().timetable : tt),
      user_id: Auth.getUserId(),
      schoolyear: year,
      ttId: uniqueId(),
    };

    return fetch(URLS.API_TIMETABLE_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(updatedTimetable),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data);
          dispatch({
            type: UPSERT_TIMETABLE,
            timetable: data.timetable,
          });
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch({
            type: UPSERT_TIMETABLE_ERROR,
            timetable: "",
            message: error,
          });
        });
      }
    });
  };
}

/**
 * fetch timetable from db
 * @param user_id
 * @return {{type: string, item: *}}
 */
export function fetchTimetable(user_id, year) {
  // console.log('call fetch timetables for user_id: ' + user_id);
  return (dispatch) =>
    fetch(`${URLS.API_TIMETABLE_URL}/${user_id}/${year}`, {
      method: "get",
      headers: { Authorization: `bearer ${Auth.getToken()}` },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data);
          dispatch({
            type: FETCH_TIMETABLE,
            timetable: data.timetable,
          });
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch({
            type: FETCH_TIMETABLE_ERROR,
            timetable: "",
            message: error,
          });
        });
      }
    });
}

export function handleEntries(item, ab_weeks) {
  return {
    type: HANDLE_ENTRIES,
    item,
    ab_weeks,
  };
}

export function clearAll() {
  return {
    type: CLEAR_ALL,
  };
}
