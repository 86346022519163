import React, { useState } from "react";
import Dropdown, { type Option } from "react-dropdown";
import type { SequenceTemplate } from "../../../features/templates/types";
import FilterTagList from "./FilterTagList";
import type { Slot } from "../../../features/sequence/types";
import { useGetSequenceTemplatesQuery } from "../../../services/api";
import { selectSequenceTemplatesResultUniqueLists } from "../../../features/templates/templates-slice";

type Props = {
  useTemplate: (template: SequenceTemplate) => void;
};

type State = {
  grade_filter: string;
  subject_filter: string;
  tags_filter: string[];
};

const initState = {
  grade_filter: "",
  subject_filter: "",
  tags_filter: [],
};

function UseTemplateComponent(props: Props) {
  const seqTemplatesList = useGetSequenceTemplatesQuery(undefined, {
    selectFromResult: selectSequenceTemplatesResultUniqueLists,
  });
  const [state, setState] = useState<State>(initState);

  const subjectlist = [
    { label: "Alle Fächer", value: "" },
    ...seqTemplatesList.subjects,
  ];

  const gradelist = [
    { label: "Alle Jahrgangsstufen", value: "" },
    ...seqTemplatesList.grades,
  ];

  const { subject_filter, tags_filter, grade_filter } = state;
  return (
    <div className="milti-wrapper">
      <div className="template-wrapper template-modal create">
        <h1>Vorlage wählen</h1>
        <div className="class_grade">
          <div className="dropdown-wrapper">
            <div className="row">
              <div className="field-group dropdowns">
                <div className="col">
                  <label>Klasse</label>
                  <Dropdown
                    options={subjectlist}
                    onChange={(option: Option) => {
                      setState({ ...state, subject_filter: option.value });
                    }}
                    placeholder="Klasse wählen"
                  />
                </div>
                <div className="col col-last">
                  <label>Jahrgangsstufe</label>
                  <Dropdown
                    options={gradelist}
                    onChange={(option: Option) => {
                      setState({ ...state, grade_filter: option.value });
                    }}
                    placeholder="Jahrgangsstufe wählen"
                  />
                </div>
                <div style={{ clear: "both" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="tags_filter">
          <FilterTagList
            items={seqTemplatesList.tags}
            filterTags={(tags: string[]) => {
              setState({ ...state, tags_filter: tags });
            }}
          />
        </div>
      </div>
      <div className="template-wrapper template-modal create overflow">
        <div className="itemlist overflow-list">
          <div className="template-list-wrapper use overflow">
            {seqTemplatesList.list.map((item: SequenceTemplate) => {
              let count_unit = 0;
              if (item.sequences_list) {
                if (
                  item.sequences_list.timeslots &&
                  item.sequences_list.timeslots.length > 0
                ) {
                  item.sequences_list.timeslots.forEach((element: Slot) => {
                    if (element.title) {
                      count_unit += 1;
                    } else if (
                      element.didakt !== "<p><br></p>" ||
                      element.didakt
                    ) {
                      count_unit += 1;
                    } else if (
                      element.homework !== "<p><br></p>" ||
                      element.homework
                    ) {
                      count_unit += 1;
                    } else if (element.lehrplanbezug.length > 0) {
                      count_unit += 1;
                    }
                  });
                }
              }

              let show_item = true;
              let filter_class_css = "item";
              if (
                subject_filter &&
                subject_filter !== item.selected_subject.value
              ) {
                filter_class_css = "item hidden";
                show_item = false;
              }

              if (grade_filter && grade_filter !== item.selected_grade.value) {
                filter_class_css = "item hidden";
                show_item = false;
              }

              if (show_item && tags_filter.length > 0) {
                // tagsflter is set
                filter_class_css = "item hidden";
                tags_filter.forEach((ft) => {
                  if (item.tags.indexOf(ft) > -1) {
                    filter_class_css = "item";
                  }
                });
              }

              return (
                <div className={filter_class_css} key={item._id}>
                  <div className="item-wrapper">
                    <div className="left">
                      <div className="title">
                        <a
                          onClick={() => {
                            props.useTemplate(item);
                          }}
                        >
                          {item.title}
                        </a>
                      </div>
                      <div className="sub-title">
                        {count_unit} Unterrichtseinheiten{" "}
                        <span className="small">
                          | {item.sequence_cal.weeks} Wochen bei Anwendung
                        </span>
                      </div>
                      <div className="tags-wrapper">
                        <span className="info-tag tag">
                          {item.selected_subject.label}
                        </span>
                        <span className="info-tag tag">
                          Jahrgangsstufe {item.selected_grade.label}
                        </span>

                        {item.tags.map((tag: string) => (
                          <span key={`tag-${tag}`} className="tag readonly">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        props.useTemplate(item);
                      }}
                      className="right"
                    >
                      <div className="arrow">&rsaquo;</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseTemplateComponent;
