import React from "react";
import TimeTableLabelItem from "./TimeTableLabelItem";

function renderItems(props) {
  let { cnt } = props;
  if (!cnt) cnt = 7;

  const hour_items = [];
  for (let i = 0; i <= cnt; i += 1) {
    const nr = i + 1;
    hour_items.push(<TimeTableLabelItem idx={nr} key={`ttl-item-${i}`} />);
  }
  return hour_items;
}

function TimeTableColumnLabel(props) {
  return (
    <div key="label-column-1" className="timetable-column label-column">
      <div className="labels">{renderItems(props)}</div>
    </div>
  );
}
export default TimeTableColumnLabel;
