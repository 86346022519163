import React from "react";
import { Link } from "react-router-dom";
import parseHtmlToReact from "html-react-parser";

const buildLink = (linktarget, linkname) => (
  <Link to={linktarget}>{linkname}</Link>
);

export default function ScreenNote(props) {
  const { type, message, linktarget, linkname } = props;
  let fade_css = "fade-in";
  let info_icon = "success_circle.png";

  if (type === "success_in") {
    fade_css = "success fade-only-in";
  }

  if (type === "error") {
    fade_css = "fade-in-error";
    info_icon = "error_circle.png";
  }

  return (
    <div
      className={`screen_note ${fade_css} ${type}`}
      onAnimationEnd={() => {
        if (props.onAnimationEndClose) {
          props.closeCallback();
        }
      }}
    >
      <div className="note-wrapper">
        <div className="left-controll">
          <img src={`/assets/images/${info_icon}`} />
        </div>
        <div className="content">
          <div className="text">
            {parseHtmlToReact(message, {
              replace: (domNode) => {
                if (domNode.name === "a") {
                  return buildLink(linktarget, linkname);
                }
                return undefined;
              },
            })}
          </div>
          {props.undo && (
            <div className="undo_container">
              <a onClick={() => props.functionCallbackUndo()}>
                Rückgängig machen
              </a>
            </div>
          )}
        </div>
        <div className="right-controll" onClick={() => props.closeCallback()}>
          <img src="/assets/images/white-cross.png" />
        </div>
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
}
