type Block = { start: number; end: number };
type PlannedFn<T> = (item: T) => boolean;

function hasSpaceToMoveRight<T>(
  targetBlock: Block,
  isPlanned: PlannedFn<T>,
  items: Array<T>,
) {
  const itemToTheRight = items[targetBlock.end + 1];
  return (
    targetBlock.end + 1 < items.length &&
    itemToTheRight &&
    !isPlanned(itemToTheRight)
  );
}

function createBlocks<T>(isPlanned: PlannedFn<T>, items: Array<T>): Block[] {
  // this part could be replaced by Object.groupBy in the future
  return items.reduce<Block[]>((acc, item, index) => {
    const prevBlock = acc.at(-1);
    const endOfPrevBlock = prevBlock ? prevBlock.end : -1;

    // do nothing if item is unplanned
    if (!isPlanned(item)) {
      return acc;
    }

    // do nothing if current index is still within previous block
    if (endOfPrevBlock >= index) {
      return acc;
    }

    // otherwise find first unplanned item after current position
    const start = index;
    const indexOfFirstUnplanned = items.findIndex(
      (searchItem, idx) =>
        // const nextItem = items[idx + 1];
        idx > start && !isPlanned(searchItem),
    );
    const end =
      indexOfFirstUnplanned >= 0 ? indexOfFirstUnplanned - 1 : items.length - 1;

    // store the start and end index of block
    return [...acc, { start, end }];
  }, []);
}

function findSurroundingBlock(index: number, blocks: Block[]) {
  return blocks.find((block) => block.start <= index && index <= block.end);
}

export function moveItems<T>({
  source,
  target,
  items,
  isPlanned,
}: {
  source: number;
  target: number;
  items: Array<T>;
  isPlanned: PlannedFn<T>;
}) {
  // this part could be replaced by Object.groupBy in the future
  const blocks = createBlocks<T>(isPlanned, items);

  const sourceItem = items[source];
  if (!sourceItem) {
    return items;
  }

  const sourceBlock = findSurroundingBlock(source, blocks);
  const targetBlock = findSurroundingBlock(target, blocks);
  const withinSameBlock =
    sourceBlock && targetBlock && sourceBlock === targetBlock;

  // CASE 1: item to same block: remove and reinsert at target
  if (withinSameBlock) {
    return items.toSpliced(source, 1).toSpliced(target, 0, sourceItem);
  }

  // moved out of its block

  // Note the ! (non-null assertion) at the end of the expression
  // It tells the TypeScript compiler that this expression cannot be null
  // normally this should be avoided but here we can use it because we know there has to be an empty item in this case
  const emptyItem = items.find((item) => !isPlanned(item))!;

  // CASE 2: move empty slot somewhere
  if (!sourceBlock) {
    // to other emoty slot -> do nothing
    if (!targetBlock) {
      return items;
    }

    if (!hasSpaceToMoveRight(targetBlock, isPlanned, items)) {
      // to planned block (has space to right)
      //   -> take one out from the end of the block to preserve block starts
      return items
        .toSpliced(target + 1, 0, emptyItem)
        .toSpliced(targetBlock.start - 1, 1);
    }
    // to planned block (has space to right)
    //   -> take one out from the end of the block to preserve block starts
    return items
      .toSpliced(targetBlock.end + 1, 1)
      .toSpliced(target, 0, emptyItem);
  }

  // CASE 3: item to empty slot: take out, insert at target replacing target
  if (!targetBlock) {
    return (
      items
        // remove source item, insert empty item at end of block
        .toSpliced(source, 1)
        .toSpliced(sourceBlock.end, 0, emptyItem)
        // replace target item with source item
        .toSpliced(target, 1, sourceItem)
    );
  }

  // CASE 4: item to different block (with or without space): take out, insert empty

  // there is an empty item to the right of the target block

  if (hasSpaceToMoveRight(targetBlock, isPlanned, items)) {
    return (
      items
        // remove source item, insert empty item
        .toSpliced(source, 1)
        .toSpliced(sourceBlock.end, 0, emptyItem)
        // remove empty item, insert source item
        .toSpliced(targetBlock.end + 1, 1)
        .toSpliced(target, 0, sourceItem)
    );
  }
  // without space to the right
  return (
    items
      // remove source item
      .toSpliced(source, 1)
      // insert source item at target
      .toSpliced(target, 0, sourceItem)
  );
}
