/* inspired by
 https://github.com/bpampuch/pdfmake/issues/205
 http://jsfiddle.net/mychn9bo/4/
 */

function CreateParagraph() {
  return { text: [] };
}

function ParseContainer(cnt, e, p, styles) {
  const elements = [];
  const children = e.childNodes;
  Array.from(children).forEach((child) => {
    // function are mutually recursive so we need to disable eslint here
    // eslint-disable-next-line no-use-before-define
    ParseElement(elements, child, p, styles);
  });
  elements.forEach((el) => {
    cnt.push(el);
  });
}

function ComputeStyle(o, styles) {
  const styleEntries = styles.map((style) => {
    const [styleName, styleValue] = style
      .toLowerCase()
      .split(":")
      .map((item) => item.trim());
    if (styleValue) {
      switch (styleName) {
        case "font-size": {
          return ["fontSize", parseInt(styleValue)];
        }
        case "text-align":
          if (styleValue === "right") {
            return ["alignment", "right"];
          }
          if (styleValue === "center") {
            return ["alignment", "center"];
          }
          break;
        case "font-weight":
          if (styleValue === "bold") {
            return ["bold", true];
          }
          break;

        case "css-class": {
          return ["style", styleValue];
        }

        case "text-decoration":
          if (styleValue === "underline") {
            return ["decoration", "underline"];
          }
          break;
        case "font-style":
          if (styleValue === "italic") {
            return ["italics", true];
          }
          break;
        default:
          return ["NULL", null];
      }
    }
    return ["NULL", null];
  });
  const newStyleObject = Object.fromEntries(
    styleEntries.filter(([key]) => key !== "NULL"),
  );
  return { ...o, ...newStyleObject };
}

function ParseElement(cnt, e, p, styles) {
  if (e.getAttribute) {
    const nodeStyle = e.getAttribute("style");
    if (nodeStyle) {
      nodeStyle.split(";").forEach((style) => {
        styles.push(style);
      });
    }
  }

  switch (e.nodeName.toLowerCase()) {
    case "#text": {
      const t = { text: e.textContent.replace(/\n/g, "") };
      const tWithStyles = ComputeStyle(t, styles);
      p.text.push(tWithStyles);
      break;
    }
    case "b":
    case "strong":
      ParseContainer(cnt, e, p, styles.concat(["font-weight:bold"]));
      break;
    case "u":
      ParseContainer(cnt, e, p, styles.concat(["text-decoration:underline"]));
      break;
    case "i":
    case "em":
      ParseContainer(cnt, e, p, styles.concat(["font-style:italic"]));
      break;
    case "span":
      ParseContainer(cnt, e, p, styles);
      break;
    case "br": {
      const newP = CreateParagraph();
      newP.margin = [0, 5, 0, 0];
      cnt.push(newP);
      break;
    }
    case "table": {
      const t = {
        table: {
          widths: [],
          body: [],
        },
      };
      const border = e.getAttribute("pdf-border");
      const layouts = ["noBorders", "headerLineOnly", "lightHorizontalLines"];
      if (layouts.indexOf(border) > -1) {
        t.layout = border;
      }
      ParseContainer(t.table.body, e, p, styles);

      const widths = e.getAttribute("pdf-widths");
      if (!widths) {
        if (t.table.body.length !== 0) {
          t.table.body[0].forEach(() => {
            t.table.widths.push("*");
          });
        }
      } else {
        const w = widths.split(/[\s,]+/);
        w.forEach((width) => {
          t.table.widths.push(width);
        });
      }
      cnt.push(t);
      break;
    }
    case "tbody":
      ParseContainer(cnt, e, p, styles);
      break;
    case "tr": {
      const row = [];
      ParseContainer(row, e, p, styles);
      cnt.push(row);
      break;
    }
    case "td": {
      const newP = CreateParagraph();
      const st = { stack: [] };
      st.stack.push(newP);

      const rspan = e.getAttribute("rowspan");
      if (rspan) {
        st.rowSpan = parseInt(rspan);
      }
      const cspan = e.getAttribute("colspan");
      if (cspan) {
        st.colSpan = parseInt(cspan);
      }

      ParseContainer(st.stack, e, newP, styles);
      cnt.push(st);
      break;
    }
    case "div":
    case "li":
    case "p": {
      const newP = CreateParagraph();
      // let st = {stack: [], margin: [0, 5, 0, 0]};
      const st = { stack: [] };
      st.stack.push(newP);
      const stWithStyles = ComputeStyle(st, styles);
      ParseContainer(stWithStyles.stack, e, newP, styles);
      cnt.push(stWithStyles);
      break;
    }

    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6": {
      const newP = CreateParagraph();
      const st = { stack: [] };
      st.stack.push(newP);
      const stWithStyles = ComputeStyle(st, styles);
      ParseContainer(
        stWithStyles.stack,
        e,
        newP,
        styles.concat(["css-class:headline"]),
      );
      cnt.push(stWithStyles);
      break;
    }

    case "ul": {
      const ul = { ul: [] };
      ParseContainer(ul.ul, e, p, styles);
      cnt.push(ul);
      break;
    }
    case "ol": {
      const ul = { ol: [] };
      ParseContainer(ul.ol, e, p, styles);
      cnt.push(ul);
      break;
    }
    default:
      // console.log('#html2pdfmake', 'Parsing for node ' + e.nodeName + ' not found');
      break;
  }
  return p;
}

function ParseHtml(cnt, htmlText) {
  const p = CreateParagraph();
  const htmlParser = new DOMParser();
  const doc = htmlParser.parseFromString(htmlText ?? "", "text/html");
  const styles = [];
  Array.from(doc.body.childNodes).forEach((node) => {
    if (node.nodeName.toLowerCase() === "#text") {
      const newP = document.createElement("p");
      node.replaceWith(newP);
      newP.appendChild(node);
      ParseElement(cnt, newP, p, styles);
      return;
    }
    ParseElement(cnt, node, p, styles);
  });
  return cnt;
}

export function html2pdfmake(html) {
  return ParseHtml([], html);
}
