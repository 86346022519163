/**
 * Created by makkuz on 06.02.18.
 */

import React from "react";
import CheckBox from "./CheckBox";

class ClassItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      subjects: this.props.subjects,
      _id: this.props.id,
      edit_mode: false,
      show_note: false,
      wait_data: "",
      newTimeTableData: [],
      subject_title: "",
      show_note_class: false,
    };

    this.renderListForm = this.renderListForm.bind(this);
    this.renderEditForm = this.renderEditForm.bind(this);
    this.editClass = this.editClass.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.setClassesTitle = this.setClassesTitle.bind(this);
    this.addSubjectToClass = this.addSubjectToClass.bind(this);
    this.updateClassroom = this.updateClassroom.bind(this);
    this.acceptDeleteNote = this.acceptDeleteNote.bind(this);
    this.showNote = this.showNote.bind(this);
    this.addSubjectToClassAfterAccept =
      this.addSubjectToClassAfterAccept.bind(this);
    this.deleteClass = this.deleteClass.bind(this);
    this._formDeleteClass = this._formDeleteClass.bind(this);
    this.acceptDeleteNoteClass = this.acceptDeleteNoteClass.bind(this);
    this.cancelDeleteClass = this.cancelDeleteClass.bind(this);
  }

  setClassesTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  addSubjectToClassAfterAccept(type, value, subject_title) {
    this.setState({
      wait_data: {
        type,
        value,
      },
      show_note: true,
      subject_title,
    });
  }

  addSubjectToClass(type, subjectValues, timetableDelete = false) {
    // console.log('add or remove subjects');
    // console.log(this.state);

    // console.log('timetable before make some stuff');
    // console.log(this.props.timetable);

    let { subjects } = this.state;
    let tt_list = this.state.newTimeTableData;

    const value = JSON.parse(subjectValues);

    if (type === "add") {
      subjects.push(value);
    } else {
      // delete stuff
      if (timetableDelete) {
        if (tt_list.length === 0) {
          tt_list = { ...this.props.timetable };
        }
        const search_id = this.props.id + value._id;
        const tmp_list = [];
        tt_list.entries.forEach((element) => {
          const check_id = element.class._id + element.class.subject._id;

          if (check_id !== search_id) {
            tmp_list.push(element);
          }
        });

        tt_list.entries = tmp_list;
        // console.log('delete classes after: ');
        // console.log(tt_list);
      }

      const list = subjects.filter((p) => p._id !== value._id);
      subjects = list;
    }
    this.setState({
      subjects,
      clearChecked: false,
      newTimeTableData: tt_list,
    });
  }

  /**
   * update classroom and or create an new timetable
   */
  updateClassroom(e) {
    e.preventDefault();

    // var classes = this.state.classes;
    const class_obj = {
      title: this.state.title,
      subjects: this.state.subjects,
      _id: this.state._id,
    };
    this.props.updateClassList(class_obj);
    // this.props.updateTimeTable();
    // reset for next class object
    this.setState({
      edit_mode: false,
      newTimeTableData: [],
    });
  }

  editClass(e) {
    e.preventDefault();
    this.setState({
      edit_mode: true,
    });
  }

  _formDeleteClass(e, forceDelete = false) {
    e.preventDefault();
    this.deleteClass(forceDelete);
  }

  deleteClass(forceDelete = false) {
    if (forceDelete) {
      this.props.removeFromList(this.props.id);
      this.setState({
        show_note_class: false,
      });
    } else {
      let cnt = 0;
      this.props.item.subjects.forEach((classes) => {
        if (classes.schooldays.length > 0) {
          cnt += 1;
        }
      });
      if (cnt === 0) {
        this.props.removeFromList(this.props.id);
      } else {
        // console.log('lösch bestätigung anzeigen');
        this.setState({
          show_note_class: true,
        });
      }
    }
  }

  acceptDeleteNoteClass(e) {
    e.preventDefault();
    this.deleteClass(true);
  }

  cancelEdit(e) {
    e.preventDefault();
    this.setState({
      title: this.props.title,
      subjects: this.props.subjects,
      edit_mode: false,
    });
  }

  cancelDeleteClass(e) {
    e.preventDefault();
    this.setState({
      show_note_class: false,
    });
  }

  showNote() {
    this.setState({
      show_note: true,
    });
  }

  /**
   * accept data delete
   */
  acceptDeleteNote(e) {
    e.preventDefault();
    this.setState({
      show_note: false,
      wait_data: "",
      subject_title: "",
    });
    this.addSubjectToClass(
      this.state.wait_data.type,
      this.state.wait_data.value,
      true,
    );
  }

  renderListForm() {
    return (
      <div className="wrapper-classes-item">
        <div className="classes_item">
          <span className="title">{this.props.title}</span>
          <span className="subjects">
            {this.props.subjects.map((subject, idx) => (
              <span key={idx} className="text-item">
                {subject.title}
                <span className="comma">, </span>
              </span>
            ))}
          </span>
          <span className="actions">
            <a
              href="#"
              onClick={(e) => {
                this.editClass(e);
              }}
            >
              <img
                src="/assets/images/edit-icon.png"
                alt="edit class"
                className="form-icon"
              />
            </a>
            &nbsp;
            <a
              href="#"
              onClick={(e) => {
                this._formDeleteClass(e, false);
              }}
            >
              <img
                src="/assets/images/delete-icon.png"
                alt="delete class"
                className="form-icon"
              />
            </a>
          </span>
          {this.state.show_note_class && (
            <div className="message-box notice">
              <div className="text">
                Du hast bereits Stunden für die{" "}
                <strong>{this.props.title} </strong> geplant. Bist du sicher,
                dass du die <strong>{this.props.title}</strong> löschen
                möchtest? Deine Daten gehen dadurch verloren.
                <br />
                <br />
                <button
                  className="btn btn-dark"
                  onClick={(e) => {
                    this.acceptDeleteNoteClass(e);
                  }}
                >
                  ja, bitte die Daten löschen
                </button>
                &nbsp; &nbsp; &nbsp;
                <button
                  className="btn btn-dark"
                  onClick={(e) => {
                    this.cancelDeleteClass(e);
                  }}
                >
                  Abbrechen
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderEditForm() {
    const enabled = true;
    return (
      <div className="classes_item_container inside">
        <div className="fields">
          <div className="field_input">
            <label>Klasse</label>
            <input
              placeholder="z.B. Klasse 6b"
              className="inputfield"
              value={this.state.title}
              onChange={(e) => {
                this.setClassesTitle(e);
              }}
            />
          </div>
        </div>
        <div className="subjects">
          <label>
            Fächer{" "}
            <span className="info">
              (die du in dieser Klasse unterrichtest)
            </span>
          </label>
          <div className="subject_items">
            {this.props.subject_list.map((item, idx) => {
              const key = `select-${idx}`;
              let defaultChecked = false;
              let data = item;
              let has_planed_hours = false;

              // check if item with value exists, check as default and pass as value
              this.state.subjects.forEach((subs) => {
                if (!defaultChecked && subs._id === item._id) {
                  defaultChecked = true;
                  data = subs;
                  has_planed_hours = subs.schooldays.length > 0;
                }
              });

              return (
                <CheckBox
                  key={idx}
                  cssid={key}
                  item={data}
                  addSubjectToClass={this.addSubjectToClass}
                  value={data}
                  clearChecked={this.state.clearChecked}
                  defaultChecked={defaultChecked}
                  planed_hours={has_planed_hours}
                  addSubjectToClassAfterAccept={
                    this.addSubjectToClassAfterAccept
                  }
                />
              );
            })}
          </div>
        </div>

        {this.state.show_note && (
          <div className="message-box notice">
            <div className="text">
              Du hast bereits Stunden für{" "}
              <strong>
                {this.props.title} | {this.state.subject_title}
              </strong>{" "}
              geplant. Bist du sicher, dass du{" "}
              <strong>{this.state.subject_title}</strong> nicht mehr in dieser
              Klasse unterrichten möchtest? Deine Daten gehen dadurch verloren.
              <br />
              <br />
              <button
                className="btn btn-dark"
                onClick={(e) => {
                  this.acceptDeleteNote(e);
                }}
              >
                ja, bitte die Daten löschen
              </button>
            </div>
          </div>
        )}

        <div className="btn_wrapper">
          <button
            disabled={!enabled}
            className="btn btn-dark"
            onClick={(e) => {
              this.updateClassroom(e);
            }}
          >
            Speichern
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            className="btn btn-dark"
            onClick={(e) => {
              this.cancelEdit(e);
            }}
          >
            Abbrechen
          </button>
        </div>
      </div>
    );
  }

  render() {
    let form;
    if (this.state.edit_mode) {
      form = this.renderEditForm();
    } else {
      form = this.renderListForm();
    }
    return <div className="class_list_wrapper">{form}</div>;
  }
}
export default ClassItem;
