import { Interval, DateTime } from "luxon";
import { dateRange } from "../../utils/date";

export function getScoolHours(scooldays, obj, lessonDuration) {
  const daylist = [];
  scooldays.forEach((elem) => {
    daylist[elem.day] = elem.hour;
  });

  const startDate = DateTime.fromFormat(obj.calc_date, "yyyyMMdd");
  const endDate = DateTime.fromFormat(obj.calc_end_date, "yyyyMMdd");

  const sequenceDays = dateRange(startDate, endDate);

  const hours = sequenceDays.reduce((acc, d) => {
    const minutes = daylist[d.weekday];
    return acc + (minutes ? minutes / lessonDuration : 0);
  }, 0);

  return hours;
}

export function findDatesInMap(
  map,
  value,
  weeks = 1,
  holidays = [],
  scooldays = [],
  ab_settings_active = false,
  ab_settings,
  lessonDuration,
) {
  // Use param reassignment here, because TypeScript inferred the
  // type incorrectly as a boolean when it was a default parameter
  // TODO: refactor this when this codee gets ported to TypeScript
  // eslint-disable-next-line no-param-reassign
  ab_settings = false;
  const search = map.find(
    (element) => Number(element.pos_start) === Number(value),
  ) ?? { date: "00000000", calc_date: "00000000" };
  const weeklist = [];

  {
    // wochen ermitteln um die ferienzeiten raus zu rechnen.
    let wl_count = 1;
    map.forEach((e) => {
      if (e.pos_start >= value) {
        if (wl_count <= weeks) {
          if (!e.holiday) {
            weeklist.push(e);
          }
          wl_count += 1;
        }
      }
    });
  }

  // stunden berechnen
  const daylist = [];
  scooldays.forEach((elem) => {
    daylist[elem.day] = elem.hour;
  });

  const dateRangeStart = DateTime.fromISO(search.calc_date);
  const lastDate =
    Number(weeks) === 1
      ? DateTime.fromISO(search.calc_date)
      : DateTime.fromISO(search.calc_date).plus({ weeks: weeks - 1 });

  const lastDateEntryFromMap = map[map.length - 1];
  const lastDateFromMap = DateTime.fromFormat(
    lastDateEntryFromMap.end_date,
    "dd.MM.yyyy",
  );
  const isLastSchoolyearDate = lastDate.endOf("week") >= lastDateFromMap;

  const holidayRange = holidays.filter((elem) => {
    const start = DateTime.fromFormat(elem.start, "d-M-yyyy");
    return start >= dateRangeStart && start < lastDate.endOf("week");
  });

  const dayRange = dateRange(dateRangeStart, lastDate.endOf("week"));

  const hours = dayRange.reduce((acc, day) => {
    const minutes = daylist[day.weekday];

    if (minutes) {
      const current_date_kw = day.weekNumber;
      const current_date_dayname = day.weekdayLong;

      /*
      Marko: wir haben aktuell den Fall, wenn man AB Wochen in den Ferien deaktiviert und A und B  Wochen identisch sind
      (also keine awechselnde AB Woche) dann wird dieser Tag übersprungen. Wir prüfen also erstmal ob AB Woche identisch
      geplant sind, wenn ja überspringen wir die Prüfung ob AB Woche.
      Workaround mit Tobi besprochen.
      */
      const abWeekClones = ab_settings_active
        ? scooldays.filter((sd) => sd.day_name === current_date_dayname)
        : [];

      const hasAbWeekClones =
        abWeekClones &&
        abWeekClones.length === 2 &&
        abWeekClones[0].count === abWeekClones[1].count &&
        abWeekClones[0].hour === abWeekClones[1].hour;

      let ignore_calc = false;

      if (holidays.length > 0) {
        const b_compareDate = day;
        holidays.forEach((el) => {
          const b_startDate = DateTime.fromFormat(el.start, "d-M-yyyy").minus({
            days: 1,
          });
          const b_endDate = DateTime.fromFormat(el.end, "d-M-yyyy").plus({
            days: 1,
          });
          if (b_startDate < b_compareDate && b_compareDate < b_endDate) {
            ignore_calc = true;
          }
        });
      }
      if (!ignore_calc) {
        // pruefen ob ab wochen und entsprechend die stunden summieren.
        if (ab_settings_active) {
          let week_ab_settings = false;
          const search_ab_row = map.find(
            (element) => Number(element.kw) === current_date_kw,
          );

          if (ab_settings) {
            if (ab_settings.entries && ab_settings.entries.length > 0) {
              const ab_from_settings = ab_settings.entries.find(
                (element) => Number(element.kw) === current_date_kw,
              );
              if (
                ab_from_settings &&
                ab_from_settings.ab_week &&
                search_ab_row.ab_week !== ab_from_settings.ab_week
              ) {
                search_ab_row.ab_week = ab_from_settings.ab_week;
              }
            }
          }

          if (search_ab_row && search_ab_row.ab_week) {
            week_ab_settings = scooldays.find(
              (element) =>
                (element.week_type === search_ab_row.ab_week &&
                  element.day_name === current_date_dayname) ||
                (hasAbWeekClones && search_ab_row.ab_week === "-"),
            );

            if (week_ab_settings) {
              return acc + week_ab_settings.hour / lessonDuration;
            }
          }
        } else {
          return acc + minutes / lessonDuration;
        }
      }
    }
    return acc;
  }, 0);

  const calcEndDate = isLastSchoolyearDate
    ? lastDateFromMap.toFormat("yyyyMMdd")
    : lastDate.endOf("week").toFormat("yyyyMMdd");

  const lastDateRange = isLastSchoolyearDate
    ? `${lastDateFromMap.toFormat("dd.MMM")}`
    : `${lastDate.endOf("week").toFormat("dd.MMM")}`;

  return {
    date: search.date,
    date_range: `${dateRangeStart.toFormat("dd.MMM")} - ${lastDateRange}`,
    calc_end_date: calcEndDate,
    calc_date: dateRangeStart.toFormat("yyyyMMdd"),
    label_weeks: weeklist.length,
    label_scoolhours: hours,
    range_holidays: holidayRange,
  };
}

/**
 *
 */
export function getWeekNummer2PositionMap(
  startDate,
  endDate,
  CALENDAR_WEEK_UNIT,
  holidays,
) {
  const calcStartDate = DateTime.fromISO(startDate);
  const calcEndDate = DateTime.fromISO(endDate);

  const weekRange = dateRange(calcStartDate, calcEndDate, "weeks");

  const weekList = weekRange.map((currDate, index) => {
    const monday = currDate.set({ weekday: 1 });
    const friday = currDate.set({ weekday: 5 });

    // monatswechsel in der aktuellen woche?
    const month_break =
      index !== 0 && friday.month !== friday.minus({ weeks: 1 }).month;

    const last_month_txt = friday.monthLong;

    let holiday_marker = "";

    holidays.forEach((el) => {
      if (el.start && el.end) {
        // neue Logik: prüfen ob beim ende oder start datum mehr als zwei tage dazwischen liegen, wenn ja sind es ferien
        const end = DateTime.fromFormat(el.end, "d-M-yyyy");
        const start = DateTime.fromFormat(el.start, "d-M-yyyy");
        const diff_days = end.diff(start).as("days") + 1;

        if (diff_days > 1) {
          // wenn ja die kw aus dem ende datum mit der kw aus dem ersten tag der woche vergleichen (calcdate)
          const kw_calcdate = currDate.toFormat("W");

          const intervals = Interval.fromDateTimes(
            start.startOf("day"),
            end.endOf("day"),
          )
            .splitBy({ day: 1 })
            .map((date) => date.start.toFormat("W"));

          intervals.forEach((item) => {
            if (item === kw_calcdate) {
              holiday_marker = "holiday";
            }
          });
        }
      }
    });

    const startDateFormat =
      index === 0
        ? calcStartDate.toFormat("dd.MM.yyyy")
        : monday.toFormat("dd.MM.yyyy");

    const startCalcDate =
      index === 0
        ? calcStartDate.toFormat("yyyy-MM-dd")
        : monday.toFormat("yyyy-MM-dd");

    const compDate =
      index === 0
        ? calcStartDate.toFormat("yyyyMMdd")
        : monday.toFormat("yyyyMMdd");

    const endDateFormat =
      index === weekRange.length - 1
        ? calcEndDate.toFormat("dd.MM.yyyy")
        : monday.set({ weekday: 5 }).toFormat("dd.MM.yyyy");

    const compEndDate =
      index === weekRange.length - 1
        ? calcEndDate.toFormat("yyyyMMdd")
        : friday.toFormat("yyyyMMdd");

    const dateShort =
      index === 0 ? calcStartDate.toFormat("dd.MM") : monday.toFormat("dd.MM");

    return {
      id: index + 1,
      pos_start: index,
      pos_end: index + 1,
      kw: currDate.toFormat("W"),
      year: monday.toFormat("yyyy"),
      date: startDateFormat,
      end_date: endDateFormat,
      compDate,
      compEndDate,
      date_short: dateShort,
      kw_human: monday.toFormat("W"),
      calc_date: startCalcDate,
      month_break,
      month_name: last_month_txt,
      calc_end_date: "",
      holiday: holiday_marker,
      ab_week: index % 2 === 0 ? "A" : "B",
    };
  });

  return weekList;
}

/**
 * get the number of days between two dates without weekends
 * @param startDate
 * @param endDate
 * @return {number}
 */
export function getYearScooldDays(startDate, endDate, dayUnit) {
  const dayLux = DateTime.fromISO(startDate);
  const endDayLux = DateTime.fromISO(endDate);

  const weekdaysInSchoolyear = dateRange(dayLux, endDayLux).filter(
    (d) => d.weekday !== 6 && d.weekday !== 7, // no saturdays or sundays
  );

  const monthlistLux = weekdaysInSchoolyear.reduce((acc, d, index) => {
    const month = d.toFormat("MMMM");
    const monthYear = d.toFormat("MMMMyyyy");
    if (!acc.some((item) => item.monthYear === monthYear)) {
      const pos = index * Number(dayUnit / 5);
      return [...acc, { pos, month, monthYear }];
    }
    return acc;
  }, []);
  return {
    days: weekdaysInSchoolyear.length,
    months: monthlistLux,
  };
}

/**
 * create an uniqe id
 * @return {string}
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function uniqueId() {
  return Math.floor(new Date().valueOf() * Math.random());
}
