/**
 * Created by makkuz on 06.02.18.
 */

import React from "react";
import ClassSubjectRowItem from "./ClassesSubjectRowItem";

class ClassSubjectRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
    this.handleHoverOut = this.handleHoverOut.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleHover() {
    this.props.addModalHoverClass();
  }

  handleHoverOut() {
    this.props.removeModalHoverClass();
  }

  handleItemClick(subject_id) {
    const item = {
      class_id: this.props._id,
      subject_id,
      week_type: this.props.week_type,
    };
    this.props.handleItemClick(item);
  }

  render() {
    const preselectClass =
      String(this.props.preselect_class) === String(this.props._id);

    return (
      <div className="classes-subject-row">
        <div className="title">{this.props.title}</div>
        <div className="subjects">
          {this.props.subjects.map((subject, i) => {
            let preselect = false;
            if (
              preselectClass &&
              String(subject._id) === String(this.props.preselect_subject)
            ) {
              preselect = true;
            }

            return (
              <ClassSubjectRowItem
                key={`csri-${i}`}
                handleHover={this.handleHover}
                handleHoverOut={this.handleHoverOut}
                handleItemClick={this.handleItemClick}
                title={subject.title}
                color={subject.color}
                subject_id={subject._id}
                preselect={preselect}
                preselect_class_id={this.props._id}
                preselect_subject_id={subject._id}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
export default ClassSubjectRow;
