class ElmCalendarError extends Error {
  constructor(message, key, data) {
    super(`${key}: ${message}`);

    this.name = "ElmCalendarError";

    // A key for Sentry grouping
    this.key = key;
    // some extra data
    this.data = data;
  }
}

export { ElmCalendarError };
