import { createAction } from "@reduxjs/toolkit";

type Options = { replace: boolean };

export const routerNavigate = createAction(
  "ROUTER_NAVIGATE",
  (
    to: string,
    opts?: Options,
  ): {
    payload: {
      to: string;
      opts?: Options;
    };
  } => ({ payload: { to, opts } }),
);
