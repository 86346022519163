/**
 * Created by makkuz on 06.02.18.
 */

import React from "react";
import TimeTableItem from "./TimeTableItem";

class TimeTableColumn extends React.Component {
  constructor(props) {
    super(props);

    // console.log('constructor');
    // console.log(this.props);

    this.state = {
      hours: 7,
    };
    this.openModal = this.openModal.bind(this);
    this.addTimeTableItem = this.addTimeTableItem.bind(this);
    this.findMaxHourFromList = this.findMaxHourFromList.bind(this);
  }

  UNSAFE_componentWillMount() {
    const cnt = this.findMaxHourFromList(this.props.timetable);
    if (cnt > this.state.hours) {
      this.setState({
        hours: cnt,
      });
    }
  }

  openModal(hour, day, class_id, subject_id, class_id_b, subject_id_b) {
    this.props.openModal(
      hour,
      day,
      class_id,
      subject_id,
      class_id_b,
      subject_id_b,
    );
  }

  addTimeTableItem() {
    // console.log('add time table hour');
    let { hours } = this.state;
    hours += 1;

    this.setState({
      hours,
    });

    // console.log('add time table item: ' + hours);

    this.props.addTimeTableLabel(hours);
  }

  findMaxHourFromList(list) {
    let res = Math.max(...list.map((o) => o.hour));
    if (res < this.state.hours) res = this.state.hours;
    // console.log('max hour calculated: ' + res);
    return res;
  }

  renderItems() {
    const hour_items = [];

    // const cnt = this.props.cnt;
    const cnt = this.findMaxHourFromList(this.props.timetable);

    for (let i = 0; i <= cnt; i += 1) {
      let tt = false;
      if (this.props.timetable.length > 0) {
        tt = this.props.timetable.find((item) => Number(item.hour) === i);

        if (!tt) tt = false;
      }
      hour_items.push(
        <TimeTableItem
          ab_weeks={this.props.ab_weeks}
          timetable={tt}
          hour={i}
          day={this.props.day}
          openModal={this.openModal}
          key={`tt-item-${i}`}
        />,
      );
    }
    hour_items.push(
      <TimeTableItem
        ab_weeks={this.props.ab_weeks}
        hour="-1"
        day={this.props.day}
        openModal={this.addTimeTableItem}
        key={`tt-item-${this.props.day}-9999`}
      />,
    );
    return hour_items;
  }

  render() {
    const staturday_class = this.props.show_saturday
      ? this.props.show_saturday
      : "";
    return (
      <div className={`timetable-column ${staturday_class}`}>
        {this.renderItems()}
      </div>
    );
  }
}
export default TimeTableColumn;
