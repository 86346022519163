import { DateTime } from "luxon";
import { html2pdfmake } from "./html2pdfmake";
import { fonts } from "./fonts";

const _handleMaterial = (elem) => {
  const material_preselect = [];
  const _material_filter = [];
  if (elem.data && elem.data.length > 0) {
    elem.data.forEach((data) => {
      if (data.material && data.material.length > 0) {
        data.material.forEach((material) => {
          if (!_material_filter[material.value]) {
            _material_filter[material.value] = material.value;
            material_preselect.push({
              label: material.label,
              value: material.value,
            });
          }
        });
      }
    });
  }
  return material_preselect;
};

const _isEmptyDaySlotForMove = (curr_slot) => {
  let empty_count = 0;
  if (curr_slot.title) {
    empty_count += 1;
  }
  if (curr_slot.didakt && curr_slot.didakt !== "<p><br></p>") {
    empty_count += 1;
  }
  if (curr_slot.homework && curr_slot.homework !== "<p><br></p>") {
    empty_count += 1;
  }
  if (curr_slot.lehrplanbezug && curr_slot.lehrplanbezug.length > 0) {
    empty_count += 1;
  }
  if (curr_slot.data && curr_slot.data.length > 0) {
    empty_count += 1;
  }
  return empty_count === 0;
};

const _handleArrayList = (list) => {
  let list_str = "";
  if (list && list.length > 0) {
    list.forEach((elem) => {
      list_str = `${list_str + elem.label}, `;
    });
  }
  return list_str;
};

const _prepareData = (timeslots, saturday = false, end_date) => {
  const rows = [];
  let kw = 0;
  timeslots.forEach((elem, idx) => {
    const lehrlist = _handleArrayList(elem.lehrplanbezug);
    const material = _handleMaterial(elem);
    const material_list = _handleArrayList(material);
    const date =
      elem.date instanceof Date
        ? DateTime.fromJSDate(elem.date)
        : DateTime.fromISO(elem.date);
    const endDate = DateTime.fromFormat(end_date, "yMMdd");
    if (date.toUnixInteger() <= endDate.toUnixInteger()) {
      // check if timeslot empty or not
      if (_isEmptyDaySlotForMove(elem)) {
        return;
      }
      // calendar week row
      const new_kw = date.weekNumber;
      if (new_kw > kw) {
        kw = new_kw;

        const last_day_idx = saturday ? 6 : 5;
        const startOfWeek = date.startOf("week");
        const endOfWeek = startOfWeek.plus({ days: last_day_idx - 1 });

        const timeRow = {
          text: `Woche von ${startOfWeek.toLocaleString({
            day: "numeric",
          })} bis ${endOfWeek.toLocaleString(DateTime.DATE_FULL)}`,
          style: "timeRow",
        };
        rows.push(timeRow);
      }

      const didakt = html2pdfmake(elem.didakt);
      const lehr_stack = {
        stack: [
          { text: "\n\nLehrplanbezug\n", bold: true },
          { text: lehrlist },
        ],
      };
      didakt.push(lehr_stack);
      const formatted_date = date.toLocaleString(DateTime.DATE_HUGE);

      const unit_nr = idx + 1;

      const tmp = {
        style: "tableRow",
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          // paddingLeft: function(i, node) { return 4; },
          // paddingRight: function(i, node) { return 4; },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        },
        table: {
          widths: [300, "*"],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: [{ text: elem.title, fontSize: 8, bold: true }],
                margin: [3, 3, 3, 3],
              },
              {
                text: [
                  { text: `${unit_nr}. Unterrichtseinheit | ` },
                  { text: formatted_date, bold: true },
                ],
                margin: [3, 3, 3, 3],
                alignment: "right",
              },
            ],
            [
              {
                /*
                                text : [
                                    { stack: didakt },
                                    { text: "Lehrplanbezug\n", bold: true},
                                    { text: lehrlist}

                                ],
                                */
                stack: didakt,
                /*
                                text : [
                                    { text: "Lehrplanbezug\n", bold: true},
                                    { text: lehrlist}

                                ],
                                */
                border: [true, false, false, true],
                margin: [3, 3, 3, 3],
              },
              {
                text: [
                  { text: "Material und Medien\n\n", bold: true },
                  { text: material_list },
                ],
                border: [false, false, true, true],
                margin: [3, 3, 3, 3],
              },
            ],
          ],
        },
      };
      rows.push(tmp);
    }
  });
  return rows;
};

export default (
  school,
  user,
  seq_data,
  dayslot,
  timeslots,
  end_date,
  current_schoolyear,
) => {
  const tableRows = _prepareData(timeslots, dayslot.saturday, end_date);

  // var didakt = html2pdfmake( seq_data.diktat );
  const didakt = html2pdfmake(dayslot.didaktik);

  const pdf_title = `${seq_data.title} ${dayslot.sequence_title}`;

  const documentDefinition = {
    // footer: function(currentPage, pageCount) { return 'Sequenz "'+seq_data.title+'" | Seite' +  currentPage.toString() + ' von ' + pageCount; },
    footer(currentPage, pageCount) {
      return {
        margin: 5,
        columns: [
          {
            fontSize: 8,
            text: [
              { text: `Sequenz "${seq_data.title}" | ` },
              {
                text: `Seite ${currentPage.toString()} von ${pageCount}`,
                bold: true,
              },
            ],
            alignment: "right",
            margin: [0, 0, 40, 0],
          },
        ],
      };
    },
    header: [
      {
        margin: 40,
        columns: [
          {
            width: "*",
            text: [
              { text: `${school.school}\n`, fontSize: 8, bold: true },
              {
                text: `${school.street} ${school.nr}\n`,
                fontSize: 8,
                bold: false,
              },
              {
                text: `${school.zip} ${school.location}`,
                fontSize: 8,
                bold: false,
              },
            ],
            style: "header_left",
          },
          {
            width: "*",
            text: [
              { text: "Sequenzplanung\n", fontSize: 18, bold: true },
              {
                text: `${seq_data.classroomTitle} `,
                fontSize: 8,
                bold: false,
              },
              { text: `${seq_data.title} `, fontSize: 8, bold: true },
              {
                text: `Schuljahr ${current_schoolyear.label}\n\n `,
                fontSize: 8,
                bold: true,
              },
            ],
            alignment: "center",
            style: "header_center",
          },
          {
            width: "*",
            text: [
              { text: "Lehrkraft: ", fontSize: 8, bold: false },
              { text: user.name, fontSize: 8, bold: true },
            ],
            alignment: "right",
            style: "header_right",
          },
        ],
      },
    ],
    pageSize: "A4",
    // pageOrientation: 'landscape',
    content: [
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 595 - 2 * 40,
            y2: 0,
            lineWidth: 0.5,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: [
          { text: `${seq_data.title} | `, fontSize: 12, bold: true },
          { text: dayslot.display_date, fontSize: 12, bold: false },
        ],
      },
      {
        text: [
          { text: `${dayslot.sequence_title}\n`, fontSize: 32, bold: true },
        ],
      },

      {
        stack: didakt,
        margin: [0, 10, 0, 10],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 10,
            x2: 595 - 2 * 40,
            y2: 10,
            lineWidth: 0.5,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      ...tableRows,
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      headline: {
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableFull: {},
      tableHeader: {
        bold: false,
        fontSize: 11,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      introtext: {
        fontSize: 12,
        margin: [20, 20, 20, 20],
      },
      tableRow: {
        margin: [0, 5, 0, 15],
        fontSize: 8,
      },
      timeRow: {
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 10],
      },
    },
    pageMargins: [40, 90, 40, 60],
  };
  let str = `Sequenz ${pdf_title}`;
  str = str.replace(/\s/g, "_");
  str = str.replace(",", "_");
  str = str.replace(".", "_");
  str += ".pdf";
  window.pdfMake.createPdf(documentDefinition, null, fonts).download(str);
};
