import React, { useState, useEffect } from "react";
import { defaultSettings } from "../../../../features/settings/settings-slice";
import { api } from "../../../../services/api";
import { classList } from "../../../utils/classList";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { useAppSelector } from "../../../../app/hooks";

function ChangeTaktungForm() {
  const year = useAppSelector(selectCurrentSchoolyear);
  const { isLoading, data: settings = defaultSettings } =
    api.endpoints.getSettings.useQueryState(year);

  const [state, setState] = useState<{ edit_mode: boolean; taktung: string }>({
    edit_mode: false,
    taktung: settings.settings.taktung,
  });

  // make sure to synchronize local state with api data when api data changes
  useEffect(() => {
    setState({ ...state, taktung: settings.settings.taktung });
  }, [settings.settings.taktung]);

  const [updateSettings] = api.useUpdateSettingsMutation();

  const handleEditForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setState({ ...state, edit_mode: true });
  };

  const setTaktung = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, taktung: e.target.value });
  };

  const saveTaktung = () => {
    const newSettings = {
      ...settings,
      schoolyear: year,
      settings: {
        ...settings.settings,
        taktung: state.taktung,
      },
    };
    updateSettings(newSettings);
    setState({ ...state, edit_mode: false });
  };

  const getText = () => {
    switch (true) {
      case !isLoading && String(settings.settings.taktung) === "20":
        return "Minutengenaue Taktung";
      case !isLoading && String(settings.settings.taktung) === "100":
        return "5 - Minuten Taktung";
      default:
        return "";
    }
  };

  if (!state.edit_mode) {
    return (
      <div
        className={classList({
          action_form_wrapper: true,
          "opacity-50": isLoading,
        })}
      >
        <div className="readonly">
          <span className="label">{getText()}</span>
          <span className="actions">
            <a
              href="#"
              onClick={(e) => {
                handleEditForm(e);
              }}
              className={classList({
                "pointer-events-none cursor-not-allowed": isLoading,
              })}
            >
              <img src="/assets/images/edit-icon.png" className="form-icon" />
            </a>
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="action_form_wrapper">
      <div className="edit">
        <div className="radio-wrapper">
          <span className="radio-inline">
            <input
              id="elem1"
              className="input_radio"
              type="radio"
              value="100"
              checked={String(state.taktung) === "100"}
              onChange={(e) => setTaktung(e)}
            />
            <label htmlFor="elem1">5 - Minuten Taktung</label>
          </span>

          <span className="radio-inline">
            <input
              className="input_radio"
              id="elem2"
              type="radio"
              value="20"
              checked={String(state.taktung) === "20"}
              onChange={(e) => setTaktung(e)}
            />
            <label htmlFor="elem2">Minutengenaue Taktung</label>
          </span>
        </div>
        <div className="btn-wrapper dashboard-btns">
          <button
            onClick={() => {
              saveTaktung();
            }}
            className="btn btn-step"
          >
            Änderungen Speichern
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeTaktungForm;
