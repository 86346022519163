import React from "react";
import Modal from "react-modal";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import TimeTableColumns from "../../../../common/components/settings/timetables/TimeTableColumn";
import TimeTableColumnLabel from "../../../../common/components/settings/timetables/TimeTableColumnLabel";
import ClassesSubjectRow from "../../../../common/components/settings/timetables/ClassesSubjectRow";
import Auth from "../../../../common/services/Auth";

const ModalStyles = {
  overlay: {
    backgroundColor: "rgba(64, 184, 126, 0.7)",
  },
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};
Modal.setAppElement("#appMountPoint");

const TimeTableDayLabels = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

export default class AddTimetableForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalHoverClass: "",
      modalDay: "",
      modalHour: "",
      hour: "",
      day: "",
      timeTableLabelCnt: 7,
      preselect_class_id: false,
      preselect_subject_id: false,
      schoolyear: props.schoolyear,
      entries: props.timetable.entries,
      ab_weeks: false,
      ab_weeks_change_state: 0,
      ab_weeks_can_save: false,
      ab_settings_edit: false,
      finalizeSchoolyear: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addModalHoverClass = this.addModalHoverClass.bind(this);
    this.removeModalHoverClass = this.removeModalHoverClass.bind(this);
    this.addTimeTableLabel = this.addTimeTableLabel.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.resetData = this.resetData.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.changeSaturday = this.changeSaturday.bind(this);
    this.entriesABWeeks = this.entriesABWeeks.bind(this);
    this.changeABSettings = this.changeABSettings.bind(this);
    this.changeABSettingsHandler = this.changeABSettingsHandler.bind(this);
    this.cleanUpABProperties = this.cleanUpABProperties.bind(this);
    this.setUpABProperties = this.setUpABProperties.bind(this);

    // local mehthods - dont call properties
    this.updateClassListItem = this.updateClassListItem.bind(this);
    this.handleEntries = this.handleEntries.bind(this);
  }

  UNSAFE_componentWillMount() {
    // var length = this.props.timetable.entries.length;
    let length = this.findMaxHourFromList();
    const hasABWeeksSettings = this.hasABWeeksSettings();
    if (length < 7) length = 7;
    this.setState({
      timeTableDefCnt: length,
      ab_weeks: hasABWeeksSettings,
      ab_weeks_change_state: hasABWeeksSettings,
    });
  }

  /**
   * click on subject inside modal -> create or update an entry inside timetable
   * @param obj
   */
  handleItemClick(obj) {
    // this.testHandleItemClick( obj );
    // return;

    // find class and subjects via internal id
    const tmp_classes = this.props.classes.slice();
    const tmp_subjects = this.props.subjects.slice();

    const class_entry = tmp_classes.find((p) => p._id === obj.class_id);

    const subject_entry = tmp_subjects.find((p) => p._id === obj.subject_id);

    // get previous preselecetd item values
    const _presel_day = this.state.day;
    let _presel_class_id = this.state.preselect_class_id;
    let _presel_subject_id = this.state.preselect_subject_id;

    if (this.state.ab_weeks) {
      if (obj.week_type === "B") {
        _presel_class_id = this.state.preselect_class_id_b;
        _presel_subject_id = this.state.preselect_subject_id_b;
      }
    }

    if (this.state.ab_weeks) {
      if (obj.week_type === "B") {
        this.setState({
          preselect_class_id_b: obj.class_id,
          preselect_subject_id_b: obj.subject_id,
        });
      } else {
        this.setState({
          preselect_class_id: obj.class_id,
          preselect_subject_id: obj.subject_id,
        });
      }
    } else {
      this.setState({
        preselect_class_id: obj.class_id,
        preselect_subject_id: obj.subject_id,
      });
    }

    // prepare data to add or remove schooldays
    // update der klassenliste um die Stunden zu ergänzen/löschen
    let other_weektype = "default";
    if (this.state.ab_weeks) {
      other_weektype = obj.week_type === "A" ? "B" : "A";
    }

    // prepare data to add or remove schooldays
    // update der klassenliste um die Stunden zu ergänzen/löschen
    const schoolday_entry = {
      day: this.state.day,
      day_name: this.state.modalDay,
      hour: this.state.hour,
      week_type: obj.week_type !== "default" ? obj.week_type : "default",
    };

    // prüfen ob ein anderer ab tag in irgendeinen fach existiert, wenn nein kann dieser dupliziert werden.
    // b wwochen welche geclont wurden sollen nicht matchen
    const check_exists_list = this.props.classes.slice();
    let other_weektype_exists = false;
    check_exists_list.forEach((elementc) => {
      elementc.subjects.forEach((subsc) => {
        subsc.schooldays.forEach((sdc) => {
          if (
            sdc.day === schoolday_entry.day &&
            sdc.hour === schoolday_entry.hour &&
            sdc.week_type === other_weektype &&
            !sdc.week_clone
          ) {
            other_weektype_exists = true;
          }
        });
      });
    });

    const update_class = class_entry;

    if (update_class) {
      /**
       * abgleich mit der liste aus timetable damit hier entsprechend wieder aufgeräumt wird.
       * stundenplan einträge welche neu mit inhalt belegt wurden, wurden nicht gelöscht.
       */

      const check_list = [];
      this.state.entries.forEach((elem) => {
        // alle datensätze zum aktuellen tag, klasse und fach ermitteln

        if (this.state.ab_weeks) {
          if (Number(elem.day) === Number(_presel_day)) {
            // weeks
            if (
              elem.classes_ab_weeks.A &&
              elem.classes_ab_weeks.A._id === obj.class_id &&
              elem.classes_ab_weeks.A.subject._id === obj.subject_id
            ) {
              check_list.push({
                day: elem.day,
                day_name: elem.day_name,
                hour: elem.hour,
                week_type: "A",
              });
            }
            // B Weeks
            if (
              elem.classes_ab_weeks.B &&
              elem.classes_ab_weeks.B._id === obj.class_id &&
              elem.classes_ab_weeks.B.subject._id === obj.subject_id
            ) {
              check_list.push({
                day: elem.day,
                day_name: elem.day_name,
                hour: elem.hour,
                week_type: "B",
              });
            }
          }
        } else if (
          Number(elem.day) === Number(_presel_day) &&
          elem.class._id === obj.class_id &&
          elem.class.subject._id === obj.subject_id
        ) {
          check_list.push({
            day: elem.day,
            day_name: elem.day_name,
            hour: elem.hour,
            week_type: obj.week_type !== "default" ? obj.week_type : "default",
          });
        }
      });

      const check_list_2 = [];
      const check_list_others = [];
      update_class.subjects.forEach((element) => {
        if (element._id === obj.subject_id) {
          if (element.schooldays.length > 0) {
            element.schooldays.forEach((sd) => {
              if (Number(sd.day) === Number(_presel_day)) {
                // alles zu dem gesuchten Tag
                const new_sd = { ...sd };
                check_list_2.push(new_sd);
              } else {
                // alle anderen
                const new_sd = { ...sd };
                check_list_others.push(new_sd);
              }
            });
          }

          if (check_list.length !== check_list_2.length) {
            element.schooldays = [];
            element.schooldays = check_list.concat(check_list_others);
          }
        }
      });

      /**
       * wir haben an dem geklickten element bereits einen geplanten tag. dieser muss gelöscht werden, analog
       * den freistunde prozess button.
       */
      if (_presel_class_id && _presel_subject_id) {
        // reset the day inside class list

        update_class.subjects.forEach((element, index) => {
          if (
            element._id === _presel_subject_id &&
            element.schooldays &&
            element.schooldays.length > 0
          ) {
            const tmp_list = [];
            element.schooldays.forEach((sd) => {
              if (this.state.ab_weeks) {
                // with ab weeks
                if (
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour &&
                  sd.week_type === schoolday_entry.week_type
                ) {
                  // do nothing
                } else {
                  tmp_list.push(sd);
                }
              }
              // default without ab weeks active
              else if (
                sd.day === schoolday_entry.day &&
                sd.hour === schoolday_entry.hour
              ) {
                // do nothing
              } else {
                tmp_list.push(sd);
              }
            });
            update_class.subjects[index].schooldays = tmp_list;
          }
        });
      }

      /**
       * fügt die geklickte Fach/Klasse der Schulstunde hinzu.
       */
      update_class.subjects.forEach((element, index) => {
        if (element._id === obj.subject_id) {
          if (update_class.subjects[index].schooldays) {
            // add schoolday only if not exists
            if (this.state.ab_weeks) {
              // new with ab

              // prüfen ob der andere weektype vorhanden ist, falls nicht, beide identisch anlegen.
              const exists_other_week = update_class.subjects[
                index
              ].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour &&
                  sd.week_type === other_weektype,
              );

              const exists = update_class.subjects[index].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour &&
                  sd.week_type === schoolday_entry.week_type,
              );

              if (!exists) {
                update_class.subjects[index].schooldays.push(schoolday_entry);

                // nur wenn A geklickt wurde und der B nicht existiert hinzufügen
                if (
                  !exists_other_week &&
                  !other_weektype_exists &&
                  other_weektype === "B"
                ) {
                  const other_entry = { ...schoolday_entry };
                  other_entry.week_type = other_weektype;
                  other_entry.week_clone = true;
                  update_class.subjects[index].schooldays.push(other_entry);
                }
              }
            } else {
              const exists = update_class.subjects[index].schooldays.find(
                (sd) =>
                  sd.day === schoolday_entry.day &&
                  sd.hour === schoolday_entry.hour,
              );
              if (!exists) {
                update_class.subjects[index].schooldays.push(schoolday_entry);
              }
            }
          } else {
            update_class.subjects[index].schooldays = [schoolday_entry];

            if (this.state.ab_weeks) {
              // noch nichts vorhanden - anderen weektype als clone erstmal damit a/b komplett sind
              if (!other_weektype_exists && other_weektype === "B") {
                const other_entry = { ...schoolday_entry };
                other_entry.week_type = other_weektype;
                other_entry.week_clone = true;
                update_class.subjects[index].schooldays.push(other_entry);
              }
            }
          }
        }
      });

      // store classlists inside backend
      this.updateClassListItem(update_class, this.state.schoolyear);
    } else {
      // ITEMS LöSCGEN

      // reset the day inside class list
      const class_list = this.props.classes;
      let founded_idx = "";

      class_list.forEach((element, index) => {
        if (element._id === _presel_class_id) {
          if (element.subjects.length > 0) {
            element.subjects.forEach((subs, sindex) => {
              if (
                subs._id === _presel_subject_id &&
                subs.schooldays &&
                subs.schooldays.length > 0
              ) {
                const tmp_list = [];

                // prüfen ob es zu einen A eintrag einen clone gibt, wenn ja muss der B Clone mit gelöscht werden.
                let delete_with_clone = false;
                if (this.state.ab_weeks && schoolday_entry.week_type === "A") {
                  subs.schooldays.forEach((sd) => {
                    if (
                      sd.day === schoolday_entry.day &&
                      sd.hour === schoolday_entry.hour &&
                      sd.week_type === "B" &&
                      sd.week_clone === true
                    ) {
                      delete_with_clone = true;
                    }
                  });
                }

                subs.schooldays.forEach((sd) => {
                  if (this.state.ab_weeks) {
                    if (delete_with_clone) {
                      if (
                        sd.day === schoolday_entry.day &&
                        sd.hour === schoolday_entry.hour
                      ) {
                        founded_idx = index;
                      } else {
                        tmp_list.push(sd);
                      }
                    } else if (
                      sd.day === schoolday_entry.day &&
                      sd.hour === schoolday_entry.hour &&
                      sd.week_type === schoolday_entry.week_type
                    ) {
                      founded_idx = index;
                    } else {
                      tmp_list.push(sd);
                    }
                  } else if (
                    sd.day === schoolday_entry.day &&
                    sd.hour === schoolday_entry.hour
                  ) {
                    founded_idx = index;
                  } else {
                    tmp_list.push(sd);
                  }
                });

                // var new_schooldays = subs.schooldays.filter((ss, i) => ss.day != schoolday_entry.day && ss.hour != schoolday_entry.hour);
                class_list[index].subjects[sindex].schooldays = tmp_list;
              }
            });
          }
        }
      });

      if (founded_idx !== "") {
        const foundClass = class_list[founded_idx];
        this.updateClassListItem(foundClass, this.state.schoolyear);
      }
    }

    let new_class_entry = "clear";
    if (class_entry) {
      new_class_entry = { ...class_entry };
      new_class_entry.subject = { ...subject_entry };
      // class_entry.subject = subject_entry;
    } else {
      // let new_class_entry = 'clear';
      // class_entry = 'clear'; // set to clear for del from timetable
    }

    // zum update der timetable list
    let new_obj = {
      day: this.state.day,
      day_name: this.state.modalDay,
      hour: this.state.hour,
      class: new_class_entry,
    };

    if (this.state.ab_weeks) {
      new_obj = this.entriesABWeeks(new_obj, obj.week_type);
    }

    this.handleEntries(new_obj, obj.week_type);
    this.closeModal();
  }

  /**
   *
   * @param item
   */
  handleEntries(item, ab_weeks) {
    const new_item = { ...item };
    let new_entries = [];
    let new_list = [];
    // let {entries} = this.state;
    const entries = this.state.entries.slice();
    const foundIndex = entries.findIndex(
      (el) =>
        Number(el.day) === Number(item.day) &&
        Number(el.hour) === Number(item.hour),
    );

    if (foundIndex !== -1) {
      new_list = [
        ...entries.slice(0, foundIndex),
        ...entries.slice(foundIndex + 1),
      ];

      // clear element dont push new one
      if (item.class === "clear") {
        // pruefen ob es sich beim löschen um ein AB Wochen element handelt
        if (ab_weeks !== "default") {
          // wenn ja, prüfen ob noch min ein wert dazu besteht - wenn ja keine änderungen
          if (
            entries[foundIndex].classes_ab_weeks &&
            (entries[foundIndex].classes_ab_weeks.A ||
              entries[foundIndex].classes_ab_weeks.B)
          ) {
            new_entries = entries;

            // kein element mehr da - komplett löschen
          } else {
            new_entries = new_list;
          }
        } else {
          new_entries = new_list;
        }

        // new_entries = new_list;
      } else {
        // push new one
        new_entries = [...new_list, new_item];
      }
    } else if (item.class === "clear") {
      new_entries = entries.slice();
    } else {
      new_entries = [...entries.slice(), new_item];
    }

    this.setState({
      entries: new_entries.slice(),
    });

    const timetable = { ...this.props.timetable, entries: new_entries.slice() };

    this.props.updateTimetable(timetable);
  }

  /**
   * @TODO: replace function calls
   * bei aktivierung und bestehenden stundenplan alles
   */
  setUpABProperties() {
    const entries = this.state.entries.slice();
    entries.forEach((elem) => {
      if (elem.class) {
        const obj = { ...elem.class };
        elem.classes_ab_weeks = {};
        elem.classes_ab_weeks.A = obj;
        elem.classes_ab_weeks.B = obj;
        elem.classes_ab_weeks.B.week_clone = true;
      }
    });

    this.setState({
      entries,
    });

    this.props.classes.forEach((element) => {
      if (element.subjects.length > 0) {
        element.subjects.forEach((subs) => {
          if (subs.schooldays && subs.schooldays.length > 0) {
            const b_weeks_list = [];
            subs.schooldays.forEach((sd) => {
              sd.week_type = "A";
              const b_weeks_clone = { ...sd };
              b_weeks_clone.week_type = "B";
              b_weeks_clone.week_clone = true;
              b_weeks_list.push(b_weeks_clone);
            });
            subs.schooldays.push(...b_weeks_list);
          }
        });
      }
      this.updateClassListItem(element, this.state.schoolyear);
    });
  }

  /**
   *
   */
  toggleABSettingsEdit(status) {
    if (Number(status) === 0) {
      // abbrechen - evtl änderungen reseten
      this.setState({
        ab_settings_edit: status,
        ab_weeks_change_state: this.state.ab_weeks, // <- reset to default
        ab_weeks_can_save: false, // <- reset to default
      });
    } else {
      // open
      this.setState({
        ab_settings_edit: status,
      });
    }
  }

  changeABSettingsHandler(val) {
    this.setState({
      ab_weeks_change_state: val,
      ab_weeks_can_save: val !== Number(this.state.ab_weeks),
    });
  }

  /**
   * change the values (on/off) for ab weeks
   * @param val
   */
  changeABSettings(val) {
    if (val === Number(this.state.ab_weeks)) {
      // do nothing same value
      return;
    }

    const currentYear = this.state.schoolyear;

    const newSettings = immutableUpdate(this.props.settings, (draft) => {
      if (!draft.settings.ab_weeks) {
        draft.settings.ab_weeks = {};
      }
      if (!draft.settings.ab_weeks[currentYear]) {
        draft.settings.ab_weeks[currentYear] = {
          holiday_break: false,
          entries: [],
        };
      }
      // set new value for current year
      draft.settings.ab_weeks[currentYear].active = val;
    });

    if (Number(val) === 0) {
      // cleanup Ab from class list and timetable - delete b entries
      this.cleanUpABProperties();
    } else {
      this.setUpABProperties();
    }

    // @TODO: replace with storage function
    this.props.updateSettings(newSettings);

    this.setState({
      ab_settings_edit: false,
      ab_weeks_can_save: false,
      ab_weeks: val,
    });
  }

  hasABWeeksSettings() {
    let ab_weeks = false;
    if (this.props.settings && this.props.settings.settings) {
      // settings exists, check if have ab active for the current year
      const { settings } = this.props.settings;
      if (settings.ab_weeks && settings.ab_weeks[this.state.schoolyear]) {
        if (Number(settings.ab_weeks[this.state.schoolyear].active) === 1) {
          ab_weeks = true;
        }
      }
    }
    return Number(ab_weeks);
  }

  /**
   * close modal layer and reset presentation data
   */
  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalDay: "",
      modalHour: "",
      hour: "",
      day: "",
      preselect_class_id: false,
      preselect_subject_id: false,
    });
  }

  /**
   * add an time table hour label to the list
   * @param hours
   */
  addTimeTableLabel(hours) {
    const cnt = this.state.timeTableLabelCnt;
    if (hours >= cnt) {
      this.setState({
        timeTableLabelCnt: cnt + 1,
      });
    }
  }

  /**
   * update classes in storage
   * @param obj
   * @param year
   */
  updateClassListItem(obj, year) {
    obj.schoolyear = this.state.schoolyear;
    obj.user_id = Auth.getUserId();

    const classes = this.props.classes.slice();
    classes.forEach((cl) => {
      if (cl._id === obj._id) {
        cl.title = obj.title;
        cl.schoolyear = year;
        cl.subjects = obj.subjects;
      }
    });

    this.props.updateClasses(classes);
  }

  /**
   * @TODO: replace upsertTimetable / updateClassListItem
   * bei der Umstellung wieder alles auf einen wert
   * nur a wochen zu normalen machen
   */
  cleanUpABProperties() {
    // alle a class elemente ermitteln und bei entries wieder rein.
    const new_entries = [];
    this.props.timetable.entries.forEach((elem) => {
      if (elem.classes_ab_weeks && elem.classes_ab_weeks.A) {
        new_entries.push({
          day: elem.day,
          day_name: elem.day_name,
          hour: elem.hour,
          class: elem.classes_ab_weeks.A,
        });
      }
    });

    this.setState({
      entries: new_entries,
    });

    const class_list = this.props.classes.slice();
    class_list.forEach((element) => {
      if (element.subjects.length > 0) {
        element.subjects.forEach((subs, sindex) => {
          const new_school_list = [];
          if (subs.schooldays && subs.schooldays.length > 0) {
            subs.schooldays.forEach((sd) => {
              if (sd.week_type === "A") {
                new_school_list.push({
                  day: sd.day,
                  day_name: sd.day_name,
                  hour: sd.hour,
                });
              }
            });
          }

          element.subjects[sindex].schooldays = new_school_list;
        });
      }
      this.updateClassListItem(element, this.state.schoolyear);
    });
  }

  /**
   * open an modal layer with clicked hour and date informations
   * @param hour
   * @param day
   * @param class_id
   * @param subject_id
   */
  openModal(
    hour,
    day,
    class_id = false,
    subject_id = false,
    class_id_b = false,
    subject_id_b = false,
  ) {
    this.setState({
      modalIsOpen: true,
      modalDay: TimeTableDayLabels[day],
      modalHour: hour + 1,
      hour,
      day,
      preselect_class_id: class_id,
      preselect_subject_id: subject_id,
      preselect_class_id_b: class_id_b,
      preselect_subject_id_b: subject_id_b,
    });
  }

  /**
   * Ergänzen und löschen von Elementen. Wird das A Element geelöscht, prüfen wir ob es einen
   * identischen B Eintrag gibt und löschen diesen mit.
   *
   * @TODO: prüfen
   *
   *
   * @param obj
   * @param week_type
   * @returns {*}
   */
  entriesABWeeks(obj, week_type) {
    const entries = this.state.entries.map((a) => ({ ...a }));

    const other_weektype = week_type === "A" ? "B" : "A";

    const exists_idx = entries.findIndex(
      (el) =>
        Number(el.day) === Number(obj.day) &&
        Number(el.hour) === Number(obj.hour),
    );

    // falls vorhanden, ersetzen oder löschen
    if (exists_idx !== -1 && entries[exists_idx].classes_ab_weeks) {
      const exists_entry = entries[exists_idx].classes_ab_weeks;

      // löschen
      if (obj.class === "clear") {
        const clear_class_id = exists_entry[week_type]._id;
        const clear_subject_id = exists_entry[week_type].subject._id;

        delete exists_entry[week_type];

        // prüfen ob der B Eintrag das gleiche (Fach, Klasse) ist, wenn ja mit löschen.
        if (week_type === "A") {
          if (
            exists_entry.B &&
            exists_entry.B._id === clear_class_id &&
            exists_entry.B.subject._id === clear_subject_id &&
            exists_entry.B.week_clone
          ) {
            delete exists_entry.B;
          }
        }
      } else {
        // hinzufügen
        const obj_a_class = { ...obj.class };
        exists_entry[week_type] = obj_a_class;

        // new wenn a geklickt und  B nicht vorhanden -> duplizieren
        if (!exists_entry[other_weektype] && other_weektype === "B") {
          const obj_b_class = { ...obj.class };
          exists_entry[other_weektype] = obj_b_class;
          exists_entry[other_weektype].week_clone = true;
        }
        const new_exists_entry = { ...exists_entry };
        obj.classes_ab_weeks = new_exists_entry;
      }

      return obj;
      // neu hinzufügen
    }
    const init_classes_ab_weeks = {
      [week_type]: obj.class,
      ...(other_weektype === "B"
        ? { [other_weektype]: { ...obj.class, week_clone: true } }
        : {}),
    };
    return { ...obj, classes_ab_weeks: init_classes_ab_weeks };
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    this.setState({
      finalizeSchoolyear: true,
    });
    this.props.nextSection();
  }

  prevSection() {
    this.props.prevSection();
  }

  addModalHoverClass() {
    this.setState({
      modalHoverClass: "hover_item",
    });
  }

  removeModalHoverClass() {
    this.setState({
      modalHoverClass: "",
    });
  }

  findMaxHourFromList(day = false) {
    const def_cnt = this.state.timeTableDefCnt;
    const list =
      this.props.timetable && this.props.timetable.entries.length > 0
        ? this.props.timetable.entries
        : [];
    let res = 0;

    if (day) {
      const numberDay = Number(day);
      const tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === numberDay,
      );
      if (tt.length > 0) {
        res = Math.max(...tt.map((o) => o.hour));
      }
    } else {
      res = Math.max(...list.map((o) => o.hour));
    }

    if (def_cnt > res) {
      res = def_cnt;
    }
    return res;
  }

  resetData() {
    this.props.clearAll();
  }

  /**
   *
   * @param value
   */
  changeSaturday(value) {
    const obj = { ...this.props.timetable };
    obj.saturday = Number(value) === 1;
    this.props.updateTimetable(obj);
  }

  /**
   * create the columns from monday to saturday
   * @return {Array}
   */
  renderColumn(saturday_css_class) {
    const cnt = this.state.timeTableLabelCnt;
    const label_count_list = this.findMaxHourFromList();

    let label_count = 0;
    if (label_count_list > cnt) {
      label_count = label_count_list;
    } else {
      label_count = cnt;
    }

    const column_items = [];
    let tt = {};

    column_items.push(
      <TimeTableColumnLabel cnt={label_count} key="tt-column-label-0" />,
    ); // first column are the labels of hours

    for (let i = 0; i <= 4; i += 1) {
      tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === i,
      );

      column_items.push(
        <TimeTableColumns
          ab_weeks={this.state.ab_weeks}
          timetable={tt}
          addTimeTableLabel={this.addTimeTableLabel}
          day={i}
          openModal={this.openModal}
          show_saturday=""
          cnt=""
          key={`tt-column-${i}`}
        />,
      );
    }
    // push saturday

    tt = this.props.timetable.entries.filter(
      (entry) => Number(entry.day) === 5,
    );
    const rcnt = this.findMaxHourFromList(5);
    column_items.push(
      <TimeTableColumns
        timetable={tt}
        addTimeTableLabel={this.addTimeTableLabel}
        day="5"
        openModal={this.openModal}
        show_saturday={saturday_css_class}
        cnt={rcnt}
        key="tt-column-5"
      />,
    );
    return column_items;
  }

  render() {
    const nextenabled = this.state.finalizeSchoolyear;

    // overwrite

    // saturday stuff
    const isSaturdayValue =
      this.props.timetable && this.props.timetable.saturday ? 0 : 1;
    const defaultCheckedSaturday = !!(
      this.props.timetable && this.props.timetable.saturday
    );
    let saturday_css_class = "";
    let hasSaturdayClass = "with_saturday";

    if (!defaultCheckedSaturday) {
      saturday_css_class = "hidden";
      hasSaturdayClass = "";
    }

    // last modal row
    const last_modal = {
      title: "",
      subjects: [{ title: "Leerstunde", color: "#F2F2F2" }],
    };
    const hover_css = this.state.modalHoverClass;

    let ab_mode_css = "";
    let ab_edit_css = "";
    let ab_active_css = "";
    let ab_inactive_css = "";

    const {
      ab_settings_edit,
      ab_weeks,
      ab_weeks_change_state,
      ab_weeks_can_save,
    } = this.state;

    if (ab_weeks_change_state) {
      ab_inactive_css = "";
      ab_active_css = "active";
    } else {
      ab_inactive_css = "active";
      ab_active_css = "";
    }

    if (ab_settings_edit) {
      ab_mode_css = "hide";
      ab_edit_css = "show";
    } else {
      ab_mode_css = "show";
      ab_edit_css = "hide";
    }

    const abHeader = this.state.ab_weeks ? (
      <div className="ab_week_info">
        <span className="left">A</span>
        <span className="right">B</span>
      </div>
    ) : null;

    return (
      <div id="timetableView" className={`newschool ${hasSaturdayClass}`}>
        <div className="setting-ab-weeks">
          <div className={`info-wrapper ${ab_mode_css}`}>
            <span className="mode">Modus</span>
            <span className="info">
              {String(ab_weeks) === "0" ? (
                <span>gleicher Stundenplan für alle Wochen</span>
              ) : (
                <span>A/B Wochen</span>
              )}
            </span>
            <span className="edit">
              <a
                onClick={() => {
                  this.toggleABSettingsEdit(1);
                }}
              >
                <img src="/assets/images/edit-icon.png" className="form-icon" />
              </a>
            </span>
          </div>
          <div className={`edit-wrapper ${ab_edit_css}`}>
            <div className="mode">Modus</div>

            <span className={`item ${ab_inactive_css}`}>
              <input
                type="radio"
                name="ab_setting"
                value="0"
                checked={!ab_weeks_change_state}
                onChange={() => {
                  this.changeABSettingsHandler(0);
                }}
              />
              <label
                onClick={() => {
                  this.changeABSettingsHandler(0);
                }}
              >
                gleicher Stundenplan für alle Wochen
              </label>
            </span>

            <span className={`item ${ab_active_css}`}>
              <input
                type="radio"
                name="ab_setting"
                value="1"
                checked={Boolean(ab_weeks_change_state)}
                onChange={() => {
                  this.changeABSettingsHandler(1);
                }}
              />
              <label
                onClick={() => {
                  this.changeABSettingsHandler(1);
                }}
              >
                A/B Wochen
              </label>
            </span>

            <div className="btn-wrapper">
              {!ab_weeks_can_save ? (
                <button className="btn btn-dark" disabled="disabled">
                  speichern
                </button>
              ) : (
                <button
                  className="btn btn-step active"
                  onClick={() => {
                    this.changeABSettings(ab_weeks_change_state);
                  }}
                >
                  speichern
                </button>
              )}
              &nbsp; &nbsp; &nbsp;
              <button
                className="btn btn-dark"
                onClick={() => {
                  this.toggleABSettingsEdit(0);
                }}
              >
                abbrechen
              </button>
            </div>
          </div>
        </div>

        <div className="modal-wrapper-out" id="modal-wrapper-out">
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => {}}
            onRequestClose={this.closeModal}
            style={ModalStyles}
            ariaHideApp={false}
            contentLabel="Stundenplan"
          >
            <div className={`modal-wrapper ${hover_css}`}>
              <div className="modal-header">
                <span className="day">{this.state.modalDay},</span>
                <span className="hour">{this.state.modalHour}. Stunde</span>
              </div>

              {this.state.ab_weeks ? (
                <Tabs className="tab-main-wrapper">
                  <div className="tab-links">
                    <TabLink to="tab_a_week">A Woche</TabLink>
                    <TabLink to="tab_b_week">
                      B Woche (falls abweichend)
                    </TabLink>
                  </div>

                  <TabContent for="tab_a_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-arow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.handleItemClick}
                        nr={i}
                        week_type="A"
                        preselect_class={this.state.preselect_class_id}
                        preselect_subject={this.state.preselect_subject_id}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-arow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr="9999"
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  </TabContent>
                  <TabContent for="tab_b_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-brow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.handleItemClick}
                        nr={i}
                        week_type="B"
                        preselect_class={this.state.preselect_class_id_b}
                        preselect_subject={this.state.preselect_subject_id_b}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-brow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr="9999"
                      week_type="B"
                      preselect_class={this.state.preselect_class_id_b}
                      preselect_subject={this.state.preselect_subject_id_b}
                    />
                  </TabContent>
                </Tabs>
              ) : (
                <div className="defaul-tt-modal">
                  {this.props.classes.map((p, i) => (
                    <ClassesSubjectRow
                      {...p}
                      key={`cs-arow-${i}`}
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.handleItemClick}
                      nr={i}
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  ))}
                  <ClassesSubjectRow
                    {...last_modal}
                    key="cs-arow-9999"
                    addModalHoverClass={this.addModalHoverClass}
                    removeModalHoverClass={this.removeModalHoverClass}
                    handleItemClick={this.handleItemClick}
                    nr="9999"
                    week_type="A"
                    preselect_class={this.state.preselect_class_id}
                    preselect_subject={this.state.preselect_subject_id}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>

        <div className="tt-day-wrapper">
          <div className="day-label first" />
          <div className="day-label">Montag {abHeader}</div>
          <div className="day-label">Dienstag {abHeader}</div>
          <div className="day-label">Mittwoch {abHeader}</div>
          <div className="day-label">Donnerstag {abHeader}</div>
          <div className="day-label">Freitag {abHeader}</div>
          <div className={`day-label ${saturday_css_class}`}>
            Samstag {abHeader}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="tt-wrapper">
          {this.renderColumn(saturday_css_class)}
          <div style={{ clear: "both" }} />
        </div>

        <div className="add_column">
          Samstagsunterricht
          <input
            onChange={(e) => {
              this.changeSaturday(e.target.value);
            }}
            defaultChecked={defaultCheckedSaturday}
            type="checkbox"
            value={isSaturdayValue}
          />
        </div>
        <div className="line" />

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Klassen
              </a>
            </div>
            <div className="next">
              <button
                disabled={nextenabled}
                className="btn-dark btn"
                onClick={() => this.nextSection()}
              >
                Schuljahr 2024/2025 anlegen
              </button>

              {nextenabled && (
                <div className="innercontent">
                  <strong>
                    Die Daten werden verarbeitet,
                    <br /> einen Moment bitte...
                  </strong>
                </div>
              )}
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}
