import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";

export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
const FETCH_CLASSES_ERROR = "FETCH_CLASSES_ERROR";
export const ADD_CLASSROOM_SUCCESS = "ADD_CLASSROOM_SUCCESS";
const ADD_CLASSROOM_ERROR = "ADD_CLASSROOM_ERROR";
export const ADD_CLASS_LOCAL = "ADD_CLASS_LOCAL";
export const REMOVE_CLASS_LOCAL = "REMOVE_CLASS_LOCAL";

export const ADD_CLASS_LIST = "ADD_CLASS_LIST";
export const REMOVE_CLASSES_LIST = "REMOVE_CLASSES_LIST";
const REMOVE_CLASSES_LIST_ERROR = "REMOVE_CLASSES_LIST_ERROR";

export const UPDATE_CLASSLIST_ITEM = "UPDATE_CLASSLIST_ITEM";

export function updateClassListItem(obj, year) {
  const payload = { ...obj, user_id: Auth.getUserId(), schoolyear: year };

  return (dispatch) =>
    fetch(URLS.API_CLASSES_URL_UPDATE, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data);
          dispatch({
            type: UPDATE_CLASSLIST_ITEM,
            classes: data.classes,
          });
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch({
            type: ADD_CLASSROOM_ERROR,
            classes: "",
            message: error,
          });
        });
      }
    });
}

export function addClassList(classData) {
  return (dispatch) =>
    fetch(URLS.API_CLASSES_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(classData),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data) =>
          dispatch({
            type: ADD_CLASS_LIST,
            data: data.classes,
          }),
        );
      }
      return response.json().then((error) =>
        dispatch({
          type: ADD_CLASSROOM_ERROR,
          erorr: error,
        }),
      );
    });
}
/**
 * fetch classes list from mongo
 * @param user_id
 * @return {function(*=)}
 */
export function fetchClassesList(user_id, schoolyear) {
  return (dispatch) =>
    fetch(`${URLS.API_CLASSES_URL}/${user_id}/${schoolyear}`, {
      method: "get",
      headers: { Authorization: `bearer ${Auth.getToken()}` },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data);
          dispatch({
            type: FETCH_CLASSES_SUCCESS,
            classes: data.classes,
          });
        });
      } else {
        response.json().then((error) => {
          /// /console.log(error);
          dispatch({
            type: FETCH_CLASSES_ERROR,
            error,
          });
        });
      }
    });
}
/**
 * remove an class item from list, returns the new list without
 * @param id
 * @return {function(*=)}
 */
export const removeClassFromList = (id) => {
  const user_id = Auth.getUserId();
  return (dispatch) =>
    fetch(`${URLS.API_CLASSES_URL_DELETE}/${user_id}/${id}`, {
      method: "delete",
      headers: { Authorization: `bearer ${Auth.getToken()}` },
    }).then((response) => {
      if (response.ok) {
        response.json().then(() => {
          dispatch(removeClassesListSuccess(id));
        });
      } else {
        response.json().then((error) => {
          dispatch(removeClassListFailed(error));
        });
      }
    });
};
function removeClassesListSuccess(idx) {
  return {
    type: REMOVE_CLASSES_LIST,
    idx,
  };
}
function removeClassListFailed(message) {
  return {
    type: REMOVE_CLASSES_LIST_ERROR,
    message,
  };
}

/**
 * add an class object to the local storage
 * @param obj
 */
export function addClassLocal(obj) {
  // console.log('in action add local class');
  return {
    type: ADD_CLASS_LOCAL,
    obj,
  };
}

/**
 * add an class object to the local storage
 * @param obj
 */
export function removeClassLocal(idx) {
  // console.log('in action remove local class');
  return {
    type: REMOVE_CLASS_LOCAL,
    idx,
  };
}

export function add_class(classData) {
  return (dispatch) =>
    fetch(URLS.API_CLASSROOMS_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(classData),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data.subject);
          dispatch({
            type: ADD_CLASSROOM_SUCCESS,
            data,
          });
        });
      } else {
        response.json().then((error) => {
          // console.log(error);
          dispatch({
            type: ADD_CLASSROOM_ERROR,
            erorr: error,
          });
        });
      }
    });
}
