import React, { useRef } from "react";
import { useScript } from "../../../common/utils/hooks";

function useWistiaScript(hashedId, embedOptions) {
  const videoRef = useRef(null);
  const cleanUp = () => {
    if (videoRef.current) {
      videoRef.current.remove();
    }
  };
  const initalize = () => {
    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: embedOptions,
      onEmbedded: (video) => {
        videoRef.current = video;
      },
    });
    return cleanUp;
  };

  useScript(
    {
      id: "wistia_script",
      type: "text/javascript",
      src: "https://fast.wistia.com/assets/external/E-v1.js",
      async: true,
    },
    initalize,
  );
}

function WistiaEmbed(props) {
  const { hashedId, ...embedOptions } = props;
  useWistiaScript(hashedId, embedOptions);

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative" }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: "0",
          position: "absolute",
          top: "0",
          width: "100%",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${hashedId} videoFoam=true`}
          style={{ height: "100%", width: "100%" }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
}
export default WistiaEmbed;
