import React from "react";
import StepZilla from "react-stepzilla";
import { connect } from "react-redux";
import Subjects from "./settings/addSubjects";
import Classes from "./settings/addClasses";
import Timetable from "./settings/addTimetable";
import School from "./settings/Schoolform";
import { uniqueId } from "../../../common/components/sequence_calendar/Utils";
import Auth from "../../../common/services/Auth";
import { withRouter } from "../../../common/utils/routing";
import * as DataView from "../../../features/dataview/actions";
import { selectSettings } from "../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";
import { selectGetSchoolData } from "../../../services/api";

const mapStateToProps = (state) => ({
  currentSchoolyear: selectCurrentSchoolyear(state),
  school: selectGetSchoolData(state, selectCurrentSchoolyear(state)),
  subjects: state.subjects,
  classes: state.classes,
  settings: selectSettings(state),
});

const mapDispatchToProps = {
  addSchoolyearData: DataView.addSchoolyearData,
};

class SchoolyearComponent extends React.Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    // set the initial component state
    this.storage = {
      subjects: [],
      classes: [],
      schooldata: [],
      settings: [],
      globalsettings: [],
      timetable: {
        ttId: uniqueId(),
        saturday: false,
        schoolyear: 2024,
        entries: [],
        user_id: Auth.getUserId(),
      },
    };

    this.state = {
      storage: this.storage,
      hasSchoolData: false,
      hasSubjectData: false,
      hasClassesData: false,
      hasTimetableData: false,
      hasSettingData: false,
    };
    this.updateSchool = this.updateSchool.bind(this);
    this.finalizeSchoolyear = this.finalizeSchoolyear.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
  }

  finalizeSchoolyear() {
    const storageData = {
      ...this.state.storage,
      globalsettings: { ...this.props.settings },
    };
    this.props.addSchoolyearData(storageData);
  }

  /**
   * fill subjects data
   * @param subjects
   */
  updateSubjects(subjects) {
    this.storage.subjects = subjects;
    this.setState({
      storage: this.storage,
      hasSubjectData: true,
    });
  }

  updateSettings(settings) {
    this.storage.globalsettings = settings;
    this.setState({
      storage: this.storage,
      hasSettingData: true,
    });
  }

  updateSchool(schooldata) {
    schooldata.old_schoolyear = this.props.currentSchoolyear;
    this.storage.schooldata = schooldata;
    this.setState({
      storage: this.storage,
      hasSchoolData: true,
    });
  }

  /**
   * fill subjects data
   * @param subjects
   */
  updateClasses(classes) {
    this.storage.classes = classes;
    this.setState({
      storage: this.storage,
      hasClassesData: true,
    });
  }

  updateTimetable(timetable) {
    this.storage.timetable = timetable;
    this.setState({
      storage: this.storage,
      hasTimetableData: true,
    });
  }

  /**
   * @todo: set timefram and schoolyear from system
   * Render the component.
   */
  render() {
    const timeframe = "2024/2025";
    const schoolyear = 2024;

    // check if data for components exists, if not got from poperties
    let prefill_school = {};
    if (this.state.hasSchoolData) {
      prefill_school = this.state.storage.schooldata;
    } else {
      prefill_school = {
        location: this.props.school.street,
        street: this.props.school.street,
        zip: this.props.school.zip,
        nr: this.props.school.nr,
        ref: this.props.school.ref,
        region: this.props.school.region,
        region_label: this.props.school.region_label,
        schooltype: this.props.school.schooltype,
        schooltype_label: this.props.school.schooltype_label,
        school: this.props.school.school,
      };
    }

    let prefill_subjects;
    if (this.state.hasSubjectData) {
      prefill_subjects = this.state.storage.subjects;
    } else {
      prefill_subjects = this.props.subjects.raw.map((subj) => ({
        ...subj,
        schoolyear,
        old_ref_id: subj._id,
      }));
    }

    let prefill_classes = [];
    if (this.state.hasClassesData) {
      prefill_classes = this.state.storage.classes;
    } else {
      // set empty schooldays on init
      prefill_classes = this.props.classes.map((classes) => ({
        ...classes,
        subjects: classes.subjects.map((subj) => ({
          ...subj,
          schooldays: [],
        })),
        schoolyear,
        old_ref_id: classes._id,
      }));
    }

    let prefill_timetable = {};
    if (this.state.hasTimetableData) {
      prefill_timetable = this.state.storage.timetable;
    } else {
      prefill_timetable = {
        ttId: uniqueId(),
        saturday: false,
        schoolyear,
        entries: [],
        user_id: Auth.getUserId(),
      };
    }

    let prefill_settings = {};
    if (this.state.hasSettingData) {
      prefill_settings = this.state.storage.globalsettings;
    } else {
      prefill_settings = this.props.settings;
    }

    const steps = [
      {
        name: "Schule",
        component: (
          <School
            schoolyear={schoolyear}
            updateSchool={(u) => {
              this.updateSchool(u);
            }}
            timeframe={timeframe}
            prefill={prefill_school}
          />
        ),
      },
      {
        name: "Fächer",
        component: (
          <Subjects
            schoolyear={schoolyear}
            subjects={prefill_subjects}
            updateSubjects={(u) => {
              this.updateSubjects(u);
            }}
            updateClasses={(u) => {
              this.updateClasses(u);
            }}
            updateTimetable={(u) => {
              this.updateTimetable(u);
            }}
            timeframe={timeframe}
            classes={prefill_classes}
            timetable={prefill_timetable}
            settings={prefill_settings}
          />
        ),
      },
      {
        name: "Klassen",
        component: (
          <Classes
            schoolyear={schoolyear}
            subjects={prefill_subjects}
            updateClasses={(u) => {
              this.updateClasses(u);
            }}
            updateTimetable={(u) => {
              this.updateTimetable(u);
            }}
            timeframe={timeframe}
            classes={prefill_classes}
            timetable={prefill_timetable}
            settings={prefill_settings}
            finalizeSchoolyear={this.finalizeSchoolyear}
          />
        ),
      },
      {
        name: "Stundenplan",
        component: (
          <Timetable
            updateClasses={(u) => {
              this.updateClasses(u);
            }}
            updateTimetable={(u) => {
              this.updateTimetable(u);
            }}
            updateSettings={(u) => {
              this.updateSettings(u);
            }}
            schoolyear={schoolyear}
            subjects={prefill_subjects}
            timeframe={timeframe}
            classes={prefill_classes}
            timetable={prefill_timetable}
            settings={prefill_settings}
            finalizeSchoolyear={this.finalizeSchoolyear}
          />
        ),
      },
    ];

    return (
      <div
        id="registration"
        className="content-wrapper stepzilla schoolyear-add"
      >
        <h1>Schuljahr 2024/2025 anlegen</h1>
        <div className="step-progress">
          <StepZilla
            steps={steps}
            // preventEnterSubmission={true}
            // nextTextOnFinalActionStep={"Save"}
            // hocValidationAppliedTo={[3]}
            startAtStep={0}
            onStepChange={(step) => window.sessionStorage.setItem("step", step)}
          />
        </div>
      </div>
    );
  }
}

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SchoolyearComponent));
