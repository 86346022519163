import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";

export const UPDATE_PAYMENT_SETTINGS = "UPDATE_PAYMENT_SETTINGS";
export const FETCH_PAYMENT_SETTINGS = "FETCH_PAYMENT_SETTINGS";

/**
 * update user/application settings
 * @param settings
 * @returns {function(*=): Promise<T>}
 */
export function handlePaymentSettings(settings) {
  settings.user_id = Auth.getUserId();
  return (dispatch) =>
    fetch(URLS.API_STRIPE_PAYMENT_SETTINGS_URL, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      body: JSON.stringify(settings),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: UPDATE_PAYMENT_SETTINGS,
            settings: data.payment_settings,
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: UPDATE_PAYMENT_SETTINGS,
            erorr: error,
          });
        });
      }
    });
}

export function getPaymentSettings(user_id) {
  return (dispatch) =>
    fetch(`${URLS.API_STRIPE_PAYMENT_SETTINGS_URL}/${user_id}/`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${Auth.getToken()}`,
      },
      // body: JSON.stringify(data)
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // console.log(data.subject);

          // console.log('fetch payment stuff');
          // console.log(data);

          dispatch({
            type: FETCH_PAYMENT_SETTINGS,
            settings: data.payment_settings,
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: FETCH_PAYMENT_SETTINGS,
            erorr: error,
          });
        });
      }
    });
}
