import React, { useCallback, useState, useEffect, useRef } from "react";

export type Movement = { x: number; y: number };

type State = {
  pos: Movement | null;
};

type Props = {
  onDragStart?: (e: React.PointerEvent) => void;
  onDragStop?: (e: React.PointerEvent, movement: Movement) => void;
  bounds?: string;
  disableDragging: boolean;
};

type ComponentProps = Props & { children: React.ReactElement };
type HookReturnProps = {
  ref: React.RefObject<HTMLDivElement>;
  onPointerDown: (e: React.PointerEvent) => void;
  onPointerUp: (e: React.PointerEvent) => void;
  style: React.CSSProperties;
};

function useDraggable(props: Props): HookReturnProps {
  const elementRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({ pos: null });
  const isDragging = state.pos !== null;
  const handleMove = useCallback(
    (e) => {
      const boundsElement = props.bounds
        ? document.getElementById(props.bounds)
        : null;

      if (state.pos && elementRef.current) {
        // compute last, diff, and new position (relative to normal position)
        const lastX = state.pos.x;
        const lastY = state.pos.y;
        const deltaX = e.movementX;
        const deltaY = e.movementY;

        if (!boundsElement) {
          setState({ ...state, pos: { x: lastX + deltaX, y: lastY + deltaY } });
          return;
        }
        const boundsRect = boundsElement.getBoundingClientRect();
        const dragRect = elementRef.current.getBoundingClientRect();

        const relativeStartingPoint = dragRect.left - lastX;

        if (deltaX < 0) {
          // make sure box doesn't go outside of bounds when moving right
          const newX =
            dragRect.left + deltaX <= boundsRect.left
              ? boundsRect.left - relativeStartingPoint
              : lastX + deltaX;
          setState({ ...state, pos: { x: newX, y: lastY + deltaY } });
          return;
        }
        if (deltaX > 0) {
          // make sure box doesn't go outside of bounds when moving left
          const newX =
            dragRect.right + deltaX >= boundsRect.right
              ? boundsRect.right - dragRect.width - relativeStartingPoint
              : lastX + deltaX;
          setState({ ...state, pos: { x: newX, y: lastY + deltaY } });
        }
        // don't move otherwise
      }
    },
    [state.pos],
  );

  const handleUp = useCallback(() => {
    setState({ ...state, pos: null });
  }, []);

  useEffect(() => {
    // attach pointermove event to body so that it works even if bounds of elements are left
    document.body.addEventListener("pointermove", handleMove, {
      passive: true,
    });
    return () => {
      document.body.removeEventListener("pointermove", handleMove);
    };
  }, [handleMove]);

  useEffect(() => {
    // attack pointerup event to body so that it works even if bounds of elements are left
    document.body.addEventListener("pointerup", handleUp, {
      passive: true,
    });
    return () => {
      document.body.removeEventListener("pointerup", handleUp);
    };
  }, [handleUp]);

  return {
    ref: elementRef,
    onPointerDown: (e: React.PointerEvent) => {
      if (props.onDragStart) {
        props.onDragStart(e);
      }
      setState({ ...state, pos: { x: 0, y: 0 } });
    },
    onPointerUp: (e: React.PointerEvent) => {
      if (state.pos && props.onDragStop) {
        props.onDragStop(e, { x: state.pos.x, y: state.pos.y });
      }
      setState({ ...state, pos: null });
    },
    style: {
      position: "relative",
      translate: state.pos?.x ?? 0,
      zIndex: isDragging ? 30 : undefined,
    },
  };
}

/**
 * This component can be used around other components to make it draggable
 */
function Draggable(props: ComponentProps) {
  if (props.disableDragging) {
    return props.children;
  }
  const dragProps = useDraggable(props);
  return <div {...dragProps}>{props.children}</div>;
}

export default Draggable;
