/**
 * Created by makkuz on 06.02.18.
 */

import React from "react";

class CheckBoxItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      hasAccepted: false,
    };
    this.addSubjectToClass = this.addSubjectToClass.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.clearChecked) {
      nextState.checked = false;
    }
  }

  addSubjectToClass(e) {
    const { target } = e;

    if (this.props.planed_hours && !this.state.hasAccepted) {
      // console.log('planed_hours is set - must accepted');

      if (target.checked) {
        this.setState({ checked: true });
        this.props.addSubjectToClassAfterAccept(
          "add",
          e.target.dataset.obj,
          this.props.item.title,
        );
      } else {
        this.setState({ checked: false });
        this.props.addSubjectToClassAfterAccept(
          "remove",
          e.target.dataset.obj,
          this.props.item.title,
        );
      }
    } else if (target.checked) {
      this.setState({ checked: true });
      this.props.addSubjectToClass("add", e.target.dataset.obj);
    } else {
      this.setState({ checked: false });
      this.props.addSubjectToClass("remove", e.target.dataset.obj);
    }
  }

  render() {
    const { defaultChecked, cssid, item, onKeyDown } = this.props;
    const checked = defaultChecked || this.state.checked;
    const handleKeyDownProp = onKeyDown ? { onKeyDown } : {};

    return (
      <div className="select_item">
        <label htmlFor={cssid}>
          <input
            className="checkbox"
            data-obj={JSON.stringify(item)}
            type="checkbox"
            value="on"
            id={cssid}
            onChange={this.addSubjectToClass}
            onKeyDown={null}
            checked={checked}
            {...handleKeyDownProp}
          />
          {item.title}
        </label>
      </div>
    );
  }
}
export default CheckBoxItem;
