// Need to use the React-specific entry point to import createApi
import { createApi, type BaseQueryFn } from "@reduxjs/toolkit/query/react";

type QueryArgs = {
  mode: "get" | "set";
  key: string;
  data?: unknown;
};

const sessionStorageLessonKey: string = "/session-clipboard/lesson";

const sessionClipboardBaseQuery: BaseQueryFn<QueryArgs> = (args) => {
  try {
    if (args.mode === "get") {
      const storedData = sessionStorage.getItem(args.key);
      return { data: storedData ? JSON.parse(storedData) : null };
    }
    sessionStorage.setItem(args.key, JSON.stringify(args.data));
    return { data: "Ok" };
  } catch (error) {
    return { error };
  }
};

// Define a service using a base URL and expected endpoints
export const sessionClipboard = createApi({
  reducerPath: "sessionClipboard",
  baseQuery: sessionClipboardBaseQuery,
  // refetch when window/tab gains focus
  refetchOnFocus: true,
  tagTypes: ["Lessons"],
  endpoints: (builder) => ({
    setLesson: builder.mutation({
      query: (data) => ({
        mode: "set",
        key: sessionStorageLessonKey,
        data,
      }),
      invalidatesTags: ["Lessons"],
    }),
    getLesson: builder.query({
      query: () => ({ mode: "get", key: sessionStorageLessonKey }),
      providesTags: ["Lessons"],
    }),
  }),
});
