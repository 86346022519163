import Auth from "../common/services/Auth";

function isFreigeistDude(): boolean {
  const email = Auth.getEmail();
  if (!email) {
    return false;
  }
  return [
    "deedop@hotmail.de",
    "hallo@tobiastiefert.com",
    "hanso@kuzio.info",
    "weekview@kuzio.info",
  ].includes(email);
}

const featureFlags = {
  showLessonCopyPaste: isFreigeistDude,
};

export { featureFlags };
