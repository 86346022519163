import * as ACTIONS from "./actions";

const def = {};

export function registration(state = def, action) {
  switch (action.type) {
    case ACTIONS.HANDLE_REGISTER:
      return { ...state, ...action.data };

    default:
      return state;
  }
}
