const START_NEW_YEAR_MONTH = 7;

/**
 *
 * @returns {{calc_year: string, label: string}}
 */
export function calcSchoolyearSettings(now = Date.now()) {
  const currentDate = new Date(now);
  const currentMonth = currentDate.getMonth() + 1; // adjust for zero-based months
  const currentYear = currentDate.getFullYear();

  const relevantYear =
    currentMonth < START_NEW_YEAR_MONTH ? currentYear - 1 : currentYear;

  return {
    year: relevantYear,
    label: `${relevantYear}/${relevantYear + 1}`,
  };
}
