import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
/**
 * sort the classes alphabetically
 * @link https://3.basecamp.com/5029203/buckets/25665987/todos/4565238532
 * @param classes
 * @returns {*[]}
 */
export function sortClassesAlphabetically(classes) {
  const sortedClasses = [...classes].sort((a, b) =>
    a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
  );
  return sortedClasses;
}

// Get a deeply nested property of an array/object structure and return it
// deepGet([1, 'name'], [{name: 'Dido'}, {name: 'Marko'}]) === 'Marko';
// deepGet([1, 'name', 'nonExistingProp'], [{name: 'Dido'}, {name: 'Marko'}]) === undefined;
function deepGet(path, arrayOrObject) {
  return path.reduce(
    (value, propOrIndex) => value?.[propOrIndex],
    arrayOrObject,
  );
}

function set(propOrIndex, val, prevArrayOrObject) {
  if (typeof propOrIndex === "number") {
    return Object.assign([], prevArrayOrObject, { [propOrIndex]: val });
  }
  return { ...prevArrayOrObject, [propOrIndex]: val };
}

// Legacy: Stop using this as this fills in missing arrays and objects along the path
//  USE deepSetSafe instead
// Set a deeply nested property of an array/object structure and return it without mutating
//
// deepSet([1, 'name'], "Tobi", [{name: 'Dido'}, {name: 'Marko'}]) === [{name: 'Dido'}, {name: 'Tobi'}];
export function deepSet(path, value, arrayOrObject) {
  return path.reduce((currentValue, _, index) => {
    const pathIndex = path.length - index - 1;
    const currentPath = path.slice(0, pathIndex);
    const parent = deepGet(currentPath, arrayOrObject);
    return set(path[pathIndex], currentValue, parent);
  }, value);
}

// Set a deeply nested property of an array/object structure and return it without mutating
// deepSetSafe([1, 'name'], "Tobi", [{name: 'Dido'}, {name: 'Marko'}]) === [{name: 'Dido'}, {name: 'Tobi'}];
export function deepSetSafe(path, value, arrayOrObject) {
  return immutableUpdate(arrayOrObject, (draft) => {
    const objToUpdate = deepGet(path.slice(0, -1), draft);
    const lastPathSegment = path[path.length - 1];
    objToUpdate[lastPathSegment] = value;
  });
}
