import * as ACTIONS from "./actions";

export function payment(state = {}, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_PAYMENT_SETTINGS:
      return { ...state, ...action.settings };

    case ACTIONS.FETCH_PAYMENT_SETTINGS:
      return { ...state, ...action.settings };

    default:
      return state;
  }
}
